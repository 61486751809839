






















































































import Vue from "vue";
import {
  Stripe,
  StripeAddressElement,
  StripeElements,
} from "@stripe/stripe-js";
import { ICompany, IPaymentMethod } from "@/features/CompanySettings/types";

import PaymentInfoTile from "@/features/CompanySettings/components/PaymentInfoTile.vue";

export default Vue.extend({
  components: { PaymentInfoTile },
  data: function () {
    return {
      stripe: null as Stripe | null,
      addressElement: {} as StripeAddressElement,
      company: {} as ICompany,
      saveFailed: false,
      saveSucceeded: false,
      saveErrorMessage: "",
      saveErrorCode: 200,
      step: 1,
      totalSteps: 2,
      paymentMethods: [] as Array<IPaymentMethod>,
    };
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      const query = JSON.stringify({
        query: `
        query getCompany {
          company(id:${this.$route.params.id}) {
            id:contentObjectId
            name
            countryCode
            state
            address1
            address2
            city
            postalCode
            phone
            billing {
              paymentMethods {
                cardType:brand
                last4
                default
                expYear
                expMonth
                stripeId
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveFailed = true;
            this.saveSucceeded = false;
            this.saveErrorMessage = result.errors[0].message;
          } else {
            this.saveFailed = false;
            this.saveSucceeded = true;
            this.saveErrorMessage = "";
            this.saveErrorCode = 200;
            this.company.name = result.data.company.name;
            this.company.id = result.data.company.id;
            this.company.street1 = result.data.company.address1;
            this.company.street2 = result.data.company.address2;
            this.company.city = result.data.company.city;
            this.company.state = result.data.company.state;
            this.company.postalCode = result.data.company.postalCode;
            this.company.countryCode = result.data.company.countryCode;
            this.company.phone = result.data.company.phone;

            this.paymentMethods = result.data.company.billing.paymentMethods;

            this.loadAddressElement();
          }
        });
    },
    saveChanges: function () {
      this.addressElement.getValue().then((value) => {
        if (value.complete) {
          this.company.name = value.value.name;
          this.company.street1 = value.value.address.line1;
          this.company.street2 = value.value.address.line2;
          this.company.city = value.value.address.city;
          this.company.state = value.value.address.state;
          this.company.postalCode = value.value.address.postal_code;
          this.company.countryCode = value.value.address.country;
          if (value.value.phone) {
            this.company.phone = value.value.phone;
          }

          if (!this.company.street2) {
            this.company.street2 = "";
          }

          let query = "";
          query = JSON.stringify({
            query: `
              mutation {
                mutateCompany(input: {
                  id: ${this.company.id},
                  name: "${this.company.name}",
                  address1: "${this.company.street1}",
                  address2: "${this.company.street2}",
                  city: "${this.company.city}",
                  postalCode: "${this.company.postalCode}",
                  countryCode: "${this.company.countryCode}",
                  state: "${this.company.state}",
                  phone: "${this.company.phone}",
                }) {
                  company {
                    name
                  }
                  errors {
                    messages
                  }
                }
              }
            `,
          });

          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: query,
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.errors) {
                this.saveFailed = true;
                this.saveSucceeded = false;
                this.saveErrorMessage = result.errors[0].message;
              } else {
                this.saveFailed = false;
                this.saveSucceeded = true;
                this.saveErrorMessage = "";
                this.saveErrorCode = 200;

                this.step++;
              }
            });
        }
      });
    },
    returnToCaller: function () {
      if (this.$route.query.next) {
        window.location.href = this.$route.query.next as string;
      }
    },
    loadAddressElement: async function () {
      if (!this.stripe) {
        if (window.Stripe) {
          if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
            this.stripe = window.Stripe(
              process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
            );
          }
        }
      }

      // have to use any type on next line because Stripe's typescript support
      // is missing this type
      const appearance = {
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily:
              "'Suisse', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            colorText: "#333333",
            fontSizeBase: "13px",
            fontSizeSm: "13px",
            fontWeightNormal: 500,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      let elements = {} as StripeElements;

      if (this.stripe) {
        elements = this.stripe.elements(appearance);
      }

      if (elements) {
        // Create and mount the Address Element in shipping mode
        this.addressElement = elements.create("address", {
          mode: "billing",
          display: {
            name: "organization",
          },
          fields: {
            phone: "always",
          },
          defaultValues: {
            name: this.company.name,
            phone: this.company.phone,
            address: {
              line1: this.company.street1,
              line2: this.company.street2,
              city: this.company.city,
              state: this.company.state,
              postal_code: this.company.postalCode,
              country: this.company.countryCode
                ? this.company.countryCode
                : "US",
            },
          },
        });
        this.addressElement.mount("#address-element");
      }
    },
    cardAdded: function () {
      // refresh the payment methods
      this.fetchData();
    },
  },
  computed: {
    defaultPaymentMethod: function (): IPaymentMethod | undefined {
      return this.paymentMethods.find((paymentMethod) => {
        return paymentMethod.default;
      });
    },
  },
});
