













































































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import {
  Component,
  graphData,
  SignTreeData,
  iSignData,
  iSignChild,
  iSignSide,
  iElementEvent,
  IField,
  IPart,
  ISignMaterial,
  ISignProcess,
  IAlignment,
} from "../types";
// import axios from "axios";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";

import InspectorPanel from "./InspectorPanel.vue";
import LayersPanel from "./LayersPanel.vue";
import TopBar from "./TopBar.vue";
import SVGRenderer from "./SVGRenderer.vue";
import MaterialsModal from "./modals/MaterialsModal.vue";
import ProcessesModal from "./modals/ProcessesModal.vue";
import ColorsModal from "./modals/ColorsModal.vue";
import AddColorsModal from "./modals/AddColorsModal.vue";
import AddSignTypeModal from "./modals/AddSignTypeModal.vue";
import AddAlignmentModal from "./modals/AddAlignmentModal.vue";
import AddFieldModal from "./modals/AddFieldModal.vue";
import AddFontModal from "./modals/AddFontModal.vue";
import AddSideModal from "./modals/AddSideModal.vue";
import FileModal from "./modals/FileModal.vue";
import ImageModal from "./modals/ImageModal.vue";
import ProductFamiliesModal from "./modals/ProductFamiliesModal.vue";
import ProductCategoriesModal from "./modals/ProductCategoriesModal.vue";
import ProductTypesModal from "./modals/ProductTypesModal.vue";
// import ProductModal from "./modals/ProductModal.vue";
import MissingFontsModal from "./modals/MissingFontsModal.vue";
import AddVisualsModal from "./modals/AddVisualsModal.vue";
import UnsupportedFeatureModal from "./modals/UnsupportedFeatureModal.vue";
import FeatureAccessModal from "./modals/FeatureAccessModal.vue";
import HistoryModal from "./modals/HistoryModal.vue";
import MarketplaceModal from "./modals/MarketplaceModal.vue";

import {
  uuidv4,
  getFieldDefFromChildren,
  isEmpty,
  drawRepeatElements,
} from "../utilities";
import { getElementName, normalCaseToUnderscore } from "../include/Utils";

export default Vue.extend({
  components: {
    LayersPanel,
    TopBar,
    InspectorPanel,
    SVGRenderer,
    MaterialsModal,
    ProcessesModal,
    ColorsModal,
    AddColorsModal,
    AddSignTypeModal,
    AddAlignmentModal,
    AddFieldModal,
    AddFontModal,
    AddSideModal,
    FileModal,
    ImageModal,
    ProductFamiliesModal,
    ProductCategoriesModal,
    ProductTypesModal,
    // ProductModal,
    MissingFontsModal,
    AddVisualsModal,
    UnsupportedFeatureModal,
    FeatureAccessModal,
    HistoryModal,
    MarketplaceModal,
  },
  props: {
    signTypeId: { type: String },
  },
  data() {
    return {
      canvasWidth: 14500,
      canvasHeight: 14500,
      treeData: {} as SignTreeData,
      componentData: {} as Component,
      maxSelectionBox: {
        x1: -1,
        x2: -1,
        y1: -1,
        y2: -1,
      }, //have to be initialized like this or getWidthAndHeightOfSelectedElements wont work
      scrollTop: -1,
      scrollLeft: -1,
      leftSidebarHeight: 400,
      rightSidebarHeight: 400,
      minZoomPercentage: 5,
      graphData: {},
      signData: {
        id: "",
        name: "",
        numberOfSides: 0,
        areSidesIdentical: false,
        width: 0,
        height: 0,
        measurementUnit: { unit: "pt", conversion: 1 },
        sides: [
          {
            id: "",
            name: "",
            children: [] as Array<iSignChild>,
          },
        ],
      } as iSignData,
      nextComponentNumber: 1,
      nextPartNumber: 1,
      nextFieldNumber: 1,
      nextProcessNumber: 1,
    };
  },
  mounted: function () {
    this.$root.$refs.konva = this;

    this.fitStageIntoParentContainer();

    window.addEventListener("resize", this.fitStageIntoParentContainer);

    window.addEventListener("keydown", (e: KeyboardEvent) => {
      //Ctrl+1 = zoom to fit entire sign
      if (e.key === "1") {
        if (e.ctrlKey) {
          e.preventDefault();
          e.stopPropagation();

          let initialZoom = 100;

          let offsetX = 0;
          let offsetY = 0;

          // get the offsets from the HTML if available
          if (this.$refs.renderer) {
            offsetX = (this.$refs.renderer as HTMLElement).offsetLeft;
            offsetY = (this.$refs.renderer as HTMLElement).offsetTop;
          }

          let bb = this.$store.getters.canvas.select("svg")?.getBBox();

          if (bb) {
            let panel_ratio = this.canvasWidth / this.canvasHeight;
            let image_ratio = bb.width / bb.height;

            if (panel_ratio > image_ratio) {
              initialZoom =
                (this.canvasHeight / (bb.height + offsetY + 40)) * 100;
            } else {
              initialZoom =
                (this.canvasWidth / (bb.width + offsetX + 40)) * 100;
            }

            if (initialZoom < this.minZoomPercentage) {
              initialZoom = this.minZoomPercentage;
            }
          } else {
            initialZoom = 100;
          }

          this.$store.commit(
            "setZoomPercentage",
            this.setZoomtoFactor(initialZoom)
          );
          //set scroll bars to 0,0
          this.scrollTop = 0;
          this.scrollLeft = 0;

          //toggle rerender flag so that selection handles are forced to redraw at the proper scale
          this.$store.commit("setRerender", !this.$store.getters.rerender);
        }
      }

      //Ctrl+2 = zoom to selection
      if (e.key === "2" && e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        //find out the lowest x1,y1 and highest x2,y2 values for the selected element(s)
        this.getWidthAndHeightOfSelectedElements(
          this.signData.sides[this.$store.getters.currentSide].children
        );
        //the 1.1 multiplier is to add a little space around the selection
        let selectedElementsWidth =
          this.maxSelectionBox.x2 - this.maxSelectionBox.x1;
        let selectedElementsHeight =
          this.maxSelectionBox.y2 - this.maxSelectionBox.y1;

        let offsetX = (this.$refs.renderer as HTMLElement).offsetLeft;
        let offsetY = (this.$refs.renderer as HTMLElement).offsetTop;

        let initialZoom = this.$store.getters.zoomPercentage;

        if (selectedElementsHeight !== 0 && selectedElementsWidth !== 0) {
          let panel_ratio = this.canvasWidth / this.canvasHeight;
          let image_ratio = selectedElementsWidth / selectedElementsHeight;

          if (panel_ratio > image_ratio) {
            initialZoom =
              (this.canvasHeight / (selectedElementsHeight + offsetY + 40)) *
              100;
          } else {
            initialZoom =
              (this.canvasWidth / (selectedElementsWidth + offsetX + 40)) * 100;
          }
        }

        if (initialZoom < this.minZoomPercentage) {
          initialZoom = this.minZoomPercentage;
        }

        //set the zoom percentage
        this.$store.commit(
          "setZoomPercentage",
          this.setZoomtoFactor(initialZoom)
        );
        //scroll to the top left of the selection
        this.scrollTop =
          (this.maxSelectionBox.y1 - offsetY - 120) * (initialZoom / 100);
        this.scrollLeft =
          (this.maxSelectionBox.x1 - offsetX - 120) * (initialZoom / 100);
        //reset the maxSelectionBox
        this.maxSelectionBox = {
          x1: -1,
          x2: -1,
          y1: -1,
          y2: -1,
        };
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      }

      if (e.key === "z" || e.key === "Z") {
        // Ctrl+Z = undo
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          e.stopPropagation();
          if (this.$store.getters.history.length > 0) {
            // eslint-disable-next-line
            (this.$root.$refs.TopBar as any).undoClicked();
          }
        }
      }
      if (e.key === "y" || e.key === "Y") {
        // Ctrl+Y = redo
        if (e.ctrlKey || e.metaKey) {
          e.preventDefault();
          e.stopPropagation();
          if (e.shiftKey) {
            if (this.$store.getters.redoHistory.length > 0) {
              // eslint-disable-next-line
            (this.$root.$refs.TopBar as any).redoClicked();
            }
          } else {
            if (this.$store.getters.redoHistory.length > 0) {
              // eslint-disable-next-line
            (this.$root.$refs.TopBar as any).redoClicked();
            }
          }
        }
      }
    });
    //this.loadSignTypes();
  },
  destroyed() {
    window.removeEventListener("resize", this.fitStageIntoParentContainer);
  },
  methods: {
    undoClicked: function () {
      this.$store.commit("setProcessingUndo", true);
      // this.processUndo();
      //this.$store.commit("setProcessingUndo", false);
    },
    redoClicked: function () {
      this.$store.commit("setProcessingRedo", true);
    },
    signSideAdded: function () {
      this.signData = JSON.parse(JSON.stringify(this.$store.getters.signData));
    },
    /**
     * handle the delete key pressed event
     */
    onDeleteKeyPressed: function () {
      this.deselectAll(
        this.signData.sides[this.$store.getters.currentSide].children
      );
      this.$store.commit("setRerender", !this.$store.getters.rerender);

      //delete the selected elements
      this.$store.getters.selectedElementIds.forEach((elementId: string) => {
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + elementId + "']"
        );
        el.remove();
        //remove it from the sign tree as well.
        this.deleteSignChildren(
          this.signData.sides[this.$store.getters.currentSide].children,
          elementId
        );

        // in order for the sign tree to be updated immediately we need to
        // replace the signData with a new instance so we set up a temporary
        // copy of the signData object and then overwrite the signData with it
        let tempSignData: iSignData = JSON.parse(JSON.stringify(this.signData));

        this.signData = tempSignData;
        //this.$store.commit("setSignData", this.signData);
      });
    },
    /**
     * delete all children with a specific element id
     * @param {Array<iSignChild>} children - the array of children to search through
     * @param {string} elementId - the svgId we are looking for
     */
    deleteSignChildren: function (
      children: Array<iSignChild>,
      elementId: string
    ) {
      for (let i = 0; i < children.length; i++) {
        if (children[i].svgId === elementId) {
          children.splice(i, 1);
        }

        if (children[i].children && children[i].children.length > 0) {
          this.deleteSignChildren(children[i].children, elementId);
        }
      }
    },
    /**
     * handle the process selected event
     */
    onProcessSelected(process: iSignChild) {
      this.deselectAll(
        this.signData.sides[this.$store.getters.currentSide].children
      );
      process.isSelected = true;
    },
    /**
     * add a repeat step
     */
    onDrawRepeatElements: function () {
      drawRepeatElements(this);
    },
    /**
     * handle add component event
     */
    addComponent: function () {
      let componentName = "Component_" + this.nextComponentNumber;
      //this.component = { name: "Component_" + this.nextComponentNumber };
      this.nextComponentNumber++;
      let id = uuidv4();
      let field = {
        id: id,
        alignment: "",
        content: "",
        css: "{ fill: #fff; font-family: UniversNextPro-Cond, Univers Next Pro; font-size: 95.98px; letter-spacing: 0.01em; }",
        cssClass: "",
        elementType: "g",
        fieldType: "component",
        fill: "transparent",
        stroke: "none",
        fontInfo: {
          fontFamily: "",
          fontSize: "",
          fontWeight: "normal",
        },
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        isSelected: true,
        isTargetted: false,
        isVisible: true,
        name: componentName,
        svgId: id,
        children: [],
      } as iSignChild;
      //      let signData = this.$store.getters.signData;
      this.$store.getters.selectedElements.forEach((element: iSignChild) => {
        field.children.push(element);
        for (
          var i = 0;
          i <
          this.signData.sides[this.$store.getters.currentSide].children.length;
          i++
        ) {
          if (
            element.svgId ===
            this.signData.sides[this.$store.getters.currentSide].children[i]
              .svgId
          ) {
            this.signData.sides[
              this.$store.getters.currentSide
            ].children.splice(i, 1);
          }
        }
      });

      this.signData.sides[this.$store.getters.currentSide].children.push(field);

      this.$store.commit("setSignData", this.signData);

      // this.$nextTick(function () {
      this.$store.commit("setActivePanel", "assets");
      // });
    },
    /**
     * handle the add process event
     */
    addProcess: function () {
      this.deselectAll(
        this.signData.sides[this.$store.getters.currentSide].children
      );

      let componentName = "Process_" + this.nextProcessNumber;
      //this.component = { name: "Component_" + this.nextComponentNumber };
      this.nextProcessNumber++;
      let id = uuidv4();
      let field = {
        id: id,
        alignment: "",
        content: "",
        css: "{ fill: #fff; font-family: UniversNextPro-Cond, Univers Next Pro; font-size: 95.98px; letter-spacing: 0.01em; }",
        cssClass: "",
        elementType: "process",
        fieldType: "",
        fill: "transparent",
        stroke: "none",
        fontInfo: {
          fontFamily: "",
          fontSize: "",
          fontWeight: "normal",
        },
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        isSelected: true,
        isTargetted: false,
        isVisible: true,
        name: componentName,
        svgId: id,
        children: [],
      } as iSignChild;

      let myElements = this.getSelectedSignElements();

      myElements.forEach((element: iSignChild) => {
        field.children.push(element);
        for (
          var i = 0;
          i <
          this.signData.sides[this.$store.getters.currentSide].children.length;
          i++
        ) {
          if (
            element.svgId ===
            this.signData.sides[this.$store.getters.currentSide].children[i]
              .svgId
          ) {
            this.signData.sides[
              this.$store.getters.currentSide
            ].children.splice(i, 1);
          }
        }
      });

      this.signData.sides[this.$store.getters.currentSide].children.push(field);

      this.$store.commit("setSignData", this.signData);
      this.$store.commit("setActivePanel", "connections");
    },
    /**
     * remove all component annotations from the SVG
     * @param {Component} componentData - the component to remove the annotation from
     */
    removeAnnotations: function (componentData: Component) {
      componentData.children.forEach((child: Component) => {
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + componentData.id + "']"
        );
        if (el) {
          //remove any existing component annotation so we don't get duplicates
          el.node.childNodes.forEach((node: Snap.Element) => {
            if (node.data) {
              if (node.data.toString().includes(`{"components"`)) {
                node.remove();
              }
            }
          });
        }

        if (child.children.length !== 0) {
          this.removeAnnotations(child);
        }
      });
    },
    /**
     * add component annotations to the SVG
     * @param {Component} componentData - the component to annotate
     * @param {string} componentList - a comma seperated list of components that are orderable
     */
    annotateSVG: function (componentData: Component, componentList: string) {
      if (componentData.isOrderable) {
        componentList += '"' + componentData.name + '",';
      }
      const commentString = Snap.parse(
        '<!-- {"components": [' + componentList.replace(/,\s*$/, "") + "]} -->"
      );

      componentData.children.forEach((child: Component) => {
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + componentData.id + "']"
        );
        if (el) {
          el.append(commentString);
        }

        if (child.children.length !== 0) {
          this.annotateSVG(child, componentList);
        }
      });
    },
    /**
     * Build the data structure formatted for backend from component data
     * @param {Component} componentData - the component data
     * @returns {graphData} An array of graphData elements
     */
    buildGraphData: function (componentData: Component): graphData {
      let compname = componentData.name;

      let element: graphData = {
        [compname]: {
          id: componentData.id,
          name: componentData.name,
          parent: componentData.parent,
          is_orderable: componentData.isOrderable,
          artwork: [],
          children: [],
        },
      };

      componentData.children.forEach((child: Component) => {
        if (child.children.length === 0) {
          element[compname].artwork.push(child.name);
        } else {
          element[compname].children.push(this.buildGraphData(child));
        }
      });
      //      element = JSON.parse(element);

      return element;
    },
    /**
     * handle the save component event
     */
    saveComponent: function () {
      // build the data structure formatted for backend from component data
      this.graphData = JSON.stringify(this.buildGraphData(this.componentData));

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation updateSignType($json: SignTypeMutationInput!){
              updateSignType(input: $json) {
                errors {messages}
              }
            }
          `,
          variables: JSON.stringify({
            json: {
              id: this.signTypeId,
              componentJson: this.graphData,
            },
          }),
        }),
      })
        .then((res) => res.json())
        .then((result) => console.log(result));

      this.removeAnnotations(this.componentData);
      this.annotateSVG(this.componentData, "");
    },
    /**
     * handle the wheel scroll event
     * @param {WheelEvent} e - the wheel event
     */
    handleScroll(e: WheelEvent) {
      const target = e.target as Element;

      this.scrollTop = target.scrollTop;
      this.scrollLeft = target.scrollLeft;
    },
    /**
     * process the tree built event
     * @param {SignTreeData} tree - the sign tree data
     */
    onTreeBuilt: function (tree: SignTreeData) {
      this.componentData = this.$store.getters.components;
      // parsing and stringifying makes a copy not a reference
      // if we don't do this we get mutation errors whenever we update signData
      this.signData = JSON.parse(JSON.stringify(this.$store.getters.signData));
      this.treeData = tree;
      this.$store.commit("setShowLoadingSpinner", false);
    },
    /**
     * get the width and height of the selected elements
     * @param {Array<iSignChild>} items - an array of iSignChild elements
     */
    getWidthAndHeightOfSelectedElements(items: iSignChild[]) {
      items.forEach((item: iSignChild) => {
        if (item.isSelected) {
          //get the svg element
          let el = this.$store.getters.canvas.select(
            "[sa-data-id='" + item.svgId + "']"
          );
          if (el) {
            let bb = el.getBBox();
            if (this.maxSelectionBox.x1 === -1) {
              // this is the first time here
              this.maxSelectionBox.x1 = bb.x;
              this.maxSelectionBox.y1 = bb.y;
              this.maxSelectionBox.x2 = bb.x2;
              this.maxSelectionBox.y2 = bb.y2;
            } else {
              if (bb.x < this.maxSelectionBox.x1) {
                this.maxSelectionBox.x1 = bb.x;
              }
              if (bb.x2 > this.maxSelectionBox.x2) {
                this.maxSelectionBox.x2 = bb.x2;
              }
              if (bb.y < this.maxSelectionBox.y1) {
                this.maxSelectionBox.y1 = bb.y;
              }
              if (bb.y2 > this.maxSelectionBox.y2) {
                this.maxSelectionBox.y2 = bb.y2;
              }
            }
          }
        }

        if (item.children && item.children.length > 0) {
          this.getWidthAndHeightOfSelectedElements(item.children);
        }
      });
    },
    /**
     * process the delete component event
     */
    deleteActiveComponent: function () {
      let comp = {} as Component;
      if (this.componentData.isActive) {
        comp = this.componentData;
      } else {
        comp = this.getActiveComponent(this.componentData.children);
      }

      //remove annotations
      this.removeAnnotations(comp);

      if (comp.parent) {
        //get the parent
        let parent = this.getComponentDataById(this.componentData, comp.parent);
        //find the index of the child
        let i = 0;
        for (i = 0; i < parent.children.length; i++) {
          if (parent.children[i].id === comp.id) {
            break;
          }
        }
        parent.children.splice(i, 1);
        //remove it
      }
    },
    /**
     * get the first ancestor that has a given element type
     * @param {string} svgId - the id of the element to begin searching at
     * @param {string} elementType - the element type we are looking for
     * @returns {string} the svgId of the found ancestor or blank if none found
     */
    getAncestorId: function (svgId: string, elementType: string): string {
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + svgId + "']"
      );
      if (el) {
        let parent = el.parent();
        if (parent) {
          if (parent.type === elementType && parent.attr("id") === null) {
            return parent.attr("sa-data-id");
          } else if (parent.type !== "svg" && parent.parent()) {
            return this.getAncestorId(parent.attr("sa-data-id"), elementType);
          } else {
            // return "not work ";
            return el.attr("sa-data-id");
          }
        }
      }
      return "cuibwk";
    },
    /**
     * select an element and all it's children
     * @param {iSignChild} signChild - the element to select
     */
    selectWithChildren: function (signChild: iSignChild) {
      let fieldDef = {} as IField;
      if (signChild.element) {
        if (this.elementHasNoFillNoStrokeRect(signChild.element)) {
          let elementName = getElementName(signChild.element);
          let field = this.getFieldDefinition(elementName);
          if (field) {
            fieldDef = getFieldDefFromChildren(signChild.element, field);
          }
        }
      }
      if (!isEmpty(fieldDef)) {
        this.$store.commit("addSelectedElementId", fieldDef.id);
      } else {
        this.$store.commit("addSelectedElementId", signChild.id);
      }

      if (signChild.children && signChild.children.length > 0) {
        signChild.children.forEach((child) => {
          this.selectWithChildren(child);
        });
      }
    },
    elementHasNoFillNoStrokeRect: function (element: Snap.Element): boolean {
      let found = false;
      element.children().forEach((child) => {
        if (child.type === "rect" && child.attr("fill") === "none") {
          found = true;
        }
      });
      return found;
    },

    getFieldDefinition: function (fieldName: string): IField | null {
      // console.debug(`getFieldDefinition: ${sequenceNum++}`);
      let field = null as IField | null;

      for (let i = 0; i < this.$store.getters.fields.length; i++) {
        if (
          normalCaseToUnderscore(
            this.$store.getters.fields[i].name
          ).toLowerCase() === normalCaseToUnderscore(fieldName.toLowerCase())
        ) {
          field = this.$store.getters.fields[i];
        }
      }
      return field;
    },
    /**
     * deselect an element and all it's children
     * @param {iSignChild} signChild - the element to deselect
     */
    deselectWithChildren: function (signChild: iSignChild) {
      this.$store.commit("removeSelectedElementId", signChild.svgId);

      if (signChild.children && signChild.children.length > 0) {
        signChild.children.forEach((child) => {
          this.deselectWithChildren(child);
        });
      }
    },
    deselectAllRepeats: function () {
      if (this.$store.getters.selectedRepeat) {
        this.$store.getters.canvas
          .selectAll(".repeat-clone")
          .forEach((el: Snap.Element) => {
            el.remove();
          });
        // this.$store.getters.selectedRepeat.clonedElementIds.forEach(
        //   (elementId: string) => {
        //     let el = this.$store.getters.canvas.select(
        //       "[sa-data-id='" + elementId + "']"
        //     );
        //     if (el) {
        //       el.remove();
        //     }
        //   }
        // );

        this.$store.commit("deleteSelectedRepeatClonedElementIds");
      }
      this.$store.commit("deselectAllRepeats");
      drawRepeatElements(this);
    },
    /**
     * process the element selected event
     * @param {iElementEvent} event - a data structure that contains the element and the event that triggered this
     */
    selectElement: function (event: iElementEvent) {
      if (!event.event.shiftKey) {
        //deselect all of the root elements children
        this.deselectAll(
          this.signData.sides[this.$store.getters.currentSide].children
        );
        this.$store.commit("clearSelectedElementIds");
      } else {
        //shift key is pressed
        event.event.preventDefault();
        event.event.stopPropagation();
        document?.getSelection()?.removeAllRanges();
      }

      // is the element already selected?
      let alreadySelected = false;
      this.$store.getters.selectedElementIds.forEach((id: string) => {
        if (id === event.element.id) {
          alreadySelected = true;
        }
      });

      // if already selected and the shift key is pressed then deselect the element
      if (alreadySelected && event.event.shiftKey) {
        // check if the selected element is part of a group
        let ancestorId = "";

        if (
          event.element.elementType === "g" &&
          this.elementHasNoFillNoStrokeRect(
            event.element.element as Snap.Element
          )
        ) {
          ancestorId = event.element.id;
        }
        // let ancestorId = this.getAncestorId(event.element.id, "g");

        //if it is then select all children of the group
        if (ancestorId !== "") {
          let signChild = this.getSignChildById(
            this.$store.getters.signData.sides[this.$store.getters.currentSide],
            ancestorId
          );
          this.deselectWithChildren(signChild);
        } else {
          this.$store.commit("removeSelectedElementId", event.element.id);
        }
        //deselect all fields, parts and repeats
        this.$store.commit("deselectFieldById", event.element.id);
        this.$store.commit("deselectPartById", event.element.id);
        this.$store.commit("deselectRepeatById", event.element.id);
      } else {
        // select the element
        // check if the selected element is part of a group
        let ancestorId = "";
        if (event.element.elementType === "g") {
          if (event.element.element) {
            if (!this.elementHasNoFillNoStrokeRect(event.element.element)) {
              ancestorId = event.element.id;
            }
          }
        }

        // let ancestorId = this.getAncestorId(event.element.id, "g");
        //if it is then select all children of the group

        if (ancestorId !== "") {
          let signChild = this.getSignChildById(
            this.$store.getters.signData.sides[this.$store.getters.currentSide],
            ancestorId
          );
          this.selectWithChildren(signChild);
        } else {
          // not part of a group so select it
          if (event.element.fieldType !== "repeat") {
            this.$store.commit("addSelectedElementId", event.element.id);
          } else {
            // deselect all repeats
            if (this.$store.getters.selectedRepeat) {
              this.deselectAllRepeats();
            }
            this.$store.commit("selectRepeatByName", event.element.name);
          }
        }
      }

      let el = this.getSignDataById(
        this.signData.sides[this.$store.getters.currentSide],
        event.element.id
      );

      if (el.fieldType === "repeat") {
        this.$store.commit("clearSelectedElementIds");

        for (let i = 0; i < this.$store.getters.repeats.length; i++) {
          if (
            this.$store.getters.repeats[i].name.toLowerCase() ===
            el.name.toLowerCase()
          ) {
            let repeat = this.$store.getters.repeats[i];
            this.$store.commit("selectRepeatByName", repeat.name);
            // add the repeats id to the selectedElementIds array so it can be selected
            this.$store.commit("addSelectedElementId", el.id);
            let s = this.$store.getters.canvas.select("svg");
            let selectBox = s.paper?.rect(
              repeat.x,
              repeat.y,
              repeat.width,
              repeat.height
            );
            selectBox?.attr({
              fill: "none",
              stroke: "#FFC0CB",
              strokeWidth: 2,
              class: "repeat-handles",
            });

            selectBox = s.paper?.rect(
              repeat.x,
              repeat.y,
              repeat.width,
              repeat.offset
            );
            selectBox?.attr({
              fill: "none",
              stroke: "#FFC0CB",
              strokeWidth: 2,
              class: "repeat-handles",
            });
          }
        }
      }
      el.isSelected = !el.isSelected;
      //this.$set(el, "isSelected", el.isSelected);
      //this.$store.commit("setSignData", this.signData);
      // //this.$store.commit('setSignDataTreeData',this.treeData)

      // if (el.children && el.children.length > 0) {
      //   //this.selectChildren(el.children, el.isSelected);
      //   this.selectSignChildren(el.children, el.isSelected);
      // }

      // // if (el.parent) {
      // //   this.targetAncestors(el.parent, el.isSelected);
      // // }
      // this.$store.commit("setSignData", this.signData);
      // this.$nextTick(function () {
      this.$store.commit("setRerender", !this.$store.getters.rerender);
      //   this.$store.commit("setAreElementsSelected", false);
      //   this.setElementsSelected(
      //     this.$store.getters.signData.sides[this.$store.getters.currentSide].children
      //   );
      //   this.$store.commit(
      //     "setSelectedElements",
      //     this.getSelectedSignElements()
      //   );
      // });

      //deselect all fields
      this.$store.commit("deselectAllFields");

      //select fields
      if (this.$store.getters.selectedElementIds.length > 0) {
        let fields = JSON.parse(JSON.stringify(this.$store.getters.fields));
        fields.forEach((field: IField) => {
          if (
            field.elementIds.length ===
            this.$store.getters.selectedElementIds.length
          ) {
            let matches: Array<boolean> = [];
            this.$store.getters.selectedElementIds.forEach(
              (selectedElement: string) => {
                matches.push(field.elementIds.includes(selectedElement));
              }
            );
            if (matches.includes(false)) {
              //pass
            } else {
              field.isSelected = true;
              this.$store.commit("updateFieldIsSelected", field);
            }
          }
        });
      }
      //deselect all parts
      this.$store.commit("deselectAllParts");

      //select parts
      let parts = JSON.parse(JSON.stringify(this.$store.getters.parts));
      parts.forEach((part: IPart) => {
        if (
          part.elementIds.length ===
          this.$store.getters.selectedElementIds.length
        ) {
          let matches: Array<boolean> = [];
          this.$store.getters.selectedElementIds.forEach(
            (selectedElement: string) => {
              matches.push(part.elementIds.includes(selectedElement));
            }
          );
          if (matches.includes(false)) {
            //pass
          } else {
            part.isSelected = true;
            this.$store.commit("updatePart", part);
          }
        }
      });

      //deselect all sign materials
      this.$store.commit("deselectAllSignMaterials");

      //select sign materials
      let signMaterials = JSON.parse(
        JSON.stringify(this.$store.getters.signMaterials)
      );
      signMaterials.forEach((signMaterial: ISignMaterial) => {
        if (
          signMaterial.elementIds.length ===
          this.$store.getters.selectedElementIds.length
        ) {
          let matches: Array<boolean> = [];
          this.$store.getters.selectedElementIds.forEach(
            (selectedElement: string) => {
              matches.push(signMaterial.elementIds.includes(selectedElement));
            }
          );
          if (matches.includes(false)) {
            //pass
          } else {
            signMaterial.isSelected = true;
            this.$store.commit("updateSignMaterial", signMaterial);
          }
        }
      });

      //deselect all sign processes
      this.$store.commit("deselectAllSignProcesses");

      //select sign materials
      let signProcesses = JSON.parse(
        JSON.stringify(this.$store.getters.signProcesses)
      );
      signProcesses.forEach((signProcess: ISignProcess) => {
        if (
          signProcess.elementIds.length ===
          this.$store.getters.selectedElementIds.length
        ) {
          let matches: Array<boolean> = [];
          this.$store.getters.selectedElementIds.forEach(
            (selectedElement: string) => {
              matches.push(signProcess.elementIds.includes(selectedElement));
            }
          );
          if (matches.includes(false)) {
            //pass
          } else {
            signProcess.isSelected = true;
            this.$store.commit("updateSignProcess", signProcess);
          }
        }
      });

      //deselect all alignments
      this.$store.commit("deselectAllAlignments");
      //select alignments
      let alignments = JSON.parse(
        JSON.stringify(this.$store.getters.alignments)
      );
      alignments.forEach((alignment: IAlignment) => {
        if (
          alignment.elementIds.length ===
          this.$store.getters.selectedElementIds.length
        ) {
          let matches: Array<boolean> = [];
          this.$store.getters.selectedElementIds.forEach(
            (selectedElement: string) => {
              matches.push(alignment.elementIds.includes(selectedElement));
            }
          );
          if (matches.includes(false)) {
            //pass
          } else {
            alignment.isSelected = true;
            this.$store.commit("selectAlignmentByUUID", alignment.uuid);
          }
        }
      });

      // deselect all repeats
      this.deselectAllRepeats();
      if (el.fieldType === "repeat") {
        this.$store.commit("selectRepeatByName", el.name);
        drawRepeatElements(this);
      }
    },
    /**
     * get the child elements that are selected
     * @param {Array<iSignChild>} elements - an array of child elements
     * @param {Array<iSignChild>} selectedElements - an array of elements that have already been selected
     * @returns {Array<iSignChild>} - an array of selected iSignChild elements
     */
    getSelectedChildElements: function (
      elements: Array<iSignChild>,
      selectedElements: Array<iSignChild>
    ) {
      elements.forEach((element: iSignChild) => {
        if (element.isSelected) {
          selectedElements.push(element);
        }
        if (element.children && element.children.length > 0) {
          this.getSelectedChildElements(element.children, selectedElements);
        }
      });
      return selectedElements;
    },
    /**
     * get selected elements for the sign
     * @returns {Array<iSignChild>} an array of selected iSignChild elements
     */
    getSelectedSignElements: function () {
      let selectedElements: Array<iSignChild> = [];
      this.getSelectedChildElements(
        this.$store.getters.signData.sides[this.$store.getters.currentSide]
          .children,
        selectedElements
      );
      return selectedElements;
    },
    /**
     * process the add sub-component event
     */
    addSubComponent: function () {
      let comp = {} as Component;
      if (this.componentData.isActive) {
        comp = this.componentData;
      } else {
        comp = this.getActiveComponent(this.componentData.children);
      }
      comp.children.unshift({
        id: uuidv4(),
        name: "Sub-Component",
        isActive: false,
        isVisible: true,
        isOpen: false,
        isEditing: false,
        isSelected: false,
        isTargetted: false,
        isOrderable: true,
        isNoFillNoStroke: false,
        parent: comp.id,
        children: [],
      });
      //comp.isOpen = true
    },
    /**
     * get the first component that has the isActive flag set
     * @param {Array<Component>} items - the components to search through
     * @returns {Component} - the active component or an empty object if none found
     */
    getActiveComponent: function (items: Component[]): Component {
      for (var i = 0; i < items.length; i++) {
        if (items[i].isActive) {
          return items[i];
        }

        if (items[i].children && items[i].children.length > 0) {
          let comp = this.getActiveComponent(items[i].children);
          if (comp) {
            return comp;
          }
        }
      }

      return {} as Component;
    },
    /**
     * process the set element visibility event
     * @param {iSignChild} el - the element to set visibility on
     */
    setElementVisibility: function (el: iSignChild) {
      const foundEl = this.getSignDataById(
        this.signData.sides[this.$store.getters.currentSide],
        el.id
      );
      if (foundEl) {
        foundEl.isVisible = !foundEl.isVisible;

        if (el.children && el.children.length > 0) {
          this.setChildVisibility(foundEl.children, foundEl.isVisible);
        }
        this.$store.commit(
          "setSignData",
          JSON.parse(JSON.stringify(this.signData))
        );
        this.$nextTick(function () {
          this.$store.commit("setRerender", !this.$store.getters.rerender);
        });
      }
    },
    /**
     * get the tree data element with a given id
     * @param {SignTreeData} - the sign tree data to search through
     * @param {string} - the id we are looking for
     * @returns {SignTreeData} - the found element or an empty object if none found
     */
    getTreeDataById: function (
      element: SignTreeData,
      id: string
    ): SignTreeData {
      if (element.saDataId === id) {
        return element;
      } else if (element.children && element.children.length > 0) {
        var i: number;
        var result = {} as SignTreeData;
        for (i = 0; this.isEmpty(result) && i < element.children.length; i++) {
          result = this.getTreeDataById(element.children[i], id);
        }
        return result;
      }
      return {} as SignTreeData;
    },
    /**
     * get the child element that has a given id
     * @param {iSignChild} element - the element to check
     * @param {string} id - the id we are searching for
     * @returns {iSignChild} the found element or an empty object if none found
     */
    getSignChildById: function (element: iSignChild, id: string): iSignChild {
      if (element.id === id) {
        return element;
      } else if (element.children && element.children.length > 0) {
        var result = {} as iSignChild;
        for (
          let i = 0;
          this.isEmpty(result) && i < element.children.length;
          i++
        ) {
          result = this.getSignChildById(element.children[i], id);
        }
        return result;
      }
      return {} as iSignChild;
    },
    /**
     * get the first iSignChild with a specific id
     * @param {iSignSide} element - the sign side to search
     * @param {string} id - the id we are searching for
     * @returns {iSignChild} - the found element or an empty object if no element found
     */
    getSignDataById: function (element: iSignSide, id: string): iSignChild {
      if (element.children && element.children.length > 0) {
        var i: number;
        var result = {} as iSignChild;
        for (i = 0; this.isEmpty(result) && i < element.children.length; i++) {
          result = this.getSignChildById(element.children[i], id);
        }
        return result;
      }
      return {} as iSignChild;
    },
    /**
     * test if an object is empty
     * @param {iSignChild | SignTreeData | Component} obj - the object we are testing
     * @returns {boolean} - True if object is empty
     */
    isEmpty: function (obj: iSignChild | SignTreeData | Component): boolean {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    /**
     * get the first component for a specific id
     * @param {Component} element - The component we are searching
     * @param {string} id - the id we are looking for
     * @returns {Component} - the component that was found or an empty object if none found
     */
    getComponentDataById: function (element: Component, id: string): Component {
      if (element.id == id) {
        return element;
      } else if (element.children && element.children.length > 0) {
        var i;
        var result = {} as Component;
        for (i = 0; this.isEmpty(result) && i < element.children.length; i++) {
          result = this.getComponentDataById(element.children[i], id);
        }
        return result;
      }
      return {} as Component;
    },
    /**
     * set the visibility for all child items
     * @param {Array<iSignChild>} items - the children to set visibility on
     * @param {boolean} isVisible - the value to set isVisible to
     */
    setChildVisibility: function (items: iSignChild[], isVisible: boolean) {
      items.forEach((item) => {
        item.isVisible = isVisible;
        if (item.children && item.children.length > 0) {
          this.setChildVisibility(item.children, isVisible);
        }
      });
    },
    /**
     * deselect all items in an array
     * @param {Array<iSignChild>} items - the array of items to deselect
     */
    deselectAll: function (items: Array<iSignChild>) {
      //                this.$store.commit('clearSelectedElements')
      items.forEach((item: iSignChild) => {
        item.isSelected = false;
        item.isTargetted = false;
        if (item.children && item.children.length > 0) {
          this.deselectAll(item.children);
        }
      });
    },
    /**
     * resize the ui elements to fit the window (called after a resize event)
     */
    fitStageIntoParentContainer: function () {
      this.canvasHeight = window.innerHeight - 100 - 30; // - 51 - 20;
      this.canvasWidth = window.innerWidth - 300 - 350 - 20;
      this.leftSidebarHeight = window.innerHeight - 100 - 30; // - 51 - 20;
      this.rightSidebarHeight = window.innerHeight - 100 - 30; // - 51 - 20;
    },
    /**
     * change initial zoom to the closest zoom factor
     * @param {number} initialZoom - the initial zoom to be changed
     * @returns {number} - the updated zoom
     */
    setZoomtoFactor: function (initialZoom: number) {
      const zoomFactorsLen = this.$store.getters.zoomFactors.length;
      let prevFactor = this.$store.getters.zoomFactors[zoomFactorsLen - 1];
      let nextFactor = 0;
      for (let i = zoomFactorsLen - 1; i >= 0; i--) {
        if (initialZoom <= this.$store.getters.zoomFactors[i]) {
          nextFactor = this.$store.getters.zoomFactors[i];
          break;
        }
        prevFactor = this.$store.getters.zoomFactors[i];
      }

      if (nextFactor - initialZoom <= initialZoom - prevFactor) {
        initialZoom = nextFactor;
      } else {
        initialZoom = prevFactor;
      }
      return initialZoom;
    },
  },
  watch: {},
});
