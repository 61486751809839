























import Vue from "vue";
import Konva from "./components/Konva.vue";
import Antd from "ant-design-vue";
import "./assets/antd.css";
import "./assets/signdesigner.css";
import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import ConfirmModal from "./components/modals/ConfirmModal.vue";

import {
  ISignTypeEdge,
  ISignType,
  IField,
  IFonts,
  iFont,
  iVisual,
  IMember,
  // IColorLibraryEdge,
  // IColorLibrary,
  // IColorItem,
  // IColorItemEdge,
  ITranslationLanguage,
  ITranslationLanguageEdge,
} from "./types";

import { IFieldEdge, IFontEdge, IColorEdge, IVisualEdge } from "./GraphqlTypes";

import {
  OrganizationDataQuery,
  OrganizationUUIDFromIdQuery,
  OrganizationIdFromSignTypeQuery,
  // ColorLibraries,
  TranslationLanguagesQuery,
  performQuery,
  UsersByIdQuery,
} from "@/features/SignDesigner/include/DBSupport";
import { underscoreToNormalCase } from "./include/Utils";

Vue.use(Antd);

export default Vue.extend({
  components: { Konva, FullPageLayout, ConfirmModal },
  props: {
    parameters: { type: Object }, // an object that contains signtypeId and organizationId
  },
  data: function () {
    return {
      modalHeight: 0,
      modalWidth: 0,
    };
  },
  mounted: function () {
    // set the ref
    this.$root.$refs.signDesigner = this;

    const signData = {
      id: "",
      uuid: "",
      folderId: "",
      libraryId: "",
      name: "",
      shortCode: "",
      details: "",
      hexColor: "",
      unitCost: "",
      numberOfSides: 0,
      numberOfColumns: 0,
      numberOfMessages: 0,
      isDirectional: false,
      markerId: "",
      useScaleInMessageSchedule: false,
      scale: 0,
      publish: false,
      markerNoScalePercent: 0,
      markerScaleId: "",
      markerScaleWidth: 0,
      markerScaleWidthUnit: "",
      price: "",
      priceCurrency: "",
      areSidesIdentical: false,
      width: 0,
      height: 0,
      measurementUnit: { unit: "", conversion: 0 },
      svgUrl: "",
      sides: [{ id: "", name: "", children: [] }],
    };

    this.$store.commit("setShowLoadingSpinner", true);
    this.$store.commit("setSignData", signData);
    this.$store.commit("setCanvas", {});
    this.$store.commit("setFields", []);
    this.$store.commit("setFiles", []);
    this.$store.commit("setParts", []);
    this.$store.commit("setColors", []);
    this.$store.commit("setFonts", []);
    this.$store.commit("setRepeats", []);
    this.$store.commit("setAlignments", []);
    this.$store.commit("setSelectedElementIds", []);
    this.$store.commit("setActivePanel", "layers");
    this.$store.commit("setPreviousSignTypeUUID", "");
    this.$store.commit("setCurrentSignTypeUUID", "");
    const userId = this.$store.state.session.userId;
    if (userId) {
      const userQuery = UsersByIdQuery(userId);
      performQuery(userQuery).then((data) => {
        const user: IMember = {
          id: data.data.user.id,
          firstName: data.data.user.firstName,
          lastName: data.data.user.lastName,
          email: data.data.user.email,
          userType: "builder",
        };
        this.$store.commit("setUser", user);

        const orgIDquery = OrganizationIdFromSignTypeQuery(
          this.$route.params.id
        );
        performQuery(orgIDquery)
          .then((signData) => {
            this.$store.commit(
              "setOrganizationId",
              signData.data.signType.project.id
            );

            const query = OrganizationUUIDFromIdQuery(
              this.$store.getters.organizationId
            );

            performQuery(query)
              .then((orgData) => {
                const organizationUUID = orgData.data.organization.uuid;
                this.$store.commit("clearSignTypes");
                const query2 = OrganizationDataQuery(
                  orgData.data.organization.uuid
                );
                performQuery(query2)
                  .then((data) => {
                    if (data.data.organization) {
                      this.$store.commit(
                        "setCurrentOrganizationUUID",
                        data.data.organization.uuid
                      );

                      data.data.organization.fields?.edges.forEach(
                        (edge: IFieldEdge) => {
                          let field: IField = {
                            id: edge.node.id,
                            isSelected: false,
                            isUsed: false,
                            name: underscoreToNormalCase(edge.node.name),
                            type: edge.node.fieldType,
                            x: 0,
                            y: 0,
                            width: 0,
                            height: 0,
                            capHeight: 0,
                            leading: 0,
                            lines: 1,
                            letterSpacing: "0",
                            horizontalAlignment: "left",
                            verticalAlignment: "top",
                            hasPlaceholderImage: false,
                            placeholderImageUrl: "",
                            belongsToRepeatId: null,
                            elementIds: [],
                          };
                          this.$store.commit("loadField", field);
                        }
                      );
                      let orgFonts: IFonts = {};
                      data.data.organization.fonts?.edges.forEach(
                        (edge: IFontEdge) => {
                          const currentFont: iFont = edge.node;
                          if (!orgFonts[currentFont.name]) {
                            // console.debug(`font: ${currentFont.name}`);
                            orgFonts[currentFont.name] = currentFont;
                          }
                        }
                      );
                      this.$store.commit("setFonts", orgFonts);
                      // let colors = [] as Array<iColor>;
                      data.data.organization.colors?.edges.forEach(
                        (edge: IColorEdge) => {
                          let color = {
                            id: edge.node.uuid,
                            name: edge.node.name,
                            label: edge.node.name,
                            library: "",
                            match: "",
                            hex: edge.node.color,
                            rgb: {
                              r: edge.node.color.substr(0, 2),
                              g: edge.node.color.substr(2, 2),
                              b: edge.node.color.substr(4, 2),
                            },
                          };
                          this.$store.commit("addColor", color);
                        }
                      );
                      //            this.$store.commit("setColors", colors);

                      let visuals = [] as Array<iVisual>;
                      data.data.organization.visuals?.edges.forEach(
                        (edge: IVisualEdge) => {
                          visuals.push(edge.node);
                        }
                      );
                      this.$store.commit("setVisuals", visuals);

                      // let families = [] as Array<IProductFamily>;
                      // data.data.organization.partFamilies?.edges.forEach(
                      //   (edge: IProductFamilyEdge) => {
                      //     families.push(edge.node);
                      //   }
                      // );
                      // this.$store.commit("setProductFamilies", families);

                      // let categories = [] as Array<IProductCategory>;
                      // data.data.organization.partCategories?.edges.forEach(
                      //   (edge: IProductCategoryEdge) => {
                      //     categories.push(edge.node);
                      //   }
                      // );
                      // this.$store.commit("setProductCategories", categories);

                      // let productTypes = [] as Array<IProductType>;
                      // data.data.organization.partTypes?.edges.forEach(
                      //   (edge: IProductTypeEdge) => {
                      //     productTypes.push(edge.node);
                      //   }
                      // );
                      // this.$store.commit("setProductTypes", productTypes);
                    }

                    data.data.organization.signTypes.edges.forEach(
                      (edge: ISignTypeEdge) => {
                        let signType: ISignType = {
                          id: edge.node.id,
                          uuid: edge.node.uuid,
                          isSelected: false,
                          name: edge.node.name,
                          fullPath: edge.node.fullPath,
                          shortCode: edge.node.shortCode,
                          hexColor: edge.node.hexColor,
                        };
                        this.$store.commit("addSignType", signType);
                      }
                    );

                    // const query4 = ColorLibraries();
                    // performQuery(query4).then((data) => {
                    //   if (data.errors) {
                    //     console.log(data.errors);
                    //   } else {
                    //     let colorLibraries: Array<IColorLibrary> = [];
                    //     data.data.colorLibraries.edges.forEach(
                    //       (edge: IColorLibraryEdge) => {
                    //         let colorLibrary: IColorLibrary = {
                    //           id: "",
                    //           label: edge.node.name,
                    //           description: edge.node.name,
                    //           code: "",
                    //           colors: [],
                    //           publish: edge.node.publish,
                    //         };
                    //         edge.node.colors.edges.forEach(
                    //           (colorEdge: IColorItemEdge) => {
                    //             let colorItem: IColorItem = {
                    //               id: colorEdge.node.contentObjectId,
                    //               uuid: colorEdge.node.contentObjectId,
                    //               name: colorEdge.node.name,
                    //               description: colorEdge.node.description,
                    //               gloss: colorEdge.node.gloss,
                    //               included: true,
                    //               hexCode: colorEdge.node.hexValue,
                    //             };
                    //             // colorLibrary.colors[colorLibrary.colors.length] =
                    //             //   colorItem;
                    //             colorLibrary.colors.push(colorItem);
                    //           }
                    //         );
                    //         colorLibraries.push(colorLibrary);
                    //       }
                    //     );
                    //     this.$store.commit("setColorLibraries", colorLibraries);
                    //   }

                    const TLQuery = TranslationLanguagesQuery(organizationUUID);
                    performQuery(TLQuery).then((TLdata) => {
                      let translationLanguages: Array<ITranslationLanguage> =
                        [];

                      TLdata.data.organization.translationLanguages.edges.forEach(
                        (edge: ITranslationLanguageEdge) => {
                          let translationLanguage: ITranslationLanguage = {
                            id: edge.node.id,
                            uuid: edge.node.uuid,
                            name: edge.node.name,
                          };
                          translationLanguages.push(translationLanguage);
                        }
                      );

                      this.$store.commit(
                        "setTranslationLanguages",
                        translationLanguages
                      );

                      if (this.$route.params.id !== "") {
                        const query3 = OrganizationIdFromSignTypeQuery(
                          this.$route.params.id
                        );
                        performQuery(query3).then((data) => {
                          const currentSign = data.data.signType;
                          this.$store.commit(
                            "setCurrentSignTypeUUID",
                            currentSign.uuid
                          );
                        });
                      } else {
                        //by setting the previousSignTypeUUID to something then we will force an update of the Sign Types List
                        this.$store.commit("setPreviousSignTypeUUID", "empty");
                        this.$store.commit("setCurrentSignTypeUUID", "");
                      }
                    });
                    // });
                  })
                  .catch((err) => {
                    alert(err);
                  });
              })
              .catch((err) => {
                alert(err);
              });
          })
          .catch((err) => {
            alert(err);
          });
      });
    }

    // await fetch("/graphql/", {
    //   method: "POST",
    //   body: userQuery,
    //   headers: {
    //     "content-type": "application/json",
    //     Accept: "application/json",
    //   },
    // }).then((data) => {
    //   // const data = result.data;
    //   const user: IMember = {
    //     id: this.parameters.userId,
    //     firstName: data.users.edges.node.firstName,
    //     lastName: data.users.edges.node.lastName,
    //     email: data.users.edges.node.email,
    //     userType: "builder",
    //   };
    //   this.$store.commit("setUser", user);
    // });

    // this.$store.commit("setCurrentSignTypeUUID", this.signTypeId);
    this.fitModalIntoParentContainer();

    window.addEventListener("resize", this.fitModalIntoParentContainer);

    window.addEventListener("click", this.clickAwayZoomMenu);
  },
  methods: {
    clickCancelButton(): void {
      this.$emit("done");
    },
    fitModalIntoParentContainer: function () {
      this.modalHeight = window.innerHeight - 50;
      this.modalWidth = window.innerWidth - 20;
    },
    onCloseModal: function (event: Event) {
      if (this.$store.getters.isChanged) {
        event.preventDefault();
        event.stopPropagation();
        this.$store.commit("setShowConfirmModal", true);
      } else {
        window.location.reload();
      }
    },
    saveChanges: function () {
      if (this && this.$root && this.$root.$refs && this.$root.$refs.TopBar) {
        this.$store.commit("setExitAfterPublish", true);
        /* eslint-disable-next-line */
        (this.$root.$refs.TopBar as any).showPublishModal();
        // /* eslint-disable-next-line */
        // (this.$root.$refs.TopBar as any).saveSignType().then(() => {
        //   this.$store.commit("setIsChanged", false);
        //   //eslint-disable-next-line
        //   (this.$refs.modallayout as any).$emit("close");
        // });
      }
    },
    closeModalWithoutSaving: function () {
      // ensure that the Save Changes dialog doesn't show
      this.$store.commit("setIsChanged", false);
      //eslint-disable-next-line
      (this.$refs.modallayout as any).$emit("close");
    },
    discardChanges: function () {
      this.$store.commit("setIsChanged", false);
      //eslint-disable-next-line
      (this.$refs.modallayout as any).$emit("close");
    },
    clickAwayZoomMenu: function (e: Event) {
      if (!(e.target as Element).closest(".zoom-menu")) {
        this.$store.commit("setShowZoomMenu", false);
      }
    },
  },
});
