




































import Vue from "vue";
import { PropType } from "vue";

import $ from "jquery";
import { capitalize } from "@/filters/common";

import EditPaymentInfoModal from "../modals/EditPaymentInfoModal.vue";
import EditTaxInfoModal from "../modals/EditTaxInfoModal.vue";
import ShowPaymentMethodsModal from "../modals/ShowPaymentMethodsModal.vue";

import { IPaymentMethod } from "../types";

export default Vue.extend({
  filters: { capitalize },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    paymentMethods: {
      type: Array as PropType<IPaymentMethod[]>,
    },
  },
  components: {
    EditPaymentInfoModal,
    EditTaxInfoModal,
    ShowPaymentMethodsModal,
  },
  data: function () {
    return {
      taxNumber: "809512767 RT0001",
    };
  },
  methods: {
    editPaymentInfo: function () {
      $("#edit-payment-info-modal").modal({ show: true });
    },
    editTaxInfo: function () {
      $("#edit-tax-info-modal").modal({ show: true });
    },
    showPaymentMethods: function () {
      $("#show-payment-methods-modal").modal({ show: true });
    },
    capitalize: function (value: string): string {
      return capitalize(value);
    },
  },
  computed: {
    defaultPaymentMethod: function (): IPaymentMethod | undefined {
      if (this.paymentMethods) {
        return this.paymentMethods.find((paymentMethod) => {
          return paymentMethod.default;
        });
      } else {
        return undefined;
      }
    },
  },
  watch: {},
});
