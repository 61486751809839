









































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";

import {
  ICompany,
  INonMigratedCompany,
  IMember,
  ISubscription,
  ISubscriptionItem,
  IPlanAndTiers,
  IMemberLicense,
} from "../types";
import MemberList from "../components/MemberList.vue";
import ManagePlanModal from "../modals/ManagePlanModal.vue";

export default Vue.extend({
  components: { MemberList, ManagePlanModal },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    migrated: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      selectedCompanyIndex: -1,
      members: [] as IMember[],
      searchText: "",
      plannedUserCount: 0,
      localPlanType: "",
      startBillingDate: new Date(),
      tableHeight: 400,
      showManagePlanModal: false,
      subscription: {} as ISubscription,
      subscriptionItem: {} as ISubscriptionItem,
      selectedPlanAndTier: null as IPlanAndTiers | null,
      licenseType: "",
      licensingMember: {} as IMember,
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.setTableHeight);
    this.setTableHeight();
  },
  methods: {
    onLicensingMemberUpdated: function (licensingMember: IMember) {
      this.licensingMember = licensingMember;
    },
    onSearchTextChanged: function () {
      this.selectedCompanyIndex = -1;
      this.$emit("searchTextChanged", this.searchText);
    },
    onCloseManagePlanModal: function () {
      this.showManagePlanModal = false;
      this.selectedPlanAndTier = null;
    },
    setTableHeight: function () {
      this.tableHeight = window.innerHeight - 350;
    },
    /**
     * formats the address of a company into a readable string
     * @param {ICompany} company - the company whose address we wish to format
     * @returns {string} - a formatted address string
     */
    formatAddress(company: ICompany): string {
      return [company.state, company.countryCode].join(", ");
    },
    /**
     * changes the selected company index
     * @param {number} index - index of the company we wish to select (in the companies prop)
     */
    selectCompany(index: number) {
      if (this.selectedCompanyIndex !== index) {
        this.selectedPlanAndTier = null;
        this.selectedCompanyIndex = index;
      } else {
        this.selectedCompanyIndex = -1;
      }
    },
    /**
     * fetch the members for a given company
     * @param {number} companyIndex - index of the company we wish to select (in the companies prop)
     */
    getMembers(companyIndex: number) {
      const company = this.companies[companyIndex] as INonMigratedCompany;
      const billingOwnerId = company.billingOwner.id;
      const query = JSON.stringify({
        query: `query get_members {
          unmigratedCompanies(payor: ${billingOwnerId}) {
            edges {
              node {
                users {
                  id: userId
                  firstName
                  lastName
                  email
                  companyLicenses {
                    type: licenseType
                    endDate: effectiveEndDate
                    billingCycle: billingFrequency
                    onFreeTrial
                    autoRenew
                  }
                }
                payor {
                  id: userId
                }
              }
            }
          }
        }`,
      });
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          const members = [] as IMember[];
          result.data.unmigratedCompanies.edges[0].node.users.forEach(
            (user: any) => {
              let userLicenses: IMemberLicense[] = [];

              if (user.companyLicenses) {
                user.companyLicenses.forEach((license: IMemberLicense) => {
                  if (new Date(license.endDate) > new Date()) {
                    userLicenses.push(license);
                  }
                });
              }

              const localMember: IMember = {
                id: user.id,
                agreesToTerms: true,
                emailFrequency: "H",
                image: "",
                isBuilder: false,
                phone: "",
                role: "A_2",
                profileId: 0,
                twoFAMethod: "E",
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                companyLicenses: userLicenses,
              };

              members.push(localMember);
            }
          );
          members.forEach((user: IMember) => {
            if (
              user.id !== result.data.unmigratedCompanies.edges[0].node.payor.id
            ) {
              user.role = "MEMBER";
            } else {
              user.role = "BILLING_OWNER";
            }
          });
          this.members = members;
        });
    },
    /**
     * emits an event with the following parameters to the parent component
     * @param {ICompany} newCompany - the company that we are going to create
     * @param {IMember[]} members - list of members for the new company
     * @param {string} billingMethod - how the company will be paying (either "online" or "offline")
     * @param {string} subscriptionItems - array of price keys and quantities necessary to create the subscription item
     * @param {string} newSubscriptionBillingCycle - billing cycle of the new subscription (either "MONTH" or "YEAR")
     */
    migrateButtonClicked(
      newCompany: ICompany,
      members: IMember[],
      billingMethod: string,
      subscriptionItems: Array<{ price: string; quantity: number }>,
      newSubscriptionBillingCycle: string,
      nextBillingDate: Date
    ) {
      this.startBillingDate = nextBillingDate;

      this.$emit(
        "migrateButtonClicked",
        newCompany,
        members,
        billingMethod,
        subscriptionItems,
        newSubscriptionBillingCycle,
        this.startBillingDate
      );
    },
    /**
     * emits an event with the following parameters to the parent component
     * @param {number} userCount - the number of members that will be using the plan
     * @param {string} planType - the type of plan to be set up (either "WEB" or "MOBILE")
     */
    setUpPlan(licenseType: string, userCount: number, planType: string) {
      this.licenseType = licenseType;
      this.plannedUserCount = userCount;
      this.localPlanType = planType;

      if (this.selectedPlanAndTier) {
        this.subscriptionItem = {
          id: 0,
          plan: {
            id: 0,
            name: this.selectedPlanAndTier.name,
          },
          priceTierId: 0,
          billingCycle: this.selectedPlanAndTier.tiers[0].cycle,
          upTo: userCount,
          amount: this.selectedPlanAndTier.tiers[0].amount,
          currency: this.selectedPlanAndTier.tiers[0].currency,
          remainingSeats: userCount - this.selectedPlanAndTier.tiers[0].upTo,
          stripeSubscriptionId: "",
          stripePriceId: this.selectedPlanAndTier.stripePriceId,
        };
      } else {
        let billingCycle = "MONTH";
        if (this.licensingMember) {
          billingCycle =
            this.licensingMember.companyLicenses[0].billingCycle === "Y"
              ? "YEAR"
              : "MONTH";
        }
        this.subscriptionItem = {
          id: 0,
          plan: {
            id: 0,
            name: "No plan",
          },
          priceTierId: 0,
          billingCycle: billingCycle,
          upTo: userCount,
          amount: 0,
          currency: "USD",
          remainingSeats: 0,
          stripeSubscriptionId: "",
          stripePriceId: "",
        };
      }

      this.subscription = {
        id: 0,
        companyId: 0,
        startDate: new Date(),
        endDate: null,
        nextBillingDate: new Date(),
        billingOwnerId: 0,
        status: 1,
        freeTrialEndDate: new Date(),
        stripeSubscriptionId: "",
        items: [this.subscriptionItem],
      };

      this.showManagePlanModal = true;
    },
    /**
     * converts a list of users to a list of ids
     * @returns {number[]} - list of user ids for the selected company
     */
    toIdList(users: IMember[]): number[] {
      return users.map((user) => user.id);
    },
    /**
     * scrolling animation to the selected companies position on the display
     * @param {number} index - index of the company we wish to scroll to
     */
    scrollToCompany(index: number) {
      let element = document.getElementById(
        "nonMigratedCompanyList"
      ) as HTMLElement;
      let top = document.getElementById("company" + index)?.offsetTop;
      if (top) {
        top = top + 40; // 40px is the height of each list item
      }
      element.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    },
    /**
     * updates info in child components when changes are made using manage plan modal
     * @param {string} planType - the type of plan that was selected (either "WEB" or "MOBILE")
     * @param {number} priceTierId - id of the price tier to be applied
     */
    updatePlanPreview(licenseType: string, selectedPlan: IPlanAndTiers) {
      // const companyListRefs = (this as Vue).$refs;
      // const memberList = (companyListRefs.memberList as Vue[])[0];
      // if (planType === "WEB") {
      //   (
      //     memberList as Vue & { selectedWebTierId: number[] }
      //   ).selectedWebTierId.push(priceTierId);
      // } else if (planType === "MOBILE") {
      //   (
      //     memberList as Vue & { selectedMobileTierId: number[] }
      //   ).selectedMobileTierId.push(priceTierId);
      // }
      this.selectedPlanAndTier = selectedPlan;
      this.members.forEach((member) => {
        member.companyLicenses.forEach((license) => {
          if (license.type === licenseType) {
            Vue.set(license, "selectedPlanAndTier", selectedPlan);
          }
        });
      });

      this.showManagePlanModal = false;
    },
  },
  computed: {
    /**
     * billing owner of the selected company
     * @returns {IMember} - billing owner for the selected company
     */
    billingOwner(): IMember {
      if (this.selectedCompanyIndex !== -1) {
        const companies = this.companies as INonMigratedCompany[];
        return companies[this.selectedCompanyIndex].billingOwner;
      } else {
        return {} as IMember;
      }
    },
    filteredCompanies: function (): Array<ICompany> {
      if (this.searchText === "") {
        return this.companies as Array<ICompany>;
      } else {
        return (this.companies as Array<ICompany>).filter(
          (company: ICompany) => {
            return company.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          }
        );
      }
    },
    billingOwnerLicense: function (): IMember {
      if (this.members.length > 0) {
        const index = this.members.findIndex((member) => {
          return member.id === this.billingOwner.id;
        });
        if (index !== -1) {
          return this.members[index];
        } else {
          return this.members[0];
        }
      } else {
        return {
          id: 0,
          profileId: 0,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          image: "",
          role: "",
          twoFAMethod: "",
          emailFrequency: "",
          companyLicenses: [
            {
              member: 0,
              type: "O",
              endDate: "",
              billingCycle: "",
              autoRenew: false,
              onFreeTrial: false,
              subscriptionItem: {
                planPrice: {
                  pricingPlan: {
                    id: 0,
                    name: "",
                  },
                },
              },
              selectedPlanAndTier: null,
            },
          ],
          agreesToTerms: false,
          isBuilder: false,
        };
      }
    },
  },
  watch: {
    /**
     * when the selected company changes, get members for the new company and scroll to it
     */
    selectedCompanyIndex(newValue) {
      this.members = [] as IMember[];
      if (newValue !== -1) {
        this.getMembers(newValue);
        setTimeout(() => this.scrollToCompany(newValue), 50);
      }
    },
  },
});
