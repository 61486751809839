










































































import Vue from "vue";
import $ from "jquery";

import {
  StripeElements,
  StripeCardElement,
  Token,
  StripeCardNumberElement,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeError,
  PaymentMethod,
  Stripe,
  PaymentMethodResult,
  TokenResult,
} from "@stripe/stripe-js";

export default Vue.extend({
  props: ["companyId"],
  data: function () {
    return {
      paymentElement: undefined as StripeCardElement | undefined,
      elements: undefined as StripeElements | undefined,
      // clientSecret: "" as StripeElementsOptionsClientSecret,
      token: {} as Token,
      nameOnCard: "",
      cardNumberElement: {} as StripeCardNumberElement,
      cardCVCElement: {} as StripeCardCvcElement,
      cardExpiryElement: {} as StripeCardExpiryElement,
      errorMessage: "" as string | undefined,
      stripe: null as Stripe | null,
    };
  },
  mounted: async function () {
    if (window.Stripe) {
      if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }
    }

    if (this.stripe) {
      this.elements = this.stripe.elements();

      if (this.elements) {
        this.cardNumberElement = this.elements.create("cardNumber", {
          showIcon: true,
        });
        this.cardExpiryElement = this.elements.create("cardExpiry");
        this.cardCVCElement = this.elements.create("cardCvc");

        this.cardNumberElement.mount("#cardNumber");
        this.cardExpiryElement.mount("#cardExpiry");
        this.cardCVCElement.mount("#cardCVC");
      }
      // this.paymentElement = this.elements.create("card");
      // this.paymentElement.mount("#payment-element");
    }
    // });
  },
  methods: {
    saveChanges: async function (e: Event) {
      e.preventDefault();

      this.elements?.submit();

      if (this.stripe) {
        this.stripe
          .createPaymentMethod({
            type: "card",
            card: this.cardNumberElement,
            billing_details: {
              name: this.nameOnCard,
            },
          })
          .then((result: PaymentMethodResult) => {
            if (!result.error && result.paymentMethod.id) {
              const query = JSON.stringify({
                query: `
                    mutation attach_payment_method {
                      attachPaymentMethod(input: {
                        companyId: ${this.companyId},
                        paymentMethodId: "${result.paymentMethod.id}"
                      }) {
                        # errors { messages }
                        success
                      }
                    }`,
              });
              // attach payment method on backend
              fetch("/graphql/", {
                method: "POST",
                body: query,
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => {
                  return res.json();
                })
                .then((result) => {
                  if (result.errors) {
                    this.errorMessage = result.errors[0].message;
                  }
                  // else if (
                  //   result.data.attachPaymentMethod?.errors.length > 0
                  // ) {
                  //   this.errorMessage =
                  //     result.data.attachPaymentMethod.errors[0].messages.join(
                  //       ", "
                  //     );
                  else if (
                    result.data.attachPaymentMethod &&
                    result.data.attachPaymentMethod.success
                  ) {
                    $("#edit-payment-info-modal").modal("hide");
                    this.$emit("cardAdded");
                    // window.location.href =
                    //   "/company/" + this.companyId + "/billing";
                  } else {
                    this.errorMessage = "Unknown error";
                  }
                });
            } else {
              if (result.error) {
                this.errorMessage = result.error.message;
              }
            }
          });
      }
      if (this.stripe) {
        //   if (this.paymentElement) {
        this.stripe
          .createToken(this.cardNumberElement)
          .then((result: TokenResult) => {
            // Handle result.error or result.token
            if (result.error) {
              alert(result.error.message);
            } else {
              this.token = result.token;
            }
          });
        //   }
      }
    },
    // numberOfUsers: function (): number {
    //   if (this.plan.id) {
    //     return this.$store.getters.activeUserLicenses.length;
    //   } else {
    //     return 0;
    //   }
    // },
  },
  // watch: {
  //   plan: function () {
  //     this.newTier = this.validPriceTiers[0].id;
  //   },
  //   newTier: function (newValue) {
  //     this.newTotal = this.$store.getters.priceTierById(newValue).price;
  //   },
  // },
  computed: {},
  watch: {},
});
