import Snap from "snapsvg-cjs-ts";
//import opentype from "opentype.js";
import { INameAndAttributes } from "../types";
//import { SAFonts } from "./SAFonts";
import { SAFontInfo, FontMetric } from "./SAFontInfo";

export function makeFloat(strValue: string, defaultValue = 0): number {
  const value = parseFloat(strValue);
  return isNaN(value) ? defaultValue : value;
}

/**
 * Get the associated cssName for an element
 * @param element
 */
export function elementCssName(element: Snap.Element): string {
  let retval = element.attr("class");
  if (retval !== "") {
    retval = "." + retval;
  }

  return retval;
}

/**
 * Get the element name
 * @param element
 */
export function getElementName(element: Snap.Element | null): string {
  let name = element?.attr("data-name");
  if (name === null) {
    name = element?.node.id;
  }
  if (name) {
    // trim name and replace multiple spaces with a single space
    name = name.trim().replace(/\s+/g, " ");
    // remove adobe xml strings
    name = reverseTransformString(name);
  }
  return name !== null && name !== undefined ? name : "";
}

/**
 * Returns the first word of an
 * @param element
 * ToDo: Ensure that if the name is followed by a number that the space between the name and number is replaced with an underscore!!!
 */
export function getElementNameAttributes(
  element: Snap.Element | null
): INameAndAttributes {
  const nameSpaceAttributesReg = /(\w+)([ t]*)?(.*)/;
  const nameAndAttribute = getElementName(element);
  let name = "";
  let attributes = "";

  if (nameAndAttribute !== null) {
    const result = nameSpaceAttributesReg.exec(nameAndAttribute);

    if (result) {
      name = result[1].trim();
      attributes = result[0].trim();
    }
  }
  return {
    name: name,
    attributes: attributes,
  };
}

/**
 *  Get the child element that matches specified type
 * @param element
 * @param type
 * @param recurse - when true recurse
 */
export function getChildElementOfType(
  element: Snap.Element,
  type: string,
  recurse = false
): Snap.Element | null {
  let retval = null;
  const children = element.children();

  for (let i = 0; i < children.length && retval === null; i++) {
    const child = children[i];

    if (child.type === type) {
      retval = child;
    } else if (child.type == "g") {
      retval = getChildElementOfType(child, type, recurse);
    }
  }
  return retval;
}
/**
 *  Get the child element that matches specified type
 * @param element
 * @param type
 * @param recurse - when true recurse
 */
export function getChildElementWithId(
  element: Snap.Element,
  name: string,
  recurse = false
): Snap.Element | null {
  let retval = null;
  const children = element.children();

  for (let i = 0; i < children.length && retval === null; i++) {
    const child = children[i];

    if (child.attr("id") === name) {
      retval = child;
    } else if (child.type == "g") {
      retval = getChildElementWithId(child, name, recurse);
    }
  }
  return retval;
}

export function getChildElementsBasename(
  element: Snap.Element,
  baseName: string,
  recurse = false
): Snap.Element[] {
  let levelElements: Snap.Element[] = [];
  if (element) {
    const children = element.children();

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const elementName = getElementName(child).trim();
      if (elementName.indexOf(baseName) === 0) {
        levelElements.push(child);
      } else if (child.type === "g" && recurse) {
        const elements = getChildElementsBasename(child, baseName, recurse);
        levelElements = levelElements.concat(elements);
      }
    }
  }

  return levelElements;
}

export function capitalizeWord(word: string): string {
  return word.slice(0, 1).toUpperCase() + word.slice(1);
}

/**
 * Transforms the input string by replacing special characters with their URL encoded equivalents
 * and appending "-2" at the end of the string.
 *
 * @param {string} input - The string to be transformed.
 * @return {string} The transformed string with special characters replaced and "-2" appended at the end.
 */
export function transformString(input: string): string {
  // Mapping of special characters to their URL encoded equivalents, except space
  const urlEncodedMapping: Record<string, string> = {
    " ": "_",
    "!": "_x21_",
    '"': "_x22_",
    "#": "_x23_",
    $: "_x24_",
    "%": "_x25_",
    "&": "_x26_",
    "'": "_x27_",
    "(": "_x28_",
    ")": "_x29_",
    "*": "_x2A_",
    "+": "_x2B_",
    ",": "_x2C_",
    "-": "_x2D_",
    ".": "_x2E_",
    "/": "_x2F_",
    ":": "_x3A_",
    ";": "_x3B_",
    "<": "_x3C_",
    "=": "_x3D_",
    ">": "_x3E_",
    "?": "_x3F_",
    "@": "_x40_",
    "[": "_x5B_",
    "\\": "_x5C_",
    "]": "_x5D_",
    "^": "_x5E_",
    _: "_x5F_",
    "`": "_x60_",
    "{": "_x7B_",
    "|": "_x7C_",
    "}": "_x7D_",
    "~": "_x7E_",
  };

  // Replace characters based on the mapping
  const transformedString = input.replace(
    /[^a-zA-Z0-9]/g,
    function (match: string) {
      return urlEncodedMapping[match] || match;
    }
  );

  return transformedString;
}

/**
 * Reverses a string transformation by replacing encoded sequences with their corresponding characters
 * and removing a trailing "-2" if it exists.
 *
 * @param {string} input - The transformed string to be reversed.
 * @return {string} - The original string with special characters restored and trailing "-2" removed.
 */
export function reverseTransformString(input: string): string {
  // Mapping of URL encoded equivalents back to their special characters
  const reverseUrlEncodedMapping: Record<string, string> = {
    _: " ",
    _x21_: "!",
    _x22_: '"',
    _x23_: "#",
    _x24_: "$",
    _x25_: "%",
    _x26_: "&",
    _x27_: "'",
    _x28_: "(",
    _x29_: ")",
    _x2a_: "*",
    _x2b_: "+",
    _x2c_: ",",
    _x2d_: "-",
    _x2e_: ".",
    _x2f_: "/",
    _x3a_: ":",
    _x3b_: ";",
    _x3c_: "<",
    _x3d_: "=",
    _x3e_: ">",
    _x3f_: "?",
    _x40_: "@",
    _x5b_: "[",
    _x5c_: "\\",
    _x5d_: "]",
    _x5e_: "^",
    _x5f_: "_",
    _x60_: "`",
    _x7b_: "{",
    _x7c_: "|",
    _x7d_: "}",
    _x7e_: "~",
  };

  // Replace _x??_ sequences with their corresponding characters
  const reversedString = input.replace(
    /(_x[0-9A-Fa-f]{2}_|_)/g,
    function (match) {
      return reverseUrlEncodedMapping[match.toLowerCase()] || match;
    }
  );

  return reversedString;
}

export function underscoreToNormalCase(srcString: string): string {
  let str: string;
  if (srcString && typeof srcString === "string") {
    let normalcase = reverseTransformString(srcString);
    normalcase = normalcase.replace(/_/g, " ");
    const words = normalcase.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] !== "") {
        words[i] = capitalizeWord(words[i]);
      }
    }

    str = words.join(" ");
  } else {
    str = "";
  }
  return str;
}

export function hasXMLHexStrings(target: string): boolean {
  const transformRegEx = /_x[0-9A-Fa-f]{2}_/;
  return transformRegEx.test(target);
}

export function normalCaseToUnderscore(srcString: string): string {
  let str: string;
  if (srcString) {
    //replace one or more white spaces with single underscore
    const normalcase = srcString.trim().replace(/\s+/g, "_");
    const words = normalcase.split("_");
    for (let i = 0; i < words.length; i++) {
      if (words[i] !== "") {
        words[i] = words[i].toLowerCase();
      }
    }

    str = words.join("_");
  } else {
    str = "";
  }
  return str;
}

/**
 *
 * @param svg
 * @returns Snap.Element[]
 */
export function getSides(svg: Snap.Element): Snap.Element[] {
  return getChildElementsBasename(svg, "side_", true);
}

/**
 * Finds the size of the child elements that have been replaced
 * @param element
 * @returns {null}
 */
export function sizeToChildBBox(element: Snap.Element): Snap.BBox | null {
  let largerBBox = null;
  const children = element.children();
  for (let i = 0; i < children.length; i++) {
    let bbox: Snap.BBox | null = null;
    const child = children[i];

    switch (child.type) {
      case "text":
      case "image":
      case "svg":
        bbox = resizeElementBBox(child);
        break;
      case "g":
        bbox = sizeToChildBBox(child);
        break;
      default:
    }
    largerBBox = combineBBox(largerBBox, bbox);
  }

  return largerBBox;
}

function applyAdjustment(bbox: Snap.BBox, adjustment: Adjustment): Snap.BBox {
  bbox.y += adjustment.fromTop;
  bbox.y2 -= adjustment.fromBottom;
  bbox.y2 = bbox.y2 < bbox.y ? bbox.y : bbox.y2;
  return recalcBBox(bbox);
}

export type Adjustment = {
  height: number;
  fontMetrics: FontMetric | null;
  fromTop: number;
  fromBottom: number;
};

export function getElementAdjustment(element: Snap.Element): Adjustment {
  const retval: Adjustment = {
    height: 0,
    fontMetrics: null,
    fromTop: 0,
    fromBottom: 0,
  };
  clearRects();

  //	showBBox(parent, bbox, "blue");
  let textDist;
  let elementToFindDist = null;
  //let cssId;
  if (element.type === "g") {
    const textElements: Snap.Element[] = findChildrenOfType(element, "text");

    if (textElements.length > 0) {
      //cssId = elementCssName(textElements[0]);
    }
    if (textElements.length > 1) {
      elementToFindDist = textElements[0];
    } else if (textElements.length === 1) {
      const tspans = findChildrenOfType(textElements[0], "tspan");

      if (tspans.length > 0) {
        elementToFindDist = tspans[0];
      } else {
        elementToFindDist = textElements[0];
      }
    }
  } else if (element.type === "text") {
    // cssId = elementCssName(element);
    const tspans = findChildrenOfType(element, "tspan");

    if (tspans.length > 0) {
      elementToFindDist = tspans[0];
    } else {
      elementToFindDist = element;
    }
  }

  if (elementToFindDist) {
    //		showBBox(element, tspanBBox, "green");
    textDist = getTextDistance(elementToFindDist);
    retval.fromTop = textDist.fromTop;
    retval.fromBottom = textDist.fromBottom;
  }
  return retval;
}
export function resizeElementBBox(element: Snap.Element): Snap.BBox {
  // it is an text element
  //	let nameAttribute = getElementNameAndAttributes(element);
  //	console.debug(`resizing ${nameAttribute.name}`);
  const bbox = getActualBBox(element);
  const adjustment = getElementAdjustment(element);
  return applyAdjustment(bbox, adjustment);
}
const elementsBBox = new Map();

export function getActualBBox(element: Snap.Element): Snap.BBox {
  let bbox;
  //	let attributeName = getElementNameAndAttributes(element);
  if (elementsBBox.has(element.attr("id"))) {
    bbox = Object.assign({}, elementsBBox.get(element.attr("id")));
    // console.debug(`get ${attributeName.name} id ${element.id} ${bbox.x} ${bbox.y} ${bbox.x2} ${bbox.y2}`);
  } else {
    bbox = Object.assign({}, element.getBBox());
  }
  return bbox;
}

export function getTextDistance(textElement: Snap.Element): Adjustment {
  // const fonts = SAFonts.getInstance();
  const cssId = elementCssName(textElement);
  const fontInfo = new SAFontInfo(cssId);
  const fontMetric = fontInfo.fontMetric();

  const distFromBottom = fontMetric.baseline - fontMetric.bottom;
  const distFromTop = fontMetric.top - fontMetric.capXHeight;
  return {
    height: fontMetric.top,
    fontMetrics: fontMetric,
    fromTop: distFromTop,
    fromBottom: distFromBottom,
  };
}

export function findChildrenOfType(
  element: Snap.Element,
  type: string,
  descend = false
): Snap.Element[] {
  descend = type === "g" ? false : descend;

  const children = element.children();
  let levelElements: Snap.Element[] = [];
  for (const child of children) {
    if (child.type === type) {
      levelElements.push(child);
    }
    if (descend && child.type === "g") {
      const elements = findChildrenOfType(child, type, descend);
      levelElements = levelElements.concat(elements);
    }
  }
  return levelElements;
}

const rectsShown: Snap.Element[] = [];

export function saveRect(rect: Snap.Element): void {
  rectsShown.push(rect);
}

export function clearRects(): void {
  for (let i = 0; i < rectsShown.length; i++) {
    rectsShown[i].remove();
  }
}

export function recalcBBox(bbox: Snap.BBox): Snap.BBox {
  bbox.width = bbox.w = Math.abs(bbox.x2 - bbox.x);
  bbox.height = bbox.h = Math.abs(bbox.y2 - bbox.y);
  bbox.cx = bbox.x + bbox.width / 2;
  bbox.cy = bbox.y + bbox.height / 2;

  return bbox;
}

export function recalcBBoxX2(bbox: Snap.BBox): Snap.BBox {
  bbox.x2 = bbox.x + bbox.width;
  bbox.y2 = bbox.y + bbox.height;
  bbox.cx = bbox.x + bbox.width / 2;
  bbox.cy = bbox.y + bbox.height / 2;
  return bbox;
}
export function combineBBox(
  bbox1: Snap.BBox | null,
  bbox2: Snap.BBox | null
): Snap.BBox | null {
  let bbox = bbox1
    ? Object.assign({}, bbox1)
    : bbox2
    ? Object.assign({}, bbox2)
    : null;
  if (bbox !== null && bbox1 !== null && bbox2 !== null) {
    bbox.x = Math.min(bbox1.x, bbox2.x);
    bbox.y = Math.min(bbox1.y, bbox2.y);
    bbox.x2 = Math.max(bbox1.x2, bbox2.x2);
    bbox.y2 = Math.max(bbox1.y2, bbox2.y2);
    bbox = recalcBBox(bbox);
  }
  return bbox;
}
