export const STATE_TYPE = {
  DESIGN: "0",
  ORDER: "1",
  REVIEW: "2",
  MANAGE_DEFAULT: "3",
  ARCHIVE: "4",
};

export const SMART_STATE_STATUS = {
  RECEIVED: "0",
  IN_PROGRESS: "1",
  COMPLETE: "2",
};

/**
 * returns a random number between two numbers
 * @param {number} min - the low end of the range
 * @param {number} max - the high end of the range
 * @returns {number} a random number between min and max
 */
export function randomInterval(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * generates a random RGB string for a random value
 */
export function randomRGB(): string {
  //generate RGB colors that are not too dark or too light
  const randomRed = randomInterval(50, 200);
  const randomGreen = randomInterval(50, 200);
  const randomBlue = randomInterval(50, 200);
  // create an RGB string from the colors above. The 16 on the toString will convert to hex
  const randomRGB =
    randomRed.toString(16) + randomGreen.toString(16) + randomBlue.toString(16);

  return randomRGB;
}
