

















/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Vue } from "vue-property-decorator";
import $ from "jquery";

import {
  SignTreeData,
  iSignData,
  iSignSide,
  iStyle,
  iSignChild,
  iMessageField,
  iDetailField,
  iColor,
  iHexCode,
  IField,
  IRepeat,
  IAlignment,
  IPart,
  IProcess,
  IMaterial,
} from "../types";
import {
  SignTypeQL,
  ISignCountEdge,
} from "@/features/SignDesigner/GraphqlTypes";
import Snap from "snapsvg-cjs-ts";
//import opentype from "opentype.js";
import hexcodes from "@/features/SignDesigner/assets/hexcodes.json";
import { SvgCss } from "@/features/SignDesigner/include/SvgCss";
import { SAFonts } from "@/features/SignDesigner/include/SAFonts";
import { SAFontInfo } from "@/features/SignDesigner/include/SAFontInfo";
import { Units } from "@/features/SignDesigner/include/Units";
import { reverseTransformString, sizeToChildBBox } from "../include/Utils";
import {
  getAncestorById,
  getNoFillNoStrokeByElementId,
  featureAvailable,
  clearSignTemplate,
  isEmpty,
  findRepeatByElementIds,
} from "../utilities";

import {
  underscoreToNormalCase,
  getElementName,
  getSides,
  makeFloat,
} from "@/features/SignDesigner/include/Utils";
import { SignChildFactory } from "@/features/SignDesigner/include/SignChildFactory";
import { TreeDataFactory } from "@/features/SignDesigner/include/TreeDataFactory";
import {
  uuidv4,
  isNumeric,
  convertUnitToPoints,
  convertPointsToUnit,
  removeSelectionHandles,
  calculateRepeatSize,
} from "@/features/SignDesigner/utilities";
import {
  performQuery,
  SignTypeQuery,
  SignCountQuery,
} from "../include/DBSupport";

const minZoomPercentage = 5; //the smallest zoom percentage
// let sequenceNum = 1;
export default Vue.extend({
  name: "App",
  props: {
    canvasWidth: { type: Number },
    canvasHeight: { type: Number },
    treeData: { type: Object },
    scrollTop: { type: Number },
    scrollLeft: { type: Number },
    signTypeId: { type: String },
  },
  data: function () {
    return {
      snap: null as Snap.Paper | null,
      rootSVG: null as Snap.Element | null,
      svgCss: null as SvgCss | null,
      units: null as Units | null,
      zoomFactor: 0.5, //how much we zoom in on each click/wheel
      width: 0,
      height: 0,
      offsetX: 0,
      offsetY: 0,
      hexcodes: hexcodes as iHexCode[],
      signType: {} as SignTypeQL,
      componentJson: null as JSON | null,
      signData: {} as iSignData,
      styles: [] as Array<iStyle>,
    };
  },
  mounted: async function () {
    // console.debug(`mounted ${sequenceNum++}`);
    this.$root.$refs.SVGRenderer = this;
  },
  methods: {
    /**
     * handle the delete key pressed event
     */
    onDeletePressed: function () {
      // console.debug(`onDeletePressed ${sequenceNum++}`);

      this.$emit("deleteKeyPressed");
    },
    /**
     * called when the user clicks anywhere on the svg wrapper element
     * will deselect all elements if the user has clicked anywhere off the sign
     *
     * @param {PointerEvent} event - the event that invoked this
     */
    canvasClicked: function (event: PointerEvent) {
      let svg = this.$store.getters.canvas.select("svg");
      const width = svg.attr("width");
      const height = svg.attr("height");
      let bbox = svg.getBBox();
      const scaledX = event.offsetX;
      const scaledY = event.offsetY;
      // (this.$root.$refs.CodeEditor as any).applyChanges();
      if (
        scaledX < bbox.x ||
        scaledX > width + bbox.x ||
        scaledY < bbox.y ||
        scaledY > height
      ) {
        this.$store.commit("clearSelectedElementIds");
        this.$store.commit("deselectAllFields");
        this.drawSelectionHandlesFromIds();
      }
    },

    /**
     * load the sign data
     */
    loadSignData: function () {
      // console.debug(`loadSignData ${sequenceNum++}`);
      //let s = this.$refs.workspace;

      let svgData = "";

      this.$store.commit("setShowLoadingSpinner", true);
      clearSignTemplate(this);

      performQuery(SignTypeQuery(this.$store.getters.currentSignTypeUUID)).then(
        (data) => {
          if (data.data.signType) {
            let revisionData = {} as any;
            if (
              !data.data.signType.revisionJson ||
              data.data.signType.revisionJson === ""
            ) {
              //there is no revision data so use the sign type data
              revisionData = data.data;
              this.$store.commit("setIsDraft", false);
            } else {
              // use the revision data
              revisionData = JSON.parse(data.data.signType.revisionJson);
              this.$store.commit("setIsDraft", true);
            }
            if (revisionData.signType) {
              if (revisionData.signType.messageFields === "") {
                this.$store.commit("setMessages", []);
              } else {
                let messages = [] as Array<iMessageField>;
                revisionData.signType.messageFields?.edges.forEach(function (
                  edge: any
                ) {
                  messages.push(edge.node);
                });
                this.$store.commit("setMessages", messages);
              }

              if (revisionData.signType.repeatingMessageFields === "") {
                this.$store.commit("setRepeatingMessages", []);
              } else {
                let messages = [] as Array<iMessageField>;
                revisionData.signType.repeatingMessageFields?.edges.forEach(
                  (edge: any) => {
                    messages.push(edge.node);
                  }
                );
                this.$store.commit("setRepeatingMessages", messages);
                // this.$store.commit(
                //   "setRepeatingMessages",
                //   data.data.signType.repeatingMessageFields.edges.node
                // );
              }

              if (revisionData.signType.detailFields === "") {
                this.$store.commit("setDetailFields", []);
              } else {
                let details = [] as Array<iDetailField>;
                revisionData.signType.detailFields?.edges.forEach(
                  (edge: any) => {
                    details.push(edge.node);
                  }
                );
                this.$store.commit("setDetailFields", details);
              }

              let parts = [] as Array<IPart>;
              revisionData.signType.parts?.edges.forEach(function (
                partField: any
              ) {
                parts.push(partField);
              });
              this.$store.commit("setParts", parts);

              let materials = [] as Array<IMaterial>;
              revisionData.signType.materials?.edges.forEach(function (
                materialField: any
              ) {
                materials.push(materialField);
              });
              //remove this for the modulex demo
              // this.$store.commit("setMaterials", materials);

              // let signMaterials = [] as Array<ISignMaterial>;
              // revisionData.signType.signMaterials?.forEach(function (
              //   materialField: ISignMaterial
              // ) {
              //   materialField.isSelected = false;
              //   signMaterials.push(materialField);
              // });
              // this.$store.commit("setSignMaterials", signMaterials);

              let processes = [] as Array<IProcess>;
              revisionData.signType.processes?.edges.forEach(function (
                process: any
              ) {
                processes.push(process);
              });
              //remove this for the modulex demo
              //this.$store.commit("setProcesses", processes);

              // let signProcesses = [] as Array<ISignProcess>;
              // revisionData.signType.signProcesses?.edges.forEach(function (
              //   processField: ISignProcess
              // ) {
              //   processField.isSelected = false;
              //   signProcesses.push(processField);
              // });
              // this.$store.commit("setSignProcesses", signProcesses);

              this.signType = revisionData.signType;

              if (revisionData.signType.svgFile) {
                const fileArr = [];
                fileArr.push({
                  id: uuidv4(),
                  isSelected: true,
                  //disable the next line eslinting because it was throwing a no-useless-escape
                  //error on the replace string, but I need all the escape characters
                  // eslint-disable-next-line
                  name: revisionData.signType.svgFile.replace(/^.*[\\\/]/, ""),
                  path: revisionData.signType.svgFile,
                  blob: null,
                });
                this.$store.commit("setFiles", fileArr);
              }
              if (revisionData.signType.folder) {
                this.signType.folderId = revisionData.signType.folder.id;
              } else {
                this.signType.folderId = "";
              }
              if (revisionData.signType.library) {
                this.signType.libraryId = revisionData.signType.library.id;
              } else {
                this.signType.libraryId = "";
              }
              if (revisionData.signType.marker) {
                this.signType.markerId = revisionData.signType.marker.id;
              } else {
                this.signType.markerId = "";
              }
              if (revisionData.signType.markerScale) {
                this.signType.markerScaleId =
                  revisionData.signType.markerScale.id;
              } else {
                this.signType.markerScaleId = "";
              }
              if (revisionData.signType.details) {
                this.signType.details = revisionData.signType.details;
              } else {
                this.signType.details = "";
              }
            }

            if (revisionData.signType.svgData) {
              svgData = decodeURIComponent(atob(revisionData.signType.svgData));
            }
          }

          // read the sign counts for this sign type
          const query3 = SignCountQuery(
            this.$store.getters.currentSignTypeUUID
          );
          performQuery(query3).then((data) => {
            let signCount = 0;
            data.data.signType.signs?.edges.forEach((edge: ISignCountEdge) => {
              signCount += edge.node.quantity;
            });
            this.$store.commit("setSignCount", signCount);
          });

          if (!svgData) {
            if (this.signType.svgUrl) {
              this.loadSVG(this.signType.svgUrl);
            } else {
              // the sign type has no template associated so just build the signData
              let el: Snap.Element | undefined = this.snap?.select("*");
              if (typeof el === "undefined") {
                el = this.snap as Snap.Element;
              }
              this.buildSignData(el);
            }
          } else {
            const signDesignerId = "#signdesigner_snap";
            this.snap = Snap(signDesignerId);
            const frag: Snap.Fragment = Snap.parse(svgData);
            const el = frag.select("svg");
            // if (this.snap) {
            this.snap.append(el);
            this.processSVG();
            // }
          }
          //if we have a sign type with an svgurl then load the svg otherwise just load the organization data
          this.$store.commit("setShowLoadingSpinner", false);
        }
      );
    },
    /**
     * load the SVG
     * @param {string} url - the url of the SVG file
     */
    loadSVG: function (url: string) {
      // console.debug(`loadSVG ${sequenceNum++}`);
      const signDesignerId = "#signdesigner_snap";
      this.snap = Snap(signDesignerId);

      this.$store.commit("setShowLoadingSpinner", true);

      if (this.snap) {
        //Snap.load("./A2-Room ID-2.svg", function(data) {
        Snap.load(url, this.onSVGLoaded);
      } else {
        console.error(
          `can't find the HTML element id ${signDesignerId} in the HTML container`
        );
      }
    },
    /**
     * the Snap SVG load callback
     * @param {Snap.Element} fragment - the SVG element to load
     */
    onSVGLoaded: function (fragment: Snap.Element) {
      // console.debug(`onSVGloaded ${sequenceNum++}`);

      if (this.snap) {
        if (this.snap.select("svg")) {
          this.snap.select("svg").append(fragment);
        } else {
          this.snap.append(fragment);
        }
        this.processSVG();
      }
    },
    processSVG: function (zoomToFit = true, showMissingFonts = true) {
      if (!isEmpty(this.$store.getters.canvas)) {
        removeSelectionHandles(this.$store.getters.canvas);
      }
      // reset the next repeat number
      this.$store.commit("setNextRepeatNumber", 1);

      // clear the color array
      this.$store.commit("setColors", []);

      if (this.snap) {
        this.rootSVG = $.extend(true, {}, this.snap);
        let defs = this.snap.select("defs");
        let styleElement = defs?.select("style");
        try {
          this.units = Units.removeInstance();
          this.units = Units.getInstance(this.rootSVG);
        } catch (e) {
          console.log("failed to parse Units " + e);
        }
        //this.units = Units.getInstance(this.rootSVG);
        // ensure that the svgCSS gets defined from the style
        try {
          this.svgCss = SvgCss.removeInstance();
          this.svgCss = SvgCss.getInstance(styleElement);
        } catch (e) {
          console.error("failed to parse svgCSS " + e);
        }

        const orgFonts = this.$store.getters.fonts;
        const signFonts = SAFonts.getInstance();

        signFonts
          .loadFonts(orgFonts)
          .then(() => {
            const signFonts = SAFonts.getInstance();
            const styleText: string = signFonts.styleElementFontList();

            // prepend the list of fonts just before the svg
            let defs = this.snap?.select("defs");
            let style = defs?.select("style");
            if (style?.node?.innerHTML) {
              style.node.innerHTML = styleText + style.node.innerHTML;
            }
            if (this.snap && this.rootSVG) {
              this.snap.selectAll("svg").forEach((rootSVG: Snap.Element) => {
                // need to use the correct csssvg for the svg and the right Units for the SVG.
                this.expandSVG(rootSVG);
                this.buildSignData(rootSVG);
              });

              // do the vue related processing
              // let initialZoom = this.$store.getters.zoomPercentage;

              // if (this.$store.getters.zoomPercentage === 0) {
              //   let bb = this.rootSVG.select("svg").getBBox();
              //   this.width = bb.width;
              //   this.height = bb.height;
              //   //rootSVG.drag(this.dragMove, this.dragStart, this.dragStop);

              //   if (this && this.$refs && this.$refs.workspace) {
              //     this.offsetX = (
              //       this.$refs.workspace as HTMLElement
              //     ).offsetLeft;
              //     this.offsetY = (
              //       this.$refs.workspace as HTMLElement
              //     ).offsetTop;
              //   }
              //   initialZoom = 100;

              //   let panel_ratio = this.canvasWidth / this.canvasHeight;
              //   let image_ratio = this.width / this.height;

              //   if (panel_ratio > image_ratio) {
              //     initialZoom =
              //       (this.canvasHeight / (this.height + this.offsetY + 40)) *
              //       100;
              //   } else {
              //     initialZoom =
              //       (this.canvasWidth / (this.width + this.offsetX + 40)) * 100;
              //   }

              //   if (initialZoom < minZoomPercentage) {
              //     initialZoom = minZoomPercentage;
              //   }
              // } else {
              //   //we already have a sign loaded so this sign is being added
              //   //we need to set the width and height of the new sign to the same
              //   //as the already loaded sign so that scaling/zooming works
              //   let vb = this.rootSVG.select("svg")?.getBBox(); // this.rootSVG.attr("viewBox");

              //   this.snap.selectAll("svg").forEach((svg: Snap.Element) => {
              //     svg.attr({
              //       width: vb.width,
              //       height: vb.height,
              //       preserveAspectRatio: "xMinYMin",
              //     });
              //   });
              // }

              // this.$store.commit("setZoomPercentage", initialZoom);

              this.$store.commit("setCanvas", this.snap);

              //trigger a Ctrl+1 keyboard event which will zoom the sign to fit the canvas
              if (zoomToFit) {
                window.dispatchEvent(
                  new KeyboardEvent("keydown", {
                    key: "1",
                    ctrlKey: true,
                  })
                );
              }

              // check if there are missing fonts
              if (showMissingFonts) {
                if (signFonts.fontsNotLoaded.length > 0) {
                  // if there are then display a warning dialog to the user
                  this.$store.commit("setShowMissingFontsModal", true);
                }
              }

              this.drawSelectionHandlesFromIds();
            }
          })
          .catch((error) => {
            console.log("error loading fonts: ");
            console.error(error);
          });
      }
    },
    /**
     * handle the mouse wheel scroll event
     */
    handleScroll: function (e: WheelEvent) {
      this.$emit("scroll", e);
    },
    /**
     * zoom in
     */
    zoomIn: function () {
      // console.debug(`zoomIn ${sequenceNum++}`);
      this.$store.commit("setZoomIndex", this.$store.getters.zoomIndex - 1);
      this.$store.commit(
        "setZoomPercentage",
        this.$store.getters.zoomFactors[this.$store.getters.zoomIndex]
      );
    },
    /**
     * zoom out
     */
    zoomOut: function () {
      // console.debug(`zoomOut ${sequenceNum++}`);
      this.$store.commit("setZoomIndex", this.$store.getters.zoomIndex + 1);
      this.$store.commit(
        "setZoomPercentage",
        this.$store.getters.zoomFactors[this.$store.getters.zoomIndex]
      );
    },
    /**
     * handle the shift+wheel scroll event
     * @param {WheelEvent} e - The event that triggered this call
     */
    wheelZoom: function (e: WheelEvent) {
      // console.debug(`wheelZoom ${sequenceNum++}`);
      const delta = Math.sign(e.deltaX);
      if (delta > 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    /**
     * get the bounding box of a field
     * @param {string} elementId - the element Id we are looking for
     * @returns {Snap.BBox} - the bounding box for the found element
     */
    getFieldBBox: function (elementId: string): Snap.BBox {
      // console.debug(`getFieldBBox ${sequenceNum++}`);
      let bb: Snap.BBox = {
        x: NaN,
        y: NaN,
        cx: NaN,
        cy: NaN,
        x2: NaN,
        y2: NaN,
        w: NaN,
        width: NaN,
        h: NaN,
        height: NaN,
        r0: NaN,
        r1: NaN,
        r2: NaN,
        path: NaN,
        vb: "",
      };

      const el: Snap.Element = this.$store.getters.canvas.select(
        "[sa-data-id='" + elementId + "']"
      );

      let signChild = this.$store.getters.fieldByElementId(elementId);
      //if signChild height has a value then we found a field
      if (!isNaN(signChild.height)) {
        // use the fields definition for the coordinates
        // if (signChild.isSelected) {
        bb.x = signChild.x;
        bb.y = signChild.y;
        bb.x2 = signChild.x + signChild.width;
        bb.y2 = signChild.y + signChild.height;
        bb.width = signChild.width;
        bb.height = signChild.height;
        // } else {
        // console.log("not selected");
        // }
      } else {
        if (el) {
          let tmp: Snap.BBox | null = sizeToChildBBox(el);
          if (tmp) {
            bb = tmp;
          } else {
            const e1 = el.clone();
            bb = e1.getBBox();
            e1.remove();
          }

          // if (el.type === "text") {
          //   //set height to capHeight if this is a text element
          //   const fontInfo = new SAFontInfo(el.attr("class"));

          //   if (fontInfo.fontMetric().capHeight !== 0) {
          //     bb.height = fontInfo.fontMetric().capHeight;
          //   }
          //   bb.y2 = bb.y + bb.height;
          // }
        }
      }
      // this.$store.getters.fields.forEach((field:IField) => {
      //   if (field.elementIds[0]!== elementId) {
      //     const el: Snap.Element = this.$store.getters.canvas.select(
      //       "[sa-data-id='" + elementId + "']"
      //     );
      //     let rect = getChildElementOfType(el.parent(), "rect");
      //     if (rect){
      //       rect.attr({height:20});
      //     }

      //   }
      // });
      return bb;
    },
    /**
     * draw the selection handles and outlines around a multi-line text field
     */
    drawMultilineSelectionHandles(elementId: string) {
      let snap: Snap.Element = this.$store.getters.canvas.select("*");
      const field = this.$store.getters.fieldByElementId(elementId);
      let rectX = 0;
      let rectY = 0;
      let rectWidth = 0;
      let rectHeight = 0;
      let currentY = 0;
      let totalHeight = 0;
      let startY = 0;

      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + elementId + "']"
      );

      if (field.verticalAlignment === "top") {
        //vertical align === top
        // draw the multiline box from the field down the page
        if (el) {
          let bb = this.getFieldBBox(elementId);
          // let tmpBBox = sizeToChildBBox(el);
          // bb = tmpBBox ? tmpBBox : bb;
          startY = bb.y;
          currentY = startY;
          for (let i = 0; i < field.lines; i++) {
            if (!isNaN(currentY)) {
              let selectBox = snap.paper?.rect(
                bb.x,
                currentY,
                bb.width,
                bb.height
              );
              selectBox?.attr({
                fill: "none",
                stroke: "#ff0000",
                strokeWidth: 1,
                class: "selection-handles",
              });

              currentY += field.leading;
            }
          }

          rectX = bb.x;
          rectY = startY;
          rectWidth = bb.width;
          rectHeight = currentY - startY - (field.leading - field.capHeight);
        }
      } else if (field.verticalAlignment === "bottom") {
        // vertical alignment === bottom
        // draw the multiline box from the field up the page
        if (el) {
          let bb = this.getFieldBBox(elementId);
          let tmpBBox = sizeToChildBBox(el);
          bb = tmpBBox ? tmpBBox : bb;
          startY = bb.y;
          currentY = startY;
          for (let i = 0; i < field.lines; i++) {
            if (!isNaN(currentY)) {
              let selectBox = snap.paper?.rect(
                bb.x,
                currentY,
                bb.width,
                bb.height
              );
              selectBox?.attr({
                fill: "none",
                stroke: "#ff0000",
                strokeWidth: 1,
                class: "selection-handles",
              });

              currentY -= field.leading;
            }
          }
          rectX = bb.x;
          rectY = currentY + field.leading;
          rectWidth = bb.width;
          rectHeight = startY - currentY - (field.leading - field.capHeight);

          // let selectBox = snap.paper?.rect(
          //   bb.x,
          //   currentY + field.leading,
          //   bb.width,
          //   startY - currentY - (field.leading - field.capHeight)
          // );
          // selectBox?.attr({
          //   fill: "none",
          //   stroke: "#ff0000",
          //   strokeWidth: 2,
          //   class: "selection-handles",
          // });
        }
      } else if (field.verticalAlignment === "middle") {
        //vertical alignment === middle.
        //TODO: how do we draw a multiline select if it is middle aligned
        if (el) {
          let bb = this.getFieldBBox(elementId);
          // let tmpBBox = sizeToChildBBox(el);
          // bb = tmpBBox ? tmpBBox : bb;
          startY = bb.y + bb.height / 2;
          totalHeight =
            field.lines * field.leading - (field.leading - field.capHeight);
          currentY = startY - totalHeight / 2;

          for (let i = 0; i < field.lines; i++) {
            if (!isNaN(currentY)) {
              let selectBox = snap.paper?.rect(
                bb.x,
                currentY,
                bb.width,
                bb.height
              );
              selectBox?.attr({
                fill: "none",
                stroke: "#ff0000",
                strokeWidth: 1,
                class: "selection-handles",
              });

              currentY += field.leading;
            }
          }

          rectX = bb.x;
          rectY = startY - totalHeight / 2;
          rectWidth = bb.width;
          rectHeight = totalHeight;

          // let selectBox = snap.paper?.rect(
          //   bb.x,
          //   startY - totalHeight / 2,
          //   bb.width,
          //   totalHeight
          // );
          // selectBox?.attr({
          //   fill: "none",
          //   stroke: "#ff0000",
          //   strokeWidth: 2,
          //   class: "selection-handles",
          // });
        }
      }

      let selectBox = snap.paper?.rect(rectX, rectY, rectWidth, rectHeight);
      selectBox?.attr({
        fill: "none",
        stroke: "#ff0000",
        strokeWidth: 2,
        class: "selection-handles",
      });

      // update the no-fill, no-stroke rectangle with new y position and height
      rectY = rectY - (field.leading - field.capHeight) / 2;
      rectHeight = rectHeight + (field.leading - field.capHeight);

      const rect = getNoFillNoStrokeByElementId(
        elementId,
        this.$store.getters.canvas
      );
      rect.attr({ y: rectY, height: rectHeight });
    },
    /**
     * draw the selection box around an element
     */
    drawSelectionHandles(elementId: string, drawHandles: boolean) {
      let snap: Snap.Element = this.$store.getters.canvas.select("*");

      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + elementId + "']"
      );
      if (el) {
        let bb = this.getFieldBBox(elementId);
        let tmpBBox = sizeToChildBBox(el);
        bb = tmpBBox ? tmpBBox : bb;

        if (!isNaN(bb.x)) {
          let selectBox = snap.paper?.rect(bb.x, bb.y, bb.width, bb.height);
          selectBox?.attr({
            fill: "none",
            stroke: "#00ff00",
            strokeWidth: 2,
            class: "selection-handles",
          });

          if (drawHandles) {
            // set handlesize so that it stays the same no matter what our zoom level is
            let handleSize = Math.abs(
              4 * (1 / (this.$store.getters.zoomPercentage / 100))
            );

            let fillColor = "#cccccc";
            let strokeColor = "#2c70ff";

            selectBox = snap.paper?.rect(
              bb.x - handleSize,
              bb.y - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "nw-resize",
              class: "selection-handles",
            });
            selectBox = snap.paper?.rect(
              bb.x + bb.width / 2 - handleSize,
              bb.y - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ns-resize",
              class: "selection-handles",
            });
            selectBox = snap.paper?.rect(
              bb.x + bb.width - handleSize,
              bb.y - handleSize,
              handleSize * 2,
              handleSize * 2
            );

            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ne-resize",
              class: "selection-handles",
            });

            selectBox = snap.paper?.rect(
              bb.x - handleSize,
              bb.y + bb.height / 2 - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ew-resize",
              class: "selection-handles",
            });
            selectBox = snap.paper?.rect(
              bb.x + bb.width - handleSize,
              bb.y + bb.height / 2 - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ew-resize",
              class: "selection-handles",
            });

            selectBox = snap.paper?.rect(
              bb.x - handleSize,
              bb.y + bb.height - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ne-resize",
              class: "selection-handles",
            });
            selectBox = snap.paper?.rect(
              bb.x + bb.width / 2 - handleSize,
              bb.y + bb.height - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "ns-resize",
              class: "selection-handles",
            });
            selectBox = snap.paper?.rect(
              bb.x + bb.width - handleSize,
              bb.y + bb.height - handleSize,
              handleSize * 2,
              handleSize * 2
            );
            selectBox?.attr({
              fill: fillColor,
              stroke: strokeColor,
              //cursor: "nw-resize",
              class: "selection-handles",
            });
          }
        }
      }
    },
    /**
     * draw the selection boxes around the selected elements
     */
    drawSelectionHandlesFromIds: function () {
      // console.debug(`drawSelectionHandlesFromIds ${sequenceNum++}`);

      if (this.snap) {
        removeSelectionHandles(this.snap);
        // let selection = this.snap.selectAll(".selection-handles");
        // selection.forEach(function (item: any) {
        //   item.remove();
        // });

        // // remove the repeat handles if they are showing
        // if (!this.$store.getters.isRepeatSelected) {
        //   selection = this.snap.selectAll(".repeat-handles");
        //   selection.forEach(function (item: any) {
        //     item.remove();
        //   });
        // } else {
        if (this.$store.getters.isRepeatSelected) {
          // this.$store.commit("clearSelectedElementIds");

          let repeat = this.$store.getters.selectedRepeat;
          let s = this.$store.getters.canvas.select("svg");
          let selectBox = s.paper?.rect(
            repeat.x,
            repeat.y,
            repeat.width,
            repeat.height
          );
          selectBox?.attr({
            fill: "none",
            stroke: "#FFC0CB",
            strokeWidth: 2,
            class: "repeat-handles",
          });

          selectBox = s.paper?.rect(
            repeat.x,
            repeat.y,
            repeat.width,
            repeat.offset
          );
          selectBox?.attr({
            fill: "none",
            stroke: "#FFC0CB",
            strokeWidth: 2,
            class: "repeat-handles",
          });
        } else {
          let snap: Snap.Element = this.$store.getters.canvas.select("*");
          if (snap) {
            // SnapSVG doesn't set the paper if we parse the svg from a string (which we do when loading a revision) so we must set it here
            if (!snap.paper) {
              this.$store.commit("updateCanvasPaper", snap as Snap.Paper);
              this.$nextTick(function () {
                snap = this.$store.getters.canvas.select("*");
              });
              // snap.paper = snap as Snap.Paper;
            }
          }
          // first draw a green box around each selected element
          this.$store.getters.selectedElementIds.forEach(
            (elementId: string) => {
              if (snap) {
                let field = this.$store.getters.fieldByElementId(elementId);
                //if lines is not a number then we have clicked on an element that isn't a field so we just want to draw the selection handles
                if (isNaN(field.lines)) {
                  this.drawSelectionHandles(elementId, false);
                } else {
                  // we have clicked on a field.
                  if (parseInt(field.lines) === 1) {
                    this.drawSelectionHandles(elementId, false);
                  } else {
                    this.drawMultilineSelectionHandles(elementId);
                  }
                }
              }
            }
          );
          // now draw the selection box around all the elements
          let topX = NaN;
          let topY = NaN;
          let bottomX = NaN;
          let bottomY = NaN;

          this.$store.getters.selectedElementIds.forEach(
            (elementId: string) => {
              // let el = this.$store.getters.canvas.select(
              //   "[sa-data-id='" + elementId + "']"
              // );
              let bb = this.getFieldBBox(elementId);
              if (!isNaN(bb.x)) {
                topX = isNaN(topX) ? bb.x : Math.min(topX, bb.x);
                topY = isNaN(topY) ? bb.y : Math.min(topY, bb.y);

                bottomX = isNaN(bottomX) ? bb.x2 : Math.max(bottomX, bb.x2);
                bottomY = isNaN(bottomY) ? bb.y2 : Math.max(bottomY, bb.y2);
              }
            }
          );

          if (!isNaN(topX)) {
            let selectBox = snap.paper?.rect(
              topX,
              topY,
              bottomX - topX,
              bottomY - topY
            );
            selectBox?.attr({
              fill: "none",
              stroke: "#2c70ff",
              strokeWidth: 2,
              class: "selection-handles",
            });
          }
        }
      }
    },
    /**
     * toggle the visibility of a set of elements
     * @param {Array<iSignChild>} items - the items to toggle visibility on
     */
    toggleVisibility: function (items: Array<iSignChild>) {
      // console.debug(`toggleVisibility: ${sequenceNum++}`);

      items.forEach((element: any) => {
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + element.svgId + "']"
        );
        if (el) {
          if (element.isVisible) {
            el.attr("display", "");
          } else {
            el.attr("display", "none");
          }
        }

        if (element.children && element.children.length > 0) {
          this.toggleVisibility(element.children);
        }
      });
    },
    /**
     * handle the element clicked event
     * @param {any} element - the element to select
     * @param {Event} event - the event that triggered this call
     */
    selectElement: function (element: any, event: Event) {
      // console.debug(`selectElement: ${sequenceNum++}`);
      let el = this.getSignChildById(
        this.$store.getters.signData.sides[this.$store.getters.currentSide],
        element.id
      );
      if (isEmpty(el)) {
        const parentEl = element.parent();
        //if the user clicked on a placeholder-image then select the associated no-fill/no-stroke rectangle
        if (element.hasClass("placeholder-image")) {
          const rectEl = parentEl.select("rect");
          el = this.getSignChildById(
            this.$store.getters.signData.sides[this.$store.getters.currentSide],
            rectEl.attr("sa-data-id")
          );
        } else {
          el = this.getSignChildById(
            this.$store.getters.signData.sides[this.$store.getters.currentSide],
            parentEl.attr("id")
          );
        }
      }
      if (el) {
        this.$emit("elementSelected", { element: el, event: event });
      }
      this.drawSelectionHandlesFromIds();
    },
    /**
     * get an iSignChild element for a specific id
     * @param {iSignChild} element - the element to search in
     * @param {string} id - the id we are looking for
     * @returns {iSignChild} - the found element or an empty object if not found
     */
    getSignChildById: function (element: iSignChild, id: string): iSignChild {
      // console.debug(`getSignChildById: ${sequenceNum++}`);
      if (element.id === id) {
        return element;
      } else if (element.children && element.children.length > 0) {
        let i;
        let result = {} as iSignChild;

        for (i = 0; isEmpty(result) && i < element.children.length; i++) {
          result = this.getSignChildById(element.children[i], id);
        }
        return result;
      }
      return {} as iSignChild;
    },
    /**
     * get the sides from the svg
     * @param {Snap.Element} svg - the svg element to search in
     * @returns {Snap.Set} - all the elements that have a id that starts with side_
     */
    getSides: function (svg: Snap.Element): Snap.Set {
      // console.debug(`getSides: ${sequenceNum++}`);
      return svg.selectAll("g[id^='side_']");
    },
    /**
     * is the item a no fill/no stroke element
     * @param {iSignChild} item - the item we are checking
     * @returns {boolean} - true if this item has no fill/no stroke
     */
    isNoFillNoStroke(item: iSignChild): boolean {
      // console.debug(`isNoFillNoStroke: ${sequenceNum++}`);
      const domElement = document.querySelectorAll(
        "[sa-data-id='" + item.id + "']"
      );
      // const domElement = document.getElementById(item.id);
      if (domElement) {
        const computedStyle = window.getComputedStyle(domElement[0]);

        if (
          computedStyle.getPropertyValue("stroke") === "none" &&
          computedStyle.getPropertyValue("fill") === "none"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }

      // console.log("item");
      // console.log(item.id);
      // // console.log(el.stroke);÷
      // console.log(item.fill);
      // if (item.stroke === undefined || item.fill === undefined) {
      //   console.log("print false");
      //   return false;
      // } else {
      //   //  return ((item.stroke === "" || item.stroke === "none") && item.fill === "none");
      //   return true;
      // }
    },
    /**
     * get the field definition by name
     * @param {string} fieldName - the name of the field to search for
     * @returns {IField | null} the found field or null if none found
     */
    getFieldDefinition: function (fieldName: string): IField | null {
      // console.debug(`getFieldDefinition: ${sequenceNum++}`);
      let field = null as IField | null;

      for (let i = 0; i < this.$store.getters.fields.length; i++) {
        if (
          underscoreToNormalCase(
            this.$store.getters.fields[i].name
          ).toLowerCase() === fieldName.toLowerCase()
        ) {
          field = this.$store.getters.fields[i];
        }
      }
      return field;
    },

    /**
     * return the targetfield and offset from a parameter string
     * @param {string} parameters - the parameter string (i.e. "symbol 0.5 in")
     * @returns {object} an object containing the targetField and targetOffset
     */
    processAlignmentParameters(parameters: string) {
      const targetField = parameters.substr(0, parameters.indexOf(" "));
      const targetOffset = parameters.substr(parameters.indexOf(" ") + 1);

      return {
        targetField: targetField,
        targetOffset: targetOffset,
      };
    },

    /**
     * process dynamic alignments
     * @param {string} commandString - an alignment command (i.e. align_left_to_right: symbol 0.5 in)
     * @param {string} sourceField - the svg element id for the source
     * @param {string} elementId - the signChild id of the element
     */
    processDynamicAlignment(
      commandString: string,
      sourceField: string,
      elementId: string
    ) {
      if (commandString.substr(0, 6) !== "align_") {
        console.log("not an alignment command: " + commandString);
        return "";
      }

      let alignString = commandString.substr(6);
      const command = alignString
        .substr(0, alignString.indexOf(":"))
        .toLowerCase();
      let parameters = alignString.substr(alignString.indexOf(":") + 1).trim();

      let sourceAlignment = "";
      let targetAlignment = "";
      let targetField = "";
      let targetOffset = "";

      switch (command) {
        case "left_to_left":
        case "left":
          sourceAlignment = "left";
          targetAlignment = "left";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "left_to_center":
          sourceAlignment = "left";
          targetAlignment = "center";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "left_to_right":
          sourceAlignment = "left";
          targetAlignment = "right";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "center_to_left":
          sourceAlignment = "center";
          targetAlignment = "left";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "center_to_center":
        case "center":
          sourceAlignment = "center";
          targetAlignment = "center";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "center_to_right":
          sourceAlignment = "center";
          targetAlignment = "right";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "right_to_left":
          sourceAlignment = "right";
          targetAlignment = "left";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "right_to_center":
          sourceAlignment = "right";
          targetAlignment = "center";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "right_to_right":
        case "right":
          sourceAlignment = "right";
          targetAlignment = "right";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "top_to_top":
        case "top":
          sourceAlignment = "top";
          targetAlignment = "top";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "top_to_middle":
          sourceAlignment = "top";
          targetAlignment = "middle";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "top_to_bottom":
          sourceAlignment = "top";
          targetAlignment = "bottom";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "middle_to_top":
          sourceAlignment = "middle";
          targetAlignment = "top";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "middle_to_middle":
        case "middle":
          sourceAlignment = "middle";
          targetAlignment = "middle";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "middle_to_bottom":
          sourceAlignment = "middle";
          targetAlignment = "bottom";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "bottom_to_top":
          sourceAlignment = "bottom";
          targetAlignment = "top";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "bottom_to_middle":
          sourceAlignment = "bottom";
          targetAlignment = "middle";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
        case "bottom_to_bottom":
        case "bottom":
          sourceAlignment = "bottom";
          targetAlignment = "bottom";
          targetField = this.processAlignmentParameters(parameters).targetField;
          targetOffset =
            this.processAlignmentParameters(parameters).targetOffset;
          break;
      }

      const alignment = {
        uuid: uuidv4(),
        isSelected: false,
        isInRepeat: true,
        sortIndex: this.$store.getters.alignments.length,
        sourceField: underscoreToNormalCase(sourceField),
        targets: [
          {
            sourceAlignment: sourceAlignment,
            row: "current",
            field: underscoreToNormalCase(targetField),
            targetAlignment: targetAlignment,
            offset: targetOffset,
          },
        ],
        elementIds: [elementId],
      } as IAlignment;
      this.$store.commit("loadAlignment", alignment);
    },

    /**
     * calculate the offset
     * @param {number} beforeOffset - the before offset (i.e., the offset of the left hand side of the alignment string)
     * @param {string} beforeUnit - the unit of measure for the before offset
     * @param {number} afterOffset - the after offset (i.e., the offset of the right hand side of the alignment string)
     * @param {string} afterUnit - the unit of measure for the after offset
     * @returns {string} - the total offset and unit
     */
    calculateOffset: function (
      beforeOffset: number,
      beforeUnit: string,
      afterOffset: number,
      afterUnit: string
    ): string {
      let offset = "";

      if (afterOffset === 0) {
        offset = beforeOffset + " " + beforeUnit;
      } else if (beforeOffset === 0) {
        offset = afterOffset + " " + afterUnit;
      } else {
        // we have both a before and after offset
        //if the units are the same add them
        let total = 0;
        if (beforeUnit === afterUnit) {
          total = beforeOffset + afterOffset;
          offset = total + " " + beforeUnit;
        } else {
          // we have different units so convert the after unit to be the same as the before
          total =
            beforeOffset +
            convertPointsToUnit(
              convertUnitToPoints(afterOffset, afterUnit),
              beforeUnit
            );
        }
      }
      return offset;
    },

    /**
     * parse alignment fields
     * @param {Array<string>} alignArray - an array of alignment parts ([field, offset, offset unit])
     * @returns {Array<object>} an array of {name, offset, unit}
     */
    parseAlignFields: function (alignArray: Array<string>) {
      let commandIndex = 0;
      let fieldName = "";
      let offsetValue = 0;
      let offsetUnit = "";
      let alignFields = [];

      while (commandIndex < alignArray.length) {
        if (this.$store.getters.fieldByName(alignArray[commandIndex])) {
          fieldName = alignArray[commandIndex];
          offsetValue = 0;
          offsetUnit = "pt";
          // increment the command index to get the offset value
          commandIndex++;

          if (isNumeric(alignArray[commandIndex])) {
            offsetValue = parseFloat(alignArray[commandIndex]);
            //increment the command index to get the offset unit
            commandIndex++;
            offsetUnit = alignArray[commandIndex];
            commandIndex++;
          }

          let beforeField = {
            name: fieldName,
            offset: offsetValue,
            unit: offsetUnit,
          };
          alignFields.push(beforeField);
        } else {
          console.log("Invalid repeat align syntax");
          commandIndex = alignArray.length;
        }
      }

      return alignFields;
    },

    /**
     * process repeat alignments
     * @param {string} repeatString - the repeat alignment command
     */
    processRepeatAlignments: function (repeatString: string) {
      const validCommands = [
        "align_top_to_bottom",
        "align_middle_to_bottom",
        "align_bottom_to_bottom",
        "align_top_to_middle",
        "align_middle_to_middle",
        "align_bottom_to_middle",
        "align_top_to_top",
        "align_middle_to_top",
        "align_bottom_to_top",
      ];

      let beforeCommand = [] as Array<string>;
      let afterCommand = [] as Array<string>;
      let command = "";

      // if repeatString is null then there is nothing to do so just return
      if (!repeatString) {
        return;
      }

      //if there are no align commands then just return
      if (repeatString.toLowerCase().trim() === "repeat") {
        return;
      }

      //strip the repeat off
      repeatString = repeatString.substr(repeatString.indexOf(" ")).trim();
      //each repeatstr can contain multiple "commands" seperated by commas
      const commands = repeatString.split(",");
      commands.forEach((commandString: string) => {
        let foundCommand = false;
        const repeatArr = commandString.toLowerCase().split(" ");
        repeatArr.forEach((repeatItem) => {
          if (validCommands.includes(repeatItem)) {
            foundCommand = true;
            command = repeatItem;
          } else {
            if (foundCommand) {
              afterCommand.push(repeatItem);
            } else {
              beforeCommand.push(repeatItem);
            }
          }
        });
      });

      let beforeFields = this.parseAlignFields(beforeCommand);
      let afterFields = this.parseAlignFields(afterCommand);

      //now that we have the fields defined we can create the dynamic alignments
      beforeFields.forEach((beforeField) => {
        let field = this.$store.getters.fieldByName(beforeField.name);
        afterFields.forEach((afterField) => {
          let offset = this.calculateOffset(
            beforeField.offset,
            beforeField.unit,
            afterField.offset,
            afterField.unit
          );
          let alignment = {
            uuid: uuidv4(),
            isSelected: false,
            isInRepeat: true,
            sortIndex: this.$store.getters.alignments.length,
            sourceField: field.name,
            sourceAlignment: command.split("_")[1],
            targetRow: "previous",
            targetField: this.$store.getters.fieldByName(afterField.name).name,
            targetAlignment: command.split("_")[3],
            offset: offset,
            elementIds: field.elementIds,
          };
          this.$store.commit("loadAlignment", alignment);
        });
      });
    },

    /**
     * get the alignment from the child elements
     * @param {Snap.Element} element - the snap svg element to search
     * @param {IField} field - the field we are working with
     * @returns {object} an object that contains {verticalAlignment, horizontalAlignment}
     */
    getAlignmentFromChildren(
      element: Snap.Element,
      field: IField,
      capHeight: number
    ) {
      let retVal = { verticalAlignment: "top", horizontalAlignment: "left" };

      element.children().forEach((child: any) => {
        if (child.type === "rect") {
          if (
            (child.attr("stroke") == null || child.attr("stroke") === "none") &&
            (child.attr("fill") == null || child.attr("fill") === "none")
          ) {
            let dataName = child.attr("data-name");
            if (dataName) {
              dataName = reverseTransformString(dataName);
            } else {
              dataName = reverseTransformString(child.attr("id"));
            }
            if (dataName) {
              let alignArr = dataName.split(",");
              alignArr.forEach((align: string) => {
                const trimmedAndLoweredAlign = align.toLowerCase().trim();
                let commandType = trimmedAndLoweredAlign.substr(
                  0,
                  trimmedAndLoweredAlign.indexOf(":")
                );

                if (commandType.substr(0, 5) === "align") {
                  commandType = "align";
                }

                switch (commandType) {
                  case "":
                    switch (trimmedAndLoweredAlign) {
                      case "left":
                      case "center":
                      case "right":
                        retVal.horizontalAlignment = trimmedAndLoweredAlign;
                        break;
                      case "top":
                      case "middle":
                      case "bottom":
                        retVal.verticalAlignment = trimmedAndLoweredAlign;
                        break;
                    }
                    break;
                  case "leading": {
                    const leading = trimmedAndLoweredAlign.split(":")[1].trim();
                    const leadingUnitValue = Units.parseUnitValue(leading);
                    const leadingValue = leadingUnitValue.value;
                    const leadingUnit = leadingUnitValue.unit;
                    if (leadingUnit.toLowerCase() === "pt") {
                      field.leading = leadingValue;
                    } else {
                      field.leading = convertUnitToPoints(
                        leadingValue,
                        leadingUnit
                      );
                    }
                    //calculate the number of lines
                    const rectSize = child.getBBox();
                    let rectHeight = rectSize.height;
                    let lines = 0;
                    let lineHeight = 0;

                    rectHeight = rectHeight - (field.leading - capHeight) / 2;

                    while (lineHeight < rectHeight) {
                      lines++;
                      lineHeight += field.leading;
                    }
                    field.lines = lines; // Math.ceil(rectHeight / field.leading);
                    break;
                  }
                  case "align":
                    this.processDynamicAlignment(
                      trimmedAndLoweredAlign,
                      element.attr("id"),
                      field.id
                    );
                    break;
                  default:
                    console.log("Unknown command type: " + commandType);
                    break;
                }
              });
            }
          }
        }
      });

      return retVal;
    },
    /**
     * get the field definition from the child elements
     * @param {Snap.Element} element - the element to search
     * @param {IField} signChild - the field we are working with
     * @returns {IField} the field definition
     */
    getFieldDefFromChildren(element: Snap.Element, signChild: IField): IField {
      // console.debug(`getFieldDefFromChildren: ${sequenceNum++}`);
      let id = "";
      let name = "";
      let type = "";
      let width = 0;
      let height = 0;
      let x = 0;
      let y = 0;
      let hasPlaceholderImage = false;
      let placeholderImageUrl = "";
      let elementIds = [] as Array<string>;

      element.children().forEach((child: any) => {
        if (child.type === "rect") {
          if (
            child.attr("data-name") !== "spacer" &&
            child.attr("id") !== "spacer"
          ) {
            width = makeFloat(child.attr("width"));
            height = makeFloat(child.attr("height"));
            x = makeFloat(child.attr("x"));
            y = makeFloat(child.attr("y"));
            if (
              (child.attr("stroke") == null ||
                child.attr("stroke") === "none") &&
              (child.attr("fill") == null || child.attr("fill") === "none")
            ) {
              if (
                signChild.type.toLowerCase() === "icon" ||
                signChild.type.toLowerCase() === "icon_t" ||
                signChild.type.toLowerCase() === "icon_x"
              ) {
                id = child.id;
                if (child.attr("display") !== "none") {
                  elementIds.push(child.id);
                }
              }
            } else {
              id = child.id;
              if (child.attr("display") !== "none") {
                elementIds.push(child.id);
              }
            }
          }
        } else if (child.type != "#text") {
          if (
            signChild.type.toLowerCase() === "icon" ||
            signChild.type.toLowerCase() === "icon_t" ||
            signChild.type.toLowerCase() === "icon_x"
          ) {
            //don't add the placeholder image or hidden elements to the element Ids
            if (child.type !== "image" && child.attr("display") !== "none") {
              //this is a visual field
              id = child.id;
              name = child.name;
              type = child.type;
              //make a clone of the child element so we don't get vuex mutation errors
              const el1 = $.extend(true, {}, child);
              const bb = el1.getBBox();
              width = bb.width;
              height = bb.height;
              x = bb.x;
              y = bb.y;
              elementIds.push(child.id);
            }
            // if the image is a placeholder-image then set the placeholderImageUrl and hasPlaceholderImage fields
            if (child.type === "image" && child.hasClass("placeholder-image")) {
              placeholderImageUrl = child.attr("href");
              hasPlaceholderImage = true;
            }
          } else {
            id = child.id;
            name = child.name;
            type = child.type;
            if (child.attr("display") !== "none") {
              elementIds.push(child.id);
            }
          }
        }
      });
      return {
        id: id,
        isSelected: false,
        isUsed: true,
        name: name,
        type: type,
        x: x,
        y: y,
        width: width,
        height: height,
        capHeight: NaN,
        leading: NaN,
        lines: NaN,
        letterSpacing: "",
        horizontalAlignment: "left",
        verticalAlignment: "top",
        hasPlaceholderImage: hasPlaceholderImage,
        placeholderImageUrl: placeholderImageUrl,
        belongsToRepeatId: null,
        elementIds: elementIds,
      };
    },

    /**
     * get field by name
     * @param {string} name - the name we are looking for
     * @returns {IField} - the field definition
     */
    getFieldByName: function (name: string): IField {
      // console.debug(`getFieldByName: ${sequenceNum++}`);

      let retval: IField = {
        id: "",
        isSelected: false,
        isUsed: false,
        name: "",
        type: "",
        x: NaN,
        y: NaN,
        width: NaN,
        height: NaN,
        capHeight: NaN,
        leading: NaN,
        lines: NaN,
        letterSpacing: "",
        horizontalAlignment: "left",
        verticalAlignment: "top",
        hasPlaceholderImage: false,
        placeholderImageUrl: "",
        belongsToRepeatId: null,
        elementIds: [],
      };
      for (let i = 0; i < this.$store.getters.fields.length; i++) {
        if (this.$store.getters.fields[i].name === name) {
          retval = JSON.parse(JSON.stringify(this.$store.getters.fields[i]));
          return retval;
        }
      }
      return retval;
    },

    /**
     * get child element by id
     * @param {Snap.Element} element - the snap svg element we are searching
     * @param {string} id - the id we are searching for
     * @returns {Snap.Element | null} - the found element or null if none found
     */
    getChildElementById: function (
      element: Snap.Element,
      id: string
    ): Snap.Element | null {
      let children = element.children();
      for (let i = 0; i < children.length; i++) {
        if (children[i].attr("id")) {
          const elementNameArr = children[i].attr("id").split("-");
          const lowercased = elementNameArr.map((id: string) =>
            id.toLowerCase()
          );
          if (lowercased.includes(id.toLowerCase())) {
            return children[i];
          }
        }
      }
      return null;
    },
    /**
     * get child element by data-name
     * @param {Snap.Element} element - the snap svg element we are searching
     * @param {string} id - the id we are searching for
     * @returns {Snap.Element | null} - the found element or null if none found
     */
    getChildElementByDataname: function (
      element: Snap.Element,
      id: string
    ): Snap.Element | null {
      let children = element.children();
      for (let i = 0; i < children.length; i++) {
        if (children[i].attr("data-name")) {
          const lowercased = children[i].attr("data-name").toLowerCase();
          if (lowercased.includes(id.toLowerCase())) {
            return children[i];
          }
        }
      }
      return null;
    },
    /**
     * return the first child element for a given type
     * @param {Snap.Element} element - the snap svg element we are searching
     * @param {string} elementType - the type we are search for
     * @returns {Snap.Element | null} - the found snap element or null if none found
     */
    getChildElementByType: function (
      element: Snap.Element,
      elementType: string
    ): Snap.Element | null {
      let children = element.children();
      for (let i = 0; i < children.length; i++) {
        if (children[i].type.toLowerCase() === elementType.toLowerCase()) {
          return children[i];
        }
      }
      return null;
    },
    /**
     * process child nodes
     * @param {Array<Snap.Element>} elements - the elements we are processing
     * @param {iSignSide} parent - the parent element
     */
    processChildNodes: function (
      elements: Snap.Element[],
      parent: iSignSide
    ): void {
      // console.debug(`processChildNodes: ${sequenceNum++}`);

      // Elements when being being viewed by the debugger is a HTMLCollection rather than a Snap.Element[].
      for (let i = elements.length - 1; i >= 0; i--) {
        if (this.svgCss !== null) {
          let field = SignChildFactory.fromElement(elements[i]);
          if (field) {
            switch (elements[i].type) {
              case "defs":
                //process the defs
                for (
                  let def_index = 0;
                  def_index < elements[i].node.children.length;
                  def_index++
                ) {
                  if (elements[i].node.children[def_index].tagName == "style") {
                    let style = elements[i].node.children[def_index];
                    if (style.textContent) {
                      this.styles = this.parseStyles(style.textContent);
                    }
                  }
                }
                break;

              case "g":
                {
                  let elementName = getElementName(elements[i]);
                  if (field) {
                    if (elementName === "repeat") {
                      parent.children.push(field);
                    } else {
                      if (field) {
                        this.processChildNodes(elements[i].children(), field);
                        parent.children.push(field);
                      }
                    }
                  }
                }
                break;

              case "rect":
                // if (!this.isNoFillNoStroke(field)) {
                parent.children.push(field);
                // }
                break;

              case "text":
              case "polygon":
              case "polyline":
              case "image":
              case "path":
              case "ellipse":
              case "circle":
                parent.children.push(field);
                break;
              default:
                break;
            }
          }
        }
      }
    },
    /**
     * process the child elements
     * @param {Array<Snap.Element>} elements - the elements we are processing
     * @param {any} parent - the parent element
     * @param {number} processingSide - the side number that is being processed
     */
    processChildElements: function (
      elements: Snap.Element[],
      parent: any,
      processingSide: number
    ): void {
      // console.debug(`processChildElements: ${sequenceNum++}`);
      let repeatDataNames = [] as Array<string>;
      let repeatsToCalculate = [] as Array<string>;

      let abortLoop = false;
      for (let i = elements.length - 1; i >= 0 && !abortLoop; i--) {
        if (this.svgCss !== null) {
          let signChild = SignChildFactory.fromElement(elements[i]);
          if (signChild) {
            switch (elements[i].type) {
              case "defs":
                //process the defs
                for (
                  let def_index = 0;
                  def_index < elements[i].node.children.length;
                  def_index++
                ) {
                  if (elements[i].node.children[def_index].tagName == "style") {
                    let style = elements[i].node.children[def_index];
                    if (style.textContent) {
                      this.styles = this.parseStyles(style.textContent);
                    }
                  }
                }
                break;

              case "g":
                {
                  let elementName = getElementName(elements[i]);

                  let heightRect = null;
                  if (signChild) {
                    if (elementName.substring(0, 6) === "height") {
                      //ignore height elements
                    } else if (elementName.substring(0, 4) === "side") {
                      if (elementName.includes("option")) {
                        this.$store.commit(
                          "setUnsupportedFeature",
                          "side options"
                        );
                        this.$store.commit(
                          "setShowUnsupportedFeatureModal",
                          true
                        );
                        abortLoop = true;
                      } else {
                        let sideNumber = parseInt(elementName.substring(5));
                        if (isNaN(sideNumber)) {
                          // if the id of the first side is empty then we haven't added any sides yet
                          // so set the side number to zero
                          if (this.$store.getters.signData.sides[0].id === "") {
                            sideNumber = 0;
                          } else {
                            sideNumber =
                              this.$store.getters.signData.sides.length;
                          }
                        } else {
                          // remove one so we have a zero based index
                          sideNumber--;
                        }
                        // save to store
                        this.$store.commit("setCurrentSide", sideNumber);
                        if (sideNumber === processingSide) {
                          this.processChildElements(
                            elements[i].children(),
                            parent,
                            processingSide
                          );
                        }
                      }
                    } else if (elementName.substr(0, 6) === "repeat") {
                      if (!featureAvailable(this, "repeats")) {
                        this.$store.commit("setUnsupportedFeature", "repeats");
                        this.$store.commit(
                          "setShowUnsupportedFeatureModal",
                          true
                        );
                      }
                      if (elementName.includes("option")) {
                        this.$store.commit(
                          "setUnsupportedFeature",
                          "repeat options"
                        );
                        this.$store.commit(
                          "setShowUnsupportedFeatureModal",
                          true
                        );
                        abortLoop = true;
                      } else {
                        const el = $.extend(true, [], elements[i].children());
                        this.processChildElements(el, parent, processingSide);
                        //store the repeat command so we can later process the repeat alignments
                        repeatDataNames.push(elements[i].attr("data-name"));
                      }
                    } else if (elementName.substring(0, 6) === "column") {
                      // the definition of a column is:
                      // +- g id="column"
                      // +--- g id="repeat"
                      // +------ g id="height"
                      // |          rect no-fill/no-stroke
                      // +------ a g with an id of the field name for each field you want in the repeat
                      if (elementName.includes("option")) {
                        // repeat options aren't supported yet
                        this.$store.commit(
                          "setUnsupportedFeature",
                          "column options"
                        );
                        this.$store.commit(
                          "setShowUnsupportedFeatureModal",
                          true
                        );
                        abortLoop = true;
                      } else {
                        signChild.name =
                          "Repeat_" + this.$store.getters.nextRepeatNumber;
                        signChild.fieldType = "repeat";
                        // find the first rect for the current element
                        // Should this be the no-fill/no-stroke rect or does just being the first rect count
                        let columnRect = this.getChildElementByType(
                          elements[i],
                          "rect"
                        );
                        // if we found a rect element then it has the field's attributes
                        if (columnRect) {
                          // use the attributes from the rect for the repeats definition
                          signChild.x = makeFloat(columnRect.attr("x"));
                          signChild.y = makeFloat(columnRect.attr("y"));
                          signChild.width = makeFloat(columnRect.attr("width"));
                          signChild.height = makeFloat(
                            columnRect.attr("height")
                          );
                        }
                        // get the repeat element
                        let repeat = this.getChildElementById(
                          elements[i],
                          "repeat"
                        );

                        // generate a uuid for the repeat
                        const addRepeatId = uuidv4();

                        if (repeat) {
                          // get the height element
                          let height = this.getChildElementById(
                            repeat,
                            "height"
                          );
                          if (height) {
                            heightRect = this.getChildElementByType(
                              height,
                              "rect"
                            );
                            if (!heightRect) {
                              // no height rect so it needs to be calculated.
                              // The repeatsToCalculate array is processed later when we have all the field definitions
                              repeatsToCalculate.push(addRepeatId);
                            }
                          }
                        }
                        this.processChildElements(
                          elements[i].children(),
                          signChild,
                          processingSide
                        );
                        parent.children.push(signChild);
                        let selectedElementIds: string[] = [];
                        signChild.children.forEach((child: any) => {
                          if (child.isVisible) {
                            selectedElementIds.push(child.id);
                          }
                        });
                        let name =
                          "Repeat_" + this.$store.getters.nextRepeatNumber;
                        this.$store.commit("incrementNextRepeatNumber");
                        let rowHeight = heightRect
                          ? makeFloat(heightRect.attr("height"))
                          : 0;
                        const repeatToAdd = {
                          id: addRepeatId,
                          isSelected: false,
                          name: name,
                          steps: Math.floor(signChild.height / rowHeight),
                          offset: rowHeight,
                          x: signChild.x,
                          y: signChild.y,
                          width: signChild.width,
                          height: signChild.height,
                          elementIds: selectedElementIds,
                          clonedElementIds: [],
                        } as IRepeat;
                        // if a repeat already exists with the same element ids then skip
                        // adding the repeat here. This prevents us from creating duplicate repeats
                        if (!findRepeatByElementIds(this, selectedElementIds)) {
                          // this.$store.commit("addRepeat", repeatToAdd);
                        }
                        // add reference to the repeat to all the fields contained in it
                        // loop through the repeats selected element ids
                        repeatToAdd.elementIds.forEach((elementId: string) => {
                          // find the field that corresponds to each element id
                          let repeatField = JSON.parse(
                            JSON.stringify(
                              this.$store.getters.fieldByElementId(elementId)
                            )
                          );
                          // set the belongsToRepeatId field equal to the repeat id
                          if (repeatField) {
                            repeatField.belongsToRepeatId = repeatToAdd.id;
                          }
                          this.$store.commit("updateField", repeatField);
                        });
                      }
                    } else {
                      //if the g element has a no-fill/no-stroke rect then get the field definition
                      if (this.elementHasNoFillNoStrokeRect(elements[i])) {
                        let f = this.getFieldDefinition(
                          underscoreToNormalCase(elementName)
                        );
                        if (f) {
                          let fieldDef = this.getFieldDefFromChildren(
                            elements[i],
                            f
                          );
                          // get the font info
                          let classList = this.snap?.select(
                            "[sa-data-id='" + fieldDef.id + "']"
                          );

                          if (classList) {
                            let cssClass: string = classList.node.classList[0];
                            let svgCss = SvgCss.getInstance();
                            if (svgCss && cssClass) {
                              const fontFamily = svgCss.getClassVariableValue(
                                cssClass,
                                "font-family"
                              );
                              const fontWeight = svgCss.getClassVariableValue(
                                cssClass,
                                "font-weight"
                              );
                              const fontSize = svgCss.getClassVariableValue(
                                cssClass,
                                "font-size"
                              );
                              let fill = svgCss.getClassVariableValue(
                                cssClass,
                                "fill"
                              );
                              signChild.cssClass = cssClass;
                              signChild.fontInfo.fontFamily = fontFamily
                                ? fontFamily
                                : "";
                              signChild.fontInfo.fontWeight = fontWeight
                                ? fontWeight
                                : "";
                              signChild.fontInfo.fontSize = fontSize
                                ? fontSize
                                : "";
                              // check if the child rect has a fill set
                              if (
                                elements[i].select("rect") &&
                                elements[i].select("rect").attr("fill") !==
                                  "none"
                              ) {
                                // if it does then override the fill color with the child rect's fill
                                fill = elements[i].select("rect").attr("fill");
                              }
                              signChild.fill = fill ? fill : "";
                            }
                          }

                          const fontInfo = new SAFontInfo(signChild.cssClass);

                          let alignment = this.getAlignmentFromChildren(
                            elements[i],
                            fieldDef,
                            fontInfo.fontMetric().capHeight
                          );
                          const field = this.getFieldByName(f.name);

                          field.hasPlaceholderImage =
                            fieldDef.hasPlaceholderImage;
                          field.placeholderImageUrl =
                            fieldDef.placeholderImageUrl;
                          field.horizontalAlignment =
                            alignment.horizontalAlignment;
                          field.verticalAlignment = alignment.verticalAlignment;
                          field.isUsed = true;
                          field.x = fieldDef.x;
                          field.y = fieldDef.y;
                          field.width = fieldDef.width;
                          field.height = fieldDef.height;
                          let letterSpacing: string | undefined = "0";
                          classList = this.snap?.select(
                            "[sa-data-id='" + fieldDef.id + "']"
                          );
                          if (classList) {
                            let cssClass: string = classList.node.classList[0];
                            let svgCss = SvgCss.getInstance();
                            if (svgCss && cssClass) {
                              letterSpacing = svgCss.getClassVariableValue(
                                cssClass,
                                "letter-spacing"
                              );
                            }
                          }
                          field.letterSpacing = letterSpacing
                            ? letterSpacing
                            : "0";
                          if (isNaN(fieldDef.leading)) {
                            const fontInfo = new SAFontInfo(signChild.cssClass);

                            const aboveBaseline =
                              (fontInfo.fontMetric().ascent /
                                fontInfo.fontMetric().unitsPerEm) *
                              fontInfo.fontMetric().fontSize;
                            // const belowBaseline =
                            //   (fontInfo.fontMetric().descent /
                            //     fontInfo.fontMetric().unitsPerEm) *
                            //   fontInfo.fontMetric().fontSize;
                            field.leading =
                              fontInfo.fontMetric().fontSize + aboveBaseline; // + belowBaseline;
                          } else {
                            field.leading = fieldDef.leading;
                          }
                          if (isNaN(fieldDef.lines)) {
                            //calculate number of lines
                            let rectSize = { height: NaN };
                            if (elements[i].select("rect")) {
                              const el = $.extend(
                                true,
                                {},
                                elements[i].select("rect")
                              );
                              rectSize = el.getBBox();
                            }
                            let rectHeight = rectSize.height;
                            rectHeight =
                              rectHeight -
                              (field.leading - field.capHeight) / 2;
                            let lineHeight = 0;
                            let lines = 0;
                            if (isNaN(rectHeight)) {
                              // we can't calculate the rectHeight for some reason (i.e. there is no leading value, etc.)
                              // so set the lines to 1
                              lines = 1;
                            } else {
                              // calculate the number of lines
                              while (lineHeight < rectHeight) {
                                lines++;
                                lineHeight += field.leading;
                              }
                            }
                            field.lines = lines; // Math.ceil(rectHeight / field.leading);
                          } else {
                            field.lines = fieldDef.lines;
                          }
                          field.elementIds = fieldDef.elementIds;

                          let fieldType = "text";
                          switch (field.type.toLowerCase()) {
                            case "icon_t":
                            case "icon_x":
                            case "icon":
                              fieldType = "visual";
                              break;
                            case "color":
                            case "color_t":
                            case "color_x":
                              fieldType = "color";
                              break;
                            default:
                              fieldType = "text";
                          }

                          if (fieldDef.id !== "") {
                            signChild.id = fieldDef.id;
                          } else {
                            signChild.id = field.id;
                          }
                          signChild.elementType = field.type;
                          signChild.fieldType = fieldType;
                          signChild.x = field.x;
                          signChild.y = field.y;
                          signChild.width = field.width;
                          signChild.height = field.height;

                          // set the field height equal to cap height if this is a text field
                          if (fieldType === "text") {
                            // getActualBBox
                            let el = this.snap?.select(
                              "[sa-data-id='" + field.elementIds[0] + "']"
                            );
                            // clone the element so we don't get mutation errors
                            const el1 = $.extend(true, {}, el);
                            let bbox = el1.getBBox();

                            if (bbox) {
                              // getElementAdjustment
                              // const fromTop =
                              //   fontInfo.fontMetric().top -
                              //   fontInfo.fontMetric().capXHeight;
                              const fromBottom =
                                fontInfo.fontMetric().baseline -
                                fontInfo.fontMetric().bottom;

                              // applyAdjustment
                              // bbox.y += fromTop + fontInfo.fontMetric().descent;
                              bbox.y2 -= fromBottom;
                              bbox.y =
                                bbox.y2 - fontInfo.fontMetric().capXHeight;
                              bbox.y2 = bbox.y2 < bbox.y ? bbox.y : bbox.y2;
                              bbox.width = bbox.w = Math.abs(bbox.x2 - bbox.x);
                              bbox.height = bbox.h = Math.abs(bbox.y2 - bbox.y);
                              bbox.cx = bbox.x + bbox.width / 2;
                              bbox.cy = bbox.y + bbox.height / 2;

                              if (fontInfo.fontMetric().fontFamily !== "") {
                                field.y = bbox.y;
                                field.height = bbox.height; //fontInfo.fontMetric().capHeight;
                                field.capHeight =
                                  fontInfo.fontMetric().capHeight;
                              }
                            }
                          }

                          this.$store.commit("updateField", field);
                          parent.children.push(signChild);
                        } else {
                          if (signChild) {
                            this.processChildElements(
                              elements[i].children(),
                              signChild,
                              processingSide
                            );
                            if (parent) {
                              parent.children.push(signChild);
                            }
                          }
                        }
                      } else {
                        if (signChild) {
                          this.processChildElements(
                            elements[i].children(),
                            signChild,
                            processingSide
                          );
                          if (parent) {
                            parent.children.push(signChild);
                          }
                        }
                      }
                    }
                  }
                }
                break;

              case "rect":
                // if (!this.isNoFillNoStroke(signChild)) {
                if (parent.fieldType) {
                  if (parent.fieldType.toLowerCase() !== "repeat") {
                    parent.children.push(signChild);
                  }
                } else {
                  //this is a first-level element (i.e., it has no parent)
                  parent.children.push(signChild);
                }
                // }
                break;

              case "text":
              case "polygon":
              case "polyline":
              case "image":
              case "path":
              case "ellipse":
              case "circle":
                parent.children.push(signChild);
                break;
              default:
                break;
            }
          }
        }
      }

      // we can now calculate repeat row height for all repeats that don't have a height specified.
      // We do that here because we need all the fields to be defined prior to processing
      repeatsToCalculate.forEach((repeatId) => {
        calculateRepeatSize(this, repeatId);
      });

      // we can now process repeat alignments. We do that here because we need all the fields to be defined prior to processing
      repeatDataNames.forEach((repeatString: string) => {
        this.processRepeatAlignments(repeatString);
      });
    },

    elementHasNoFillNoStrokeRect: function (element: Snap.Element): boolean {
      let found = false;
      element.children().forEach((child) => {
        if (child.type === "rect" && child.attr("fill") === "none") {
          found = true;
        }
      });
      return found;
    },
    /**
     * build the sign data
     * @param {Snap.Element} svg - the snap svg element
     */
    buildSignData: function (svg: Snap.Element) {
      // console.debug(`buildSignData: ${sequenceNum++}`);
      try {
        this.units = Units.getInstance();
      } catch (e) {
        console.log(e);
      }
      this.signData = {} as iSignData;
      //if measurement unit is empty this is the first time we are loading the sign data
      if (this.$store.getters.signData.measurementUnit.unit === "") {
        this.signData.id = this.signType.id;
        this.signData.uuid = this.signType.uuid;
        this.signData.folderId = this.signType.folderId;
        this.signData.libraryId = this.signType.libraryId;
        this.signData.shortCode = this.signType.shortCode;
        this.signData.details = this.signType.details;
        this.signData.hexColor = this.signType.hexColor;
        this.signData.unitCost = this.signType.unitCost;
        this.signData.numberOfColumns = this.signType.numberOfColumns;
        this.signData.numberOfMessages = this.signType.numberOfMessages;
        this.signData.isDirectional = this.signType.isDirectional;
        this.signData.markerId = this.signType.markerId;
        this.signData.useScaleInMessageSchedule =
          this.signType.useScaleInMessageSchedule;
        this.signData.scale = this.signType.scale;
        this.signData.publish = this.signType.publish;
        this.signData.markerNoScalePercent = this.signType.markerNoScalePercent;
        this.signData.markerScaleId = this.signType.markerScaleId;
        this.signData.markerScaleWidth = this.signType.markerScaleWidth;
        this.signData.markerScaleWidthUnit = this.signType.markerScaleWidthUnit;
        this.signData.price = this.signType.price;
        this.signData.priceCurrency = this.signType.priceCurrency;
        this.signData.templateName = this.signType.svgFile;
        this.signData.svgUrl = this.signType.svgUrl;
        //get the title of the sign from the svg if it has a title element
        //otherwise use the name from the signtype data
        // let title = svg.select("title");
        // if (title) {
        //   this.signData.name = title.node.textContent as string;
        // } else {
        this.signData.name = this.signType.name;
        this.signData.hexColor = this.signType.hexColor;

        this.signData.sides = [];
        if (svg) {
          this.signData.width = parseFloat(svg.attr("width"));
          this.signData.height = parseFloat(svg.attr("height"));
        } else {
          this.signData.width = 0;
          this.signData.height = 0;
        }

        let conversion: number | undefined = 1;
        if (this.units) {
          conversion = this.units.conversionFactor(this.units.pageWidth.unit);
        }
        //initialize the measurementUnit
        this.signData.measurementUnit = { unit: "pt", conversion: 1 };

        if (this.units?.pageWidth) {
          if (this.units.pageWidth.unit !== "") {
            this.signData.measurementUnit.unit = this.units?.pageWidth.unit;
            if (conversion) {
              this.signData.measurementUnit.conversion = conversion;
            } else {
              this.signData.measurementUnit.conversion = 1;
            }
          }
        }
      } else {
        this.signData = JSON.parse(
          JSON.stringify(this.$store.getters.signData)
        );
        if (this.signData.uuid !== this.signType.uuid) {
          this.signData.name = this.signType.name;
          this.signData.shortCode = this.signType.shortCode;
          this.signData.hexColor = this.signType.hexColor;

          if (this.signData.width === 0) {
            this.signData.width = parseFloat(svg.attr("width"));
            this.signData.height = parseFloat(svg.attr("height"));
          }

          let conversion: number | undefined = 1;
          if (this.units) {
            conversion = this.units.conversionFactor(this.units.pageWidth.unit);
          }
          //initialize the measurementUnit
          this.signData.measurementUnit = {
            unit: this.signData.measurementUnit.unit,
            conversion: this.signData.measurementUnit.conversion,
          };

          if (this.units?.pageWidth) {
            if (this.units.pageWidth.unit !== "") {
              this.signData.measurementUnit.unit = this.units?.pageWidth.unit;
              if (conversion) {
                this.signData.measurementUnit.conversion = conversion;
              } else {
                this.signData.measurementUnit.conversion = 1;
              }
            }
          }
        }

        //reset the sides info so that we don't get duplicate elements when running processChildElements below
        this.signData.sides = [];
      }

      let sides = getSides(svg);
      // don't all user to edit multi-sided signs right now so that we can get the designer
      // released
      if (sides.length > 1) {
        if (!featureAvailable(this, "multi-sided signs")) {
          // show the user a dialog
          this.$store.commit("setUnsupportedFeature", "multi-sided signs");
          this.$store.commit("setShowUnsupportedFeatureModal", true);
        }
      }
      if (sides.length > 0) {
        this.signData.numberOfSides = sides.length;
        this.signData.areSidesIdentical = false;
        //process sides
        for (let i = sides.length - 1; i >= 0; i--) {
          let side = {
            id: sides[i].attr("id"),
            name: underscoreToNormalCase(sides[i].attr("id")),
            children: [],
          };
          this.signData.sides.push(side);
          let sideNumber =
            parseInt(
              sides[i]
                .attr("id")
                .substring(sides[i].attr("id").indexOf("_") + 1)
            ) - 1;

          this.processChildElements(
            svg.children(),
            this.signData.sides[sideNumber],
            sideNumber
          );
        }
      } else {
        this.signData.numberOfSides = 1;
        this.signData.areSidesIdentical = true;
        let side = { id: "1", name: "Side 1", children: [] };
        this.signData.sides.push(side);
        if (svg) {
          this.processChildElements(svg.children(), this.signData.sides[0], 0);
        }

        if (this.signData.sides[0].children.length < 1) {
          this.signData.numberOfSides = 0;
          this.signData.areSidesIdentical = true;
          let side = { id: "", name: "Side 1", children: [] };
          this.signData.sides[0] = side;
        }
      }
      this.$store.commit("setSignData", this.signData);
      this.$store.commit("setCurrentSide", 0);
      this.$emit("treeBuilt");
    },

    /**
     * expand the SVG
     * @param {Snap.Element} svg - the snap svg element
     */
    expandSVG: function (svg: Snap.Element) {
      // console.debug(`expandSVG: ${sequenceNum++}`);
      let expandedSVG = {} as SignTreeData;

      if (svg.type === "svg") {
        expandedSVG.name = this.signType.name; //svg.attr().id;
        // expandedSVG.width = makeFloat(svg.attr("width"));
        // expandedSVG.height = makeFloat(svg.attr("height"));
        //       expandedSVG.view_box = makeFloat(svg.attr("viewBox"));
        // expandedSVG.title = "";
        expandedSVG.isNoFillNoStroke = false;
        expandedSVG.isTargetted = false;
        expandedSVG.isSelected = false;
        expandedSVG.isVisible = true;
        expandedSVG.isOpen = true;
        expandedSVG.id = "root";
        expandedSVG.saDataId = "1";
        expandedSVG.children = [];

        this.expandSVGChildren(svg.children(), expandedSVG);
        this.$store.commit("setTreeData", expandedSVG);
        this.$emit("treeBuilt", expandedSVG);
      }
    },
    /**
     * does a list of colors contain a specific color
     * @param {iColor} obj - the color we are looking for
     * @param {Array<iColor>} list - the list of colors to look in
     * @returns {boolean} True if color is in the list
     */
    containsObject: function (obj: iColor, list: iColor[]): boolean {
      for (let i = 0; i < list.length; i++) {
        if (list[i].rgb === obj.rgb) {
          return true;
        }
      }
      return false;
    },
    /**
     * find the nearest match to a hex code color
     * @param {Array<iHexCode>} arr - the color set
     * @param {Snap.RGB} rgb - the rgb color we are matching to
     * @returns {iHexCode | null} - the nearest color to match or null if none found
     */
    matchColor(arr: Array<iHexCode>, rgb: Snap.RGB): iHexCode | null {
      let bestMatch = null;
      let lowestValue = 9999999;
      arr.forEach(function (item: any) {
        let newValue = Math.sqrt(
          Math.pow(rgb.r - item.r, 2) +
            Math.pow(rgb.g - item.g, 2) +
            Math.pow(rgb.b - item.b, 2)
        );
        if (newValue < lowestValue) {
          bestMatch = item;
          lowestValue = newValue;
        }
      });

      return bestMatch;
    },
    /**
     * return the index of first class that matches a specific name
     * @param {Array<iStyle>} classes - the classes to search through
     * @param {string} className - the name we are searching for
     * @returns {number} the index of the found item or -1 if none found
     */
    getClassIndexByName: function (
      classes: Array<iStyle>,
      className: string
    ): number {
      for (let i = 0; i < classes.length; i++) {
        if (classes[i].name == className) {
          return i;
        }
      }

      return -1;
    },
    /**
     * parse the css styles into an array of iStyle elements
     * @param {string} styles - the css style string
     * @returns {Array<iStyle>} an array of iStyle elements
     */
    parseStyles: function (styles: string): Array<iStyle> {
      let startClassIndex = 0;
      //var style = [];
      const classes = [];

      do {
        startClassIndex = styles.indexOf(".", startClassIndex);
        if (startClassIndex >= 0) {
          let endClassIndex = styles.indexOf("{", startClassIndex);
          let startStyleIndex = styles.indexOf("{", startClassIndex);
          let endStyleIndex = styles.indexOf("}", startClassIndex);

          let classNames = styles
            .substring(startClassIndex, endClassIndex)
            .split(",");
          for (let i = 0; i < classNames.length; i++) {
            classNames[i] = classNames[i].trim();
            //remove the leading '.' from the class name
            classNames[i] = classNames[i].substring(1);

            let classIndex = this.getClassIndexByName(classes, classNames[i]);

            if (classIndex < 0) {
              //class doesn't exists so add it to the classes array
              classes.push({ name: classNames[i], styles: [] as any });
              classIndex = classes.length - 1;
            }

            let style = styles.substring(startStyleIndex + 1, endStyleIndex);
            let styles_with_blank_lines = style.split(";");
            //let styles = [];
            for (
              let style_index = 0;
              style_index < styles_with_blank_lines.length;
              style_index++
            ) {
              if (styles_with_blank_lines[style_index].trim() !== "") {
                let style_item = styles_with_blank_lines[style_index]
                  .split(":")
                  .map(function (item: any) {
                    return item.trim();
                  });
                classes[classIndex].styles.push({
                  attribute: style_item[0],
                  value: style_item[1],
                });
              }
            }
            startClassIndex = endStyleIndex + 1;
          }
        }
      } while (startClassIndex >= 0);

      return classes;
    },
    /**
     * expand the SVG children
     * @param {Array<any>} elements - the elements to expand
     * @param {SignTreeData} tree - the tree to add the expanded elements to
     */
    expandSVGChildren: function (elements: Array<any>, tree: SignTreeData) {
      // console.debug(`expandSVGChildren: ${sequenceNum++}`);
      // this.parse_level++;

      for (let index = 0; index < elements.length; index++) {
        let element = elements[index];

        //save the colors
        if (element.type.charAt(0) != "#" && element.type != "svg") {
          let fill = element.attr("fill");
          if (fill != "none") {
            let rgb = Snap.getRGB(element.attr("fill"));
            let match = this.matchColor(this.hexcodes, rgb);
            let c: iColor = {
              id: element.attr("id"),
              name: match ? match.name : rgb.hex,
              label: rgb.hex,
              library: "hexcode",
              match: "Imported",
              hex: rgb.hex,
              rgb: rgb,
            };
            if (!this.containsObject(c, this.$store.getters.colors)) {
              this.$store.commit("addColor", c);
            }
          }

          let stroke = element.attr("stroke");
          if (stroke != "none") {
            let rgb = Snap.getRGB(element.attr("stroke"));
            let c: iColor = {
              id: element.attr("id"),
              name: rgb.hex,
              label: rgb.hex,
              library: "hexcode",
              match: "Imported",
              hex: rgb.hex,
              rgb: rgb,
            };

            if (!this.containsObject(c, this.$store.getters.colors)) {
              this.$store.commit("addColor", c);
            }
          }
        }

        let node;
        let ancestor = null;

        switch (element.type) {
          case "defs":
            //process the defs
            for (
              let def_index = 0;
              def_index < element.children().length;
              def_index++
            ) {
              if (element.children()[def_index].type == "style") {
                let style = element.children()[def_index];
                if (style.node.textContent) {
                  this.styles = this.parseStyles(style.node.textContent);
                }
              }
            }
            break;
          case "title":
            break;
          case "rect": {
            if (!element.attr("sa-data-id")) {
              element.attr({ "sa-data-id": element.id });
            } else {
              element.id = element.attr("sa-data-id");
            }
            node = TreeDataFactory.dataNode(tree, element);

            if (
              // element.attr("stroke") === "none" &&
              // element.attr("fill") === "none"
              this.isNoFillNoStroke(element)
            ) {
              node.isNoFillNoStroke = true;
              element.attr({ "pointer-events": "none" });
            } else {
              // element.attr({"style":""} );
              node.isNoFillNoStroke = false;
              element.attr({ "pointer-events": "all" });
            }
            tree.children.push(node);
            if (this.snap) {
              ancestor = getAncestorById(element.id, "height", this.snap);
            }

            // if this isn't a height rectangle set pointer events to all
            // we want this because repeat fields are not selectable on the
            // canvas if they belong to a repeat rect with pointer events set
            if (!ancestor) {
              if (!node.isNoFillNoStroke) {
                element.attr({ "pointer-events": "all" });
              }
            }

            // if the element already has a click event handler then don't add an additional one
            if (!element.attr("click-handler")) {
              // we need to clone the element or we will get a vuex mutation error
              const el1 = $.extend(true, {}, element);
              el1.click((e: MouseEvent) => {
                this.selectElement(el1, e);
              });
            }
            // set an attribute on the element that will let us determine
            // if the click handler event has already been attached
            element.attr({ "click-handler": "set" });
            break;
          }
          // Node the below is tree data. expand tree data to match!!!
          case "text": {
            if (!element.attr("sa-data-id")) {
              element.attr({ "sa-data-id": element.id });
            } else {
              element.id = element.attr("sa-data-id");
            }
            node = TreeDataFactory.textNode(tree, element);
            tree.children.push(node);
            // if the element already has a click event handler then don't add an additional one
            if (!element.attr("click-handler")) {
              // we need to clone the element or we will get a vuex mutation error
              const el1 = $.extend(true, {}, element);
              const p = el1.parent();
              const rect = p.select("rect");
              if (this.elementHasNoFillNoStrokeRect(p)) {
                rect.attr({ "pointer-events": "all" });
                rect.click((e: any) => {
                  this.selectElement(el1, e);
                });
                el1.click((e: any) => {
                  this.selectElement(el1, e);
                });
              } else {
                el1.click((e: any) => {
                  this.selectElement(el1, e);
                });
              }
            }
            // set an attribute on the element that will let us determine
            // if the click handler event has already been attached
            element.attr({ "click-handler": "set" });
            break;
          }
          case "ellipse":
          case "circle":
          case "polygon":
          case "polyline":
          case "image":
          case "path": {
            if (!element.attr("sa-data-id")) {
              element.attr({ "sa-data-id": element.id });
            } else {
              element.id = element.attr("sa-data-id");
            }
            node = TreeDataFactory.dataNode(tree, element);
            tree.children.push(node);
            // if the element already has a click event handler then don't add an additional one
            if (!element.attr("click-handler")) {
              // we need to clone the element or we will get a vuex mutation error
              const el1 = $.extend(true, {}, element);
              el1.click((e: any) => {
                this.selectElement(el1, e);
              });
            }
            // set an attribute on the element that will let us determine
            // if the click handler event has already been attached
            element.attr({ "click-handler": "set" });
            break;
          }
          case "g":
            {
              if (!element.attr("sa-data-id")) {
                element.attr({ "sa-data-id": element.id });
              } else {
                element.id = element.attr("sa-data-id");
              }

              let g = TreeDataFactory.dataNode(tree, element);
              this.expandSVGChildren(element.children(), g);
              tree.children.push(g);
            }
            break;
          case "#text": {
            //don't do anything with #text elements
            break;
          }
          default:
            console.log("UNCAUGHT ITEM.TYPE! " + element.type);
            break;
        }
      }
    },
  },
  computed: {},
  watch: {
    /**
     * when the zoomPercentage from the store changes
     * then zoom the SVG
     */
    "$store.state.signDesigner.zoomPercentage": function () {
      if (this.$store.getters.zoomPercentage < minZoomPercentage) {
        this.$store.commit("setZoomPercentage", minZoomPercentage);
      }

      if (this.snap) {
        let svg = this.snap.select("svg");
        if (svg) {
          let w =
            svg.getBBox().width * (this.$store.getters.zoomPercentage / 100);
          let h =
            svg.getBBox().height * (this.$store.getters.zoomPercentage / 100);

          svg.attr({
            width: w,
            height: h,
            preserveAspectRatio: "xMinYMin",
          });
        }
      }
      if (this.treeData) {
        this.drawSelectionHandlesFromIds();
      }
    },
    /**
     * when the rerender flag from the store changes
     * then force an redraw
     */
    "$store.state.signDesigner.rerender": function () {
      this.drawSelectionHandlesFromIds();
      this.toggleVisibility(
        this.$store.getters.signData.sides[this.$store.getters.currentSide]
          .children
      );
    },
    /**
     * when the currentSignTypeUUID from the store changes
     * then loadSignData if needed
     */
    "$store.state.signDesigner.currentSignTypeUUID": function () {
      if (this.$store.getters.selectedSignType) {
        //only load the sign if the sign type isn't the already selected one
        if (
          this.$store.getters.selectedSignType.uuid !==
          this.$store.getters.currentSignTypeUUID
        ) {
          if (this.$store.getters.currentSignTypeUUID !== "") {
            this.loadSignData();
          } else {
            this.$store.commit("setShowLoadingSpinner", false);
          }
        }
      } else {
        if (this.$store.getters.currentSignTypeUUID !== "") {
          //only load the sign data if the sign type has changed
          if (
            this.$store.getters.currentSignTypeUUID !==
            this.$store.getters.previousSignTypeUUID
          ) {
            this.loadSignData();
          }
        } else {
          // this.$store.commit("setShowLoadingSpinner", false);
        }
      }
    },
    /**
     * when the scrollTop changes then scroll the window
     */
    scrollTop: function () {
      if (this && this.$refs && this.$refs.workspace_wrapper) {
        (this.$refs.workspace_wrapper as HTMLElement).scrollTop =
          this.scrollTop;
      }
    },
    /**
     * when the scrollLeft changes then scroll the window
     */
    scrollLeft: function () {
      if (this && this.$refs && this.$refs.workspace_wrapper) {
        (this.$refs.workspace_wrapper as HTMLElement).scrollLeft =
          this.scrollLeft;
      }
    },
  },
});
