













import Vue from "vue";
import { IMeasurementUnit } from "../types";
import { convertPointsToUnit, convertUnitToPoints } from "../utilities";
//import { makeFloat } from "../include/Utils";

export default Vue.extend({
  props: {
    value: {
      type: [Number, String],
    },
    minValue: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      displayValue: "",
      measurementUnit: {
        unit: "in",
        conversion: 0.0138888888889,
      } as IMeasurementUnit,
      measurementOptions: [
        { name: "Points", conversion: 1, abbreviation: "pt" },
        { name: "Pixels", conversion: 0.75, abbreviation: "px" },
        { name: "Inches", conversion: 0.0138888888888889, abbreviation: "in" },
        { name: "Feet", conversion: 0.001157407, abbreviation: "ft" },
        {
          name: "Millimeters",
          conversion: 0.3527778610725505,
          abbreviation: "mm",
        },
        { name: "Centimeters", conversion: 0.03527834, abbreviation: "cm" },
      ],
    };
  },
  mounted: function () {
    if (typeof this.value === "string") {
      this.displayValue = parseFloat(this.value) + " " + this.measure;
    } else {
      this.displayValue =
        parseFloat((this.value as number).toFixed(5)) + " " + this.measure;
    }
  },
  methods: {
    /**
     * auto select the text when input gets focus
     * @param {Event} evt - the event which triggered this call
     */
    selectText: function (evt: Event) {
      let target = evt.target as HTMLInputElement;
      target.select();
    },
    /**
     * handle the value changed event
     * @param {Event} evt - the event which triggered this call
     */
    valueChanged: function (evt: Event) {
      let target = evt.target as HTMLInputElement;
      let newValue = target.value;
      // let numberPos = 0;
      for (var i = 0; i < newValue.length; i++) {
        if (isNaN(parseFloat(newValue.substr(i, 1)))) {
          //decimal points and minus signs are allowed in the numeric portion
          if (newValue.substr(i, 1) == "." || newValue.substr(i, 1) == "-") {
            //pass
          } else {
            break;
          }
        }
      }

      let numericPortion = parseFloat(newValue.substring(0, i)); // the part of the string that is numeric;
      let measurement = newValue.substring(i).trim(); //the rest of the string

      //if measurement is blank then use the current measure
      if (measurement === this.measure || measurement === "") {
        if (numericPortion < this.minValue) {
          numericPortion = this.minValue;
        }
        this.$emit("input", parseFloat(numericPortion.toFixed(5)));
        // parseFloat of a toFixed will return a number without trailing zeros
        this.displayValue =
          parseFloat(numericPortion.toFixed(5)) + " " + this.measure;
      } else {
        let measurementFound = false;
        let foundMeasure = 0;
        for (let i = 0; i < this.measurementOptions.length; i++) {
          if (this.measurementOptions[i].abbreviation == measurement) {
            measurementFound = true;
            foundMeasure = i;
            break;
          }
        }
        if (measurementFound) {
          //convert number to points
          let numberInPoints = convertUnitToPoints(
            numericPortion,
            this.measurementOptions[foundMeasure].abbreviation
          );
          //convert points to user's measurement unit
          let numberInUnits = convertPointsToUnit(numberInPoints, this.measure);
          this.$emit("input", numberInUnits.toFixed(5));
          this.displayValue =
            parseFloat(numberInUnits.toFixed(5)) + " " + this.measure;
        } else {
          //invalid measurement unit so set the value back to the original
          //this.$emit('input', this.value)
          if (typeof this.value === "string") {
            this.displayValue = parseFloat(this.value) + " " + this.measure;
          } else {
            this.displayValue =
              parseFloat(this.value.toFixed(5)) + " " + this.measure;
          }
        }
      }

      // if the measurement equals this.measure
      //      emit('input', numericPortion)
      // else
      //      check if measurement is in the measurementOptions
      //          if it is then convert it to this.measure units
      //          if not then leave the value the same
      //
    },
    /**
     * return a string of the value and unit of measure (i.e. "1.25 in")
     * @returns {string} the display string
     */
    setDisplayValue: function (): string {
      if (typeof this.value === "string") {
        return parseFloat(this.value) + " " + this.measure;
      } else {
        return (
          parseFloat((this.value as number).toFixed(5)) + " " + this.measure
        );
      }
    },
    /**
     * return the value portion of the display value
     * @returns {string} - the value portion of the display string
     */
    getValueFromDisplay: function (): string {
      return this.displayValue.substr(0, this.displayValue.indexOf(" "));
    },
  },
  computed: {
    /**
     * shorthand for the measurement unit from the store's signData
     * @returns {string} the measurement unit
     */
    measure: function () {
      return this.$store.getters.signData.measurementUnit.unit;
    },
  },
  watch: {
    /**
     * when the value changes update the display value
     */
    value: function () {
      if (typeof this.value === "string") {
        this.displayValue = parseFloat(this.value) + " " + this.measure;
      } else {
        this.displayValue =
          parseFloat(this.value.toFixed(5)) + " " + this.measure;
      }
    },
    /**
     * when the measurementUnit changes update the abbreviation and measurement unit
     */
    measurementUnit: function (newUnit) {
      this.measure = newUnit.abbreviation;
      this.displayValue = this.value + " " + this.measure;
    },
  },
});
