



















































































/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import $ from "jquery";
import {
  ICompany,
  IGraphPricePlans,
  IGraphUnmigratedCompanies,
  INonMigratedCompany,
  IPricePlan,
  IPriceTier,
  IMember,
  IMemberLicense,
} from "../types";
import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import CompanyList from "../components/CompanyList.vue";

export default Vue.extend({
  components: { FullPageLayout, CompanyList },
  data: function () {
    return {
      companies: [] as ICompany[],
      nonMigratedCompanies: [] as INonMigratedCompany[],
      plannedUserCount: 0,
      newCompany: {} as ICompany,
      newCompanyMembers: [] as IMember[],
      newCompanyBillingMethod: "",
      localPlanType: "",
      nonMigratedEndCursor: "",
      nonMigratedHasNextPage: false,
      searchText: "",
    };
  },
  mounted: function () {
    this.getCompanies();
    this.getNonMigratedCompanies();
  },
  methods: {
    searchTextChanged: function (searchText: string) {
      this.searchText = searchText;
      this.getNonMigratedCompanies();
    },
    searchMigrated: function (searchText: string) {
      this.searchText = searchText;
      this.getCompanies();
    },
    /**
     * fetch all existing (i.e. migrated) companies
     * @param {string} endCursor - identifier for the last element in a graphql response
     */
    getCompanies(endCursor = "") {
      this.$store.commit("setShowLoadingSpinner", true);
      this.companies = [];
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query get_companies {
            companies(after: "${endCursor}", name_Icontains: "${this.searchText}") {
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                node {
                  id: contentObjectId
                  name
                  street1: address1
                  city
                  state
                  countryCode
                  postalCode
                  isSalesCompany: salesCompany
                }
              }
            }
          }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          result.data.companies.edges.forEach((edge: any) => {
            const localCompany = edge.node as ICompany;
            if (!localCompany.isSalesCompany) {
              this.companies.push(localCompany);
            }
          });
          // if there is another page of results, recurse
          if (result.data.companies.pageInfo.hasNextPage) {
            this.getCompanies(result.data.companies.pageInfo.endCursor);
          }
          // once all migrated companies are fetched, sort and repeat for non-migrated companies
          this.companies.sort(this.sortCompaniesByName);
          this.$store.commit("setShowLoadingSpinner", false);
        });
    },
    /**
     * get all non-migrated companies (i.e. licenses that still must be converted)
     * @param {string} endCursor - identifier for the last element in a graphql response
     */
    getNonMigratedCompanies: function (endCursor = "") {
      this.$store.commit("setShowLoadingSpinner", true);
      const query = JSON.stringify({
        query: `query get_non_migrated_companies {
          unmigratedCompanies(after: "${endCursor}", payorEmail: "${this.searchText}") {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                contentObjectId
                companyStripeId
                users {
                  id:contentObjectId
                  firstName
                  lastName
                  email
                }
                payor {
                  userId
                  firstName
                  lastName
                  email
                  organization
                  companyLicenses {
                    effectiveEndDate
                  }
                }
              }
            }
          }
        }`,
      });
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((result: IGraphUnmigratedCompanies) => {
          if (endCursor === "") {
            this.nonMigratedCompanies = [];
          }

          result.data.unmigratedCompanies.edges.forEach((edge) => {
            let pushCompany = false;
            if (edge.node.payor) {
              if (edge.node.users.length === 1) {
                if (edge.node.payor.companyLicenses) {
                  edge.node.payor.companyLicenses.forEach((license) => {
                    if (new Date(license.effectiveEndDate) >= new Date()) {
                      pushCompany = true;
                    }
                  });
                }
              } else {
                pushCompany = true;
              }

              if (pushCompany) {
                this.nonMigratedCompanies.push({
                  name: edge.node.payor.organization,
                  stripeId: edge.node.companyStripeId,
                  billingOwner: {
                    id: edge.node.payor.userId,
                    firstName: edge.node.payor.firstName,
                    lastName: edge.node.payor.lastName,
                    email: edge.node.payor.email,
                  } as IMember,
                });
              }
            }
          });
          this.nonMigratedEndCursor =
            result.data.unmigratedCompanies.pageInfo.endCursor;
          this.nonMigratedHasNextPage =
            result.data.unmigratedCompanies.pageInfo.hasNextPage;

          // if (result.data.unmigratedCompanies.pageInfo.hasNextPage) {
          //   this.getNonMigratedCompanies(
          //     result.data.unmigratedCompanies.pageInfo.endCursor
          //   );
          // }
          // sort non migrated companies
          this.nonMigratedCompanies.sort(this.sortCompaniesByName);
          this.$store.commit("setShowLoadingSpinner", false);
        });
    },
    /**
     * sorts companies by name
     * @param {ICompany | INonMigratedCompany} companyA - first company to compare
     * @param {ICompany | INonMigratedCompany} companyB - second company to compare
     * @returns {number} - number which indicates the order of the companies
     */
    sortCompaniesByName(
      companyA: ICompany | INonMigratedCompany,
      companyB: ICompany | INonMigratedCompany
    ): number {
      if (!companyA.name) {
        return 1;
      } else if (!companyB.name) {
        return -1;
      }
      let previousName = companyA.name.toLowerCase();
      let currentName = companyB.name.toLowerCase();
      if (previousName < currentName) {
        return -1;
      }
      if (previousName > currentName) {
        return 1;
      }
      return 0;
    },
    /**
     * fetch the sales company's price plans that are available to assign to non-migrated companies
     */
    getPricePlans() {
      if (!this.loadedPlanIds.length && !this.loadedTierIds.length) {
        let priceTierId = 1;
        const pricePlanQuery = JSON.stringify({
          query: `query get_price_plans {
            pricingPlans {
              edges {
                node {
                  id:contentObjectId
                  name
                  planType
                  prices {
                    edges {
                      node {
                        id: contentObjectId
                        stripePriceId
                        billingCycle
                        tiers {
                          edges {
                            node {
                              upTo
                              amount
                              currency
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`,
        });
        fetch("/graphql/", {
          method: "POST",
          body: pricePlanQuery,
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        })
          .then((data) => data.json())
          .then((result: IGraphPricePlans) => {
            result.data.pricingPlans.edges.forEach((node) => {
              const pricePlan = {
                id: parseInt(node.node.id),
                name: node.node.name,
                planType: node.node.planType,
              } as IPricePlan;
              if (!this.loadedPlanIds.includes(pricePlan.id)) {
                this.$store.commit("addPricePlan", pricePlan);
              }
              node.node.prices.edges.forEach((price) => {
                if (price.node.tiers) {
                  price.node.tiers.edges.forEach((tier) => {
                    const priceTier = {
                      id: priceTierId,
                      pricePlanId: pricePlan.id,
                      billingCycle: price.node.billingCycle,
                      stripePriceId: price.node.stripePriceId,
                      numberOfUsers: tier.node.upTo,
                      price: tier.node.amount,
                      currency: tier.node.currency,
                    } as IPriceTier;
                    if (!this.loadedTierIds.includes(priceTier.id)) {
                      this.$store.commit("addPriceTier", priceTier);
                      priceTierId++;
                    }
                  });
                }
              });
            });
          });
      }
    },
    /**
     * set local variables and displays the manage plan modal
     * @param {number} userCount - the number of members that will be using the plan
     * @param {string} planType - the type of plan to be set up (either "WEB" or "MOBILE")
     */
    setUpPlan(userCount: number, planType: string) {
      this.getPricePlans();

      this.localPlanType = planType;
      this.plannedUserCount = userCount;
      $("#manage-plan-modal").modal({ show: true });
    },
    /**
     * triggers the migration process and calls the appropriate migration function based on the
     * new company's payment method (online or offline)
     * @param {ICompany} newCompany - the company that we are going to create
     * @param {IMember[]} members - list of members for the new company
     * @param {string} billingMethod - how the company will be paying (either "online" or "offline")
     * @param {string} subscriptionItems - array of price keys and quantities necessary to create the subscription item
     * @param {string} newSubscriptionBillingCycle - billing cycle of the new subscription (either "MONTH" or "YEAR")
     */
    beginMigration(
      newCompany: ICompany,
      members: IMember[],
      billingMethod: string,
      subscriptionItems: Array<{ price: string; quantity: number }>,
      newSubscriptionBillingCycle: string,
      startBillingDate: Date
    ) {
      // set local data
      this.newCompany = newCompany;
      this.newCompanyMembers = members;
      this.newCompanyBillingMethod = billingMethod;
      if (["online", "offline"].includes(this.newCompanyBillingMethod)) {
        this.migrateBillingCompany(
          subscriptionItems,
          newSubscriptionBillingCycle,
          this.newCompanyBillingMethod == "offline",
          startBillingDate
        );
      }
    },
    /**
     * migrates company using online billing and Stripe subscriptions
     * @param {string} subscriptionItems - array of price keys and quantities necessary to create the subscription item
     * @param {string} billingCycle - the billing cycle for the new subscription (either "MONTH" or "YEAR")
     */
    migrateBillingCompany(
      subscriptionItems: Array<{ price: string; quantity: number }>,
      billingCycle: string,
      isBilledOffline: boolean,
      startBillingDate: Date
    ) {
      // if (!billingCycle) {
      //   // this is thrown if the user attempts to create a subscription with multiple items and different billing cycles
      //   alert("Billing cycle must be the same for each plan item.");
      // } else {
      // format company users input for the backend mutation
      const companyUsersInput: Array<{
        id: number;
        role: string;
        licenseTypes: Array<IMemberLicense>;
      }> = [];
      this.newCompanyMembers.forEach((member) => {
        companyUsersInput.push({
          id: member.id,
          role: member.role,
          licenseTypes: member.companyLicenses,
        });
      });

      // define query
      const companyQuery = JSON.stringify({
        variables: {
          mutateUnmigratedCompanyInput: {
            name: this.newCompany.name,
            state: this.newCompany.state,
            countryCode: this.newCompany.countryCode,
            isBilledOffline: isBilledOffline,
            billingOwner: this.newCompanyBillingOwner.id,
            companyUsers: JSON.stringify(companyUsersInput),
            subscriptionItems: JSON.stringify(subscriptionItems),
            startBillingDate: startBillingDate,
          },
        },
        query: `
            mutation create_company(
              $mutateUnmigratedCompanyInput: MutateUnmigratedCompanyInput!
            ) {
              mutateUnmigratedCompany(input: $mutateUnmigratedCompanyInput) {
                errors { messages }
                company {
                  id: contentObjectId
                  name
                }
              }
            }`,
      });

      // create the company
      fetch("/graphql/", {
        method: "POST",
        body: companyQuery,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateUnmigratedCompany?.errors.length > 0) {
            alert(
              result.data.mutateUnmigratedCompany.errors[0].messages.join(", ")
            );
          } else {
            location.reload();
          }
        });
      // }
    },
  },
  computed: {
    /**
     * a list of price plans
     * @returns {IPricePlan[]} - list of price plans, sometimes filtered if we are wokring with a certain plan type
     */
    pricePlans(): IPricePlan[] {
      const allPricePlans = this.$store.getters.pricePlans;
      if (this.localPlanType !== "") {
        return allPricePlans.filter((plan: IPricePlan) => {
          return plan.planType === this.localPlanType;
        });
      }
      return allPricePlans;
    },
    /**
     * exists to prevent double-loading plans from backend
     * @returns {number[]} - list of plan ids that have been loaded already
     */
    loadedPlanIds(): number[] {
      return this.$store.getters.pricePlans.map((plan: IPricePlan) => plan.id);
    },
    /**
     * exists to prevent double-loading tiers from backend
     * @returns {number[]} - list of tier ids that have been loaded already
     */
    loadedTierIds(): number[] {
      return this.$store.getters.priceTiers.map((tier: IPriceTier) => tier.id);
    },
    /**
     * @returns {IMember} - billing owner of a company being migrated
     */
    newCompanyBillingOwner(): IMember {
      if (this.newCompanyMembers.length) {
        const billingOwner = this.newCompanyMembers.find(function (member) {
          return member.role === "BILLING_OWNER";
        });
        if (billingOwner) {
          return billingOwner;
        }
        return {} as IMember;
      }
      return {} as IMember;
    },
  },
  watch: {},
});
