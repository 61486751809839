export function uppercase(value: string): string {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
}

export function asMoney(value: number): string {
  return "$" + value.toFixed(2);
}

export function asDate(value: Date): string {
  if (value) {
    return value.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } else {
    return "";
  }
}

/**
 * capitalize the first character in a string.
 * @param string value - the string to be capitalized
 */
export function capitalize(value: string): string {
  // Upper case the first character and lower case all the other letters
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  } else {
    return "";
  }
}

export function timesince(date: Date): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 2592000);

  if (interval >= 1) {
    return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 86400);

  if (interval >= 1) {
    return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 3600);

  if (interval >= 1) {
    return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
  }

  interval = Math.floor(seconds / 60);

  if (interval >= 1) {
    return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
  }

  return Math.floor(seconds) + " second" + (seconds === 1 ? "" : "s") + " ago";
}
