


















































































import Vue from "vue";
import $ from "jquery";

import { IPaymentMethod } from "../types";

import { capitalize } from "@/filters/common";

export default Vue.extend({
  filters: { capitalize },
  props: ["companyId"],
  data: function () {
    return {
      errorMessage: "",
      paymentMethods: [] as Array<IPaymentMethod>,
    };
  },
  mounted: function () {
    this.loadCards();

    $("#show-payment-methods-modal").on("show.bs.modal", () => {
      this.loadCards();
    });
  },
  methods: {
    closeModal: function () {
      $("#show-payment-methods-modal").modal("hide");
      this.$emit("closing");
      // window.location.href = "/company/" + this.companyId + "/billing";
    },
    removeCard: function (stripeId: string) {
      const query = JSON.stringify({
        query: `
          mutation delete_payment_method {
            deletePaymentMethod(input: {
              companyId: ${this.companyId},
              paymentMethodId: "${stripeId}"
            }) {
              # errors { messages }
              success
            }
          }`,
      });
      // delete payment method on backend
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          if (result.errors) {
            this.errorMessage = result.errors[0].message;
          }
          // } else if (result.data.deletePaymentMethod?.errors.length > 0) {
          //   this.errorMessage =
          //     result.data.deletePaymentMethod.errors[0].messages.join(", ");
          else if (
            result.data.deletePaymentMethod &&
            result.data.deletePaymentMethod.success
          ) {
            this.loadCards();
          } else {
            this.errorMessage = "Unknown error";
          }
        });
    },
    makeDefault: function (stripeId: string) {
      const query = JSON.stringify({
        query: `
          mutation set_default_payment_method {
            setDefaultPaymentMethod(input: {
              companyId: ${this.companyId},
              paymentMethodId: "${stripeId}"
            }) {
              # errors { messages }
              success
            }
          }`,
      });
      // delete payment method on backend
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          if (result.errors) {
            this.errorMessage = result.errors[0].message;
          }
          // } else if (result.data.setDefaultPaymentMethod?.errors.length > 0) {
          //   this.errorMessage =
          //     result.data.setDefaultPaymentMethod.errors[0].messages.join(", ");
          else if (
            result.data.setDefaultPaymentMethod &&
            result.data.setDefaultPaymentMethod.success
          ) {
            this.loadCards();
          } else {
            this.errorMessage = "Unknown error";
          }
        });
    },
    loadCards: function () {
      const billingQuery = JSON.stringify({
        query: `query get_billing_info{
            company(id: ${this.companyId}) {
              billing {
                paymentMethods {
                  brand
                  last4
                  default
                  expYear
                  expMonth
                  stripeId
                }
              }
            }
          }`,
      });
      fetch("/graphql/", {
        method: "POST",
        body: billingQuery,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((result) => result.json())
        .then((data) => {
          this.paymentMethods = [];
          data.data.company.billing.paymentMethods.forEach(
            (paymentMethod: {
              brand: string;
              last4: string;
              default: boolean;
              expYear: number;
              expMonth: number;
              stripeId: string;
            }) => {
              this.paymentMethods.push({
                cardType: paymentMethod.brand,
                last4: paymentMethod.last4,
                default: paymentMethod.default,
                expYear: paymentMethod.expYear,
                expMonth: paymentMethod.expMonth,
                stripeId: paymentMethod.stripeId,
              });
            }
          );
        });
    },
    getIconSource: function (cardType: string) {
      switch (cardType) {
        case "visa": {
          return "/static/img/credit_cards/1.png";
        }
        case "mastercard": {
          return "/static/img/credit_cards/2.png";
        }
        case "amex": {
          return "/static/img/credit_cards/22.png";
        }
        case "diners": {
          return "/static/img/credit_cards/10.png";
        }
        case "discover": {
          return "/static/img/credit_cards/14.png";
        }
        case "jcb": {
          return "/static/img/credit_cards/16.png";
        }
        case "unionpay": {
          return "/static/img/credit_cards/25.png";
        }
      }
    },
  },
  computed: {},
  watch: {},
});
