import { Module } from "vuex";
import {
  IOrder,
  IBuilder,
  IStripeQuote,
  IOrderManager,
  IOrderSignTypeSign,
} from "./types";
import { Stripe } from "@stripe/stripe-js";
import { RootState } from "../../store";

export interface OrderState {
  count: number;
  showSignDetailsModal: boolean;
  showInviteQuotersModal: boolean;
  showShippingAddressModal: boolean;
  showBillingAddressModal: boolean;
  showPaymentModal: boolean;
  builders: Array<IBuilder>;
  quotes: Array<IStripeQuote>;
  orders: Array<IOrder>;
  order: IOrder | null;
  stripe: Stripe | null;
  orderItems: Array<string>;
}

const store: Module<OrderState, RootState> = {
  state: (): OrderState => ({
    count: 11,
    showSignDetailsModal: false,
    showInviteQuotersModal: false,
    showShippingAddressModal: false,
    showBillingAddressModal: false,
    showPaymentModal: false,
    builders: [],
    quotes: [],
    orders: [],
    order: {
      id: "",
      uuid: "",
      name: "",
      submittedDate: new Date(),
      dueDate: new Date(),
      amount: 0,
      currency: "",
      status: "",
      stateId: 0,
      stateName: "",
      poNumber: "",
      lastSaved: null,
      manager: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        image: "",
      },
      builder: {
        id: "",
        name: "",
        email: "",
      },
      builderOrder: {
        id: "",
      },
      userIsBuilder: false,
      signTypes: [
        {
          id: "",
          orderItemId: 0,
          quantity: 0,
          name: "",
          shortCode: "",
          hexColor: "",
          isExpanded: false,
          isRemoveExpanded: false,
          install_price: 0,
          actual_price: 0,
          currency: "",
          orderLineItemId: "",
          orderId: 0,
          globalOrderIdVal: "",
          folderOrderId: 0,
          signsLoaded: false,
          signs: [
            {
              id: "",
              artwork: null,
              quantity: 0,
              number: "",
              reviewState: "",
              facingDirection: 0,
              signId: "",
              hexColor: "",
              messages: "",
              repeatingMessages: "",
              signMessageFields: [
                {
                  name: "",
                  fieldType: "",
                  value: "",
                },
              ],
              repeatingMessageFields: [
                {
                  name: "",
                  fieldType: "",
                  value: "",
                },
              ],
              details: "",
              attachments: [
                {
                  attachmentFileName: "",
                  attachment: "",
                  user: {
                    firstName: "",
                    lastName: "",
                  },
                  datetime: "",
                },
              ],
              signType: {
                id: "",
                shortCode: "",
                name: "",
                installPrice: 0,
                signPrice: 0,
                currency: "",
              },
              location: {
                id: "",
                shortCode: "",
                name: "",
              },
            },
          ],
        },
      ],
      itemSubtotal: 0,
      shipping: 0,
      taxes: {
        name: "",
        amount: 0,
      },
      billingSameAsShipping: true,
      shippingStreet1: "",
      shippingStreet2: "",
      shippingCity: "",
      shippingState: "",
      shippingPostalCode: "",
      shippingCountry: "",
      shippingContact: "",
      billingStreet1: "",
      billingStreet2: "",
      billingCity: "",
      billingState: "",
      billingPostalCode: "",
      billingCountry: "",
      billingContact: "",
      includeInstall: false,
      IncludeBuild: false,
      comments: [
        {
          commentId: "",
          message: "",
          userId: "",
          userFirstName: "",
          userLastName: "",
          userPic: "",
        },
      ],
      projects: [
        {
          name: "",
          phaseType: "",
        },
      ],
      organization: {
        id: "",
        name: "",
      },
    },

    stripe: null,
    orderItems: [],
  }),
  mutations: {
    // removeOrderItem(state, params) {
    //   for (let i = 0; i < state.orders.length; i++) {
    //     const order = state.orders[i];
    //     if (order.orderNo === params.orderNo) {
    //       for (let j = 0; j < order.orderDetails.length; j++) {
    //         const detail = order.orderDetails[j];
    //         if (detail.itemId === params.itemId) {
    //           for (let k = 0; k < detail.signs.length; k++) {
    //             const sign = detail.signs[k];
    //             if (sign.id === params.signId) {
    //               detail.signs.splice(i, 1);
    //               detail.quantity--;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    setOrderShipping: function (state: OrderState, payload: number) {
      if (state.order) {
        state.order.shipping = payload;
      }
    },
    setOrderStatus: function (
      state: OrderState,
      payload: { orderId: number; status: string }
    ) {
      if (state.order) {
        state.order.status = payload.status;
      }
    },
    setOrderActualPrice: function (
      state: OrderState,
      payload: { value: number; index: number }
    ) {
      if (state.order) {
        state.order.signTypes[payload.index].actual_price = payload.value;
      }
    },
    toggleOrderItemExpanded: function (state: OrderState, itemId: string) {
      if (state.order) {
        state.order.signTypes.forEach((signType) => {
          if (signType.id === itemId) {
            signType.isExpanded = !signType.isExpanded;
          }
        });
      }
    },
    setShowSignDetailsModal: function (state: OrderState, payload: boolean) {
      state.showSignDetailsModal = payload;
    },
    setShowInviteQuotersModal: function (state: OrderState, payload: boolean) {
      state.showInviteQuotersModal = payload;
    },

    setShowShippingAddressModal: function (
      state: OrderState,
      payload: boolean
    ) {
      state.showShippingAddressModal = payload;
    },
    setShowBillingAddressModal: function (state: OrderState, payload: boolean) {
      state.showBillingAddressModal = payload;
    },
    setOrder(state: OrderState, payload) {
      state.order = payload;
    },
    updateOrderCurrency(state: OrderState, payload: string) {
      if (state.order) {
        state.order.currency = payload;
      }
    },
    updateOrderIncludeInstall(state: OrderState, payload: boolean) {
      if (state.order) {
        state.order.includeInstall = payload;
      }
    },
    updateOrderIncludeBuild(state: OrderState, payload: boolean) {
      if (state.order) {
        state.order.IncludeBuild = payload;
      }
    },
    updateOrderLastSaved(state: OrderState, payload: Date) {
      if (state.order) {
        state.order.lastSaved = payload;
      }
    },
    updateOrderPONumber(state: OrderState, payload: string) {
      if (state.order) {
        state.order.poNumber = payload;
      }
    },

    updateReviewStateForSignId(state: OrderState, payload) {
      state.order?.signTypes.forEach((signType) => {
        signType.signs.forEach((sign) => {
          if (sign.id === payload.signId) {
            sign.reviewState = payload.reviewState;
          }
        });
      });
    },
    toggleIsRemoveExpanded(state: OrderState, payload) {
      state.order?.signTypes.forEach((signType) => {
        if (signType.id === payload.id) {
          signType.isRemoveExpanded = !signType.isRemoveExpanded;
        }
      });
    },
    setBillingSameAsShipping(state: OrderState, payload: boolean) {
      if (state.order) {
        state.order.billingSameAsShipping = payload;
      }
    },

    setOrderNameForOrder(state: OrderState, payload) {
      if (state.order) {
        state.order.name = payload;
      }
    },
    setOrderItems(state: OrderState, payload) {
      state.orderItems = payload;
    },
    setShowPaymentModal(state: OrderState, payload: boolean) {
      state.showPaymentModal = payload;
    },
    setIssuedDateForOrder(state: OrderState, payload) {
      if (state.order) {
        state.order.submittedDate = payload;
      }
    },
    setSignTypeSigns(
      state: OrderState,
      payload: { signTypeId: string; signs: Array<IOrderSignTypeSign> }
    ) {
      if (state.order) {
        const signTypeIndex = state.order.signTypes.findIndex((signType) => {
          return signType.id === payload.signTypeId;
        });
        if (signTypeIndex !== -1) {
          state.order.signTypes[signTypeIndex].signs = payload.signs;
        }
      }
    },
    setSignTypeSignsLoaded(
      state: OrderState,
      payload: { signTypeId: string; value: boolean }
    ) {
      if (state.order) {
        const signTypeIndex = state.order.signTypes.findIndex((signType) => {
          return signType.id === payload.signTypeId;
        });
        if (signTypeIndex !== -1) {
          state.order.signTypes[signTypeIndex].signsLoaded = payload.value;
        }
      }
    },
    setSignTypePrice(
      state: OrderState,
      payload: { signTypeIndex: number; value: number }
    ) {
      if (state.order) {
        state.order.signTypes[payload.signTypeIndex].actual_price =
          payload.value;
      }
    },
    updateSignTypeSignPrice(
      state: OrderState,
      payload: { signTypeId: string; price: number }
    ) {
      if (state.order) {
        const signTypeIndex = state.order.signTypes.findIndex((signType) => {
          return signType.id === payload.signTypeId;
        });
        if (signTypeIndex !== -1) {
          state.order.signTypes[signTypeIndex].actual_price = payload.price;
          state.order.signTypes[signTypeIndex].signs.forEach((sign) => {
            sign.signType.signPrice = payload.price;
          });
        }
      }
    },
    updateSignTypeInstallPrice(
      state: OrderState,
      payload: { signTypeId: string; price: number }
    ) {
      if (state.order) {
        const signTypeIndex = state.order.signTypes.findIndex((signType) => {
          return signType.id === payload.signTypeId;
        });
        if (signTypeIndex !== -1) {
          state.order.signTypes[signTypeIndex].install_price = payload.price;
          state.order.signTypes[signTypeIndex].signs.forEach((sign) => {
            sign.signType.installPrice = payload.price;
          });
        }
      }
    },
    updateOrderDueDate(state: OrderState, dueDate: Date) {
      if (state.order) {
        if (dueDate) {
          state.order.dueDate = new Date(dueDate);
        } else {
          state.order.dueDate = null;
        }
      }
    },
    updateOrderManager(state: OrderState, manager: IOrderManager) {
      if (state.order) {
        state.order.manager = manager;
      }
    },
    updateOrderBuilderEmail(state: OrderState, builderEmail: string) {
      if (state.order) {
        state.order.builder.email = builderEmail;
      }
    },
    updateOrderTaxName(state: OrderState, taxName: string) {
      if (state.order) {
        state.order.taxes.name = taxName;
      }
    },
    updateOrderTaxAmount(state: OrderState, amount: number) {
      if (state.order) {
        state.order.taxes.amount = amount;
      }
    },
  },
  getters: {
    orders(state: OrderState) {
      return state.orders;
    },
    order(state: OrderState) {
      return state.order;
    },
    ordersById: (state: OrderState) => (id: string) => {
      for (let i = 0; i < state.orders.length; i++) {
        if (state.orders[i].id === id) {
          return state.orders[i];
        }
      }
      // if we get here nothing was found so return empty object
      return {};
    },
    buildersById: (state: OrderState) => (id: number) => {
      for (let i = 0; i < state.builders.length; i++) {
        if (state.builders[i].id === id) {
          return state.builders[i];
        }
      }
      // if we get here nothing was found so return empty object
      return {};
    },

    showSignDetailsModal(state: OrderState) {
      return state.showSignDetailsModal;
    },
    showInviteQuotersModal(state: OrderState) {
      return state.showInviteQuotersModal;
    },
    showShippingAddressModal(state: OrderState) {
      return state.showShippingAddressModal;
    },
    showPaymentModal(state: OrderState) {
      return state.showPaymentModal;
    },
    orderItems(state: OrderState) {
      return state.orderItems;
    },
  },
};

export default store;
