import { render, staticRenderFns } from "./OnlineBillingSetup.vue?vue&type=template&id=a29fab7c&scoped=true&"
import script from "./OnlineBillingSetup.vue?vue&type=script&lang=ts&"
export * from "./OnlineBillingSetup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a29fab7c",
  null
  
)

export default component.exports